import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultError } from "src/types/error";
import { LeadMagnet } from "src/types/lead-magnet";

interface LeadMagnetState {
  isLoading: boolean;
  error: ResultError | undefined;

  isSaving: boolean;
  hasSavingError: boolean;
  item: LeadMagnet | undefined;
}

type LoadLeadMagnetAction = PayloadAction<LeadMagnet | undefined>;
type LeadMagnetLoadingAction = PayloadAction<boolean>;
type LeadMagnetLoadingErrorAction = PayloadAction<ResultError | undefined>;
type LeadMagnetSavingAction = PayloadAction<boolean>;
type LeadMagnetSavingErrorAction = PayloadAction<boolean>;

const initialState: LeadMagnetState = {
  isLoading: false,
  error: undefined,
  isSaving: false,
  hasSavingError: false,
  item: undefined,
};

const reducers = {
  setLoadingStatus: (
    state: LeadMagnetState,
    action: LeadMagnetLoadingAction
  ): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: LeadMagnetState,
    action: LeadMagnetLoadingErrorAction
  ): void => {
    state.error = action.payload;
  },
  setLeadMagnet: (
    state: LeadMagnetState,
    action: LoadLeadMagnetAction
  ): void => {
    state.item = action.payload;
  },
  setSavingStatus: (
    state: LeadMagnetState,
    action: LeadMagnetSavingAction
  ): void => {
    state.isSaving = action.payload;
  },
  setSavingErrorStatus: (
    state: LeadMagnetState,
    action: LeadMagnetSavingErrorAction
  ): void => {
    state.hasSavingError = action.payload;
  },
};

export const slice = createSlice({
  name: "lead-magnet",
  initialState,
  reducers,
});

export const { reducer } = slice;
