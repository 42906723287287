import type { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FieldArray, Form, Formik, getIn } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { paths } from "src/paths";
import { thunks } from "src/thunks/storyline";
import { useDispatch, useSelector } from "src/store";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { Storyline } from "src/types/storyline";
import { createRoute } from "react-router-url-params";
import React from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ContentGoalType } from "src/types/content-goal-type";

interface StorylineEditFormProps {
  storyline: Storyline;
}

export const StorylineEditForm: FC<StorylineEditFormProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { storyline } = props;

  const { strategyId } = useSelector((state) => state.settings);

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        name: storyline.name,
        description: storyline.description,
        contentGoalType: storyline.contentGoalType,
        categories: storyline.categories,
        submit: null,
      }}
      onSubmit={async (values, helpers): Promise<void> => {
        try {
          await dispatch(
            thunks.updateStoryline(strategyId!, storyline.id, {
              name: values.name,
              description: values.description,
              contentGoalType: values.contentGoalType!,
              categories: values.categories,
            })
          );
          toast.success(t(tokens.storylines.details.form.successMessage));
        } catch (error) {
          toast.error(t(tokens.general.formError));
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(255, t(tokens.general.validators.maxLength255) as string)
          .required(t(tokens.general.validators.required) as string),
        description: Yup.string().required(
          t(tokens.general.validators.required) as string
        ),
        contentGoalType: Yup.mixed<ContentGoalType>().oneOf(
          [
            ContentGoalType.Community,
            ContentGoalType.Engagement,
            ContentGoalType.Selling,
          ],
          t(tokens.general.validators.required) as string
        ),
        categories: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .required(t(tokens.general.validators.required) as string)
              .max(255, t(tokens.general.validators.maxLength255) as string),
            description: Yup.string()
              .required(t(tokens.general.validators.required) as string)
              .max(255, t(tokens.general.validators.maxLength255) as string),
          })
        ),
      })}
    >
      {(props) => (
        <>
          <Form placeholder={""}>
            <Stack spacing={4}>
              <Card>
                <CardContent>
                  <Stack spacing={3}>
                    <TextField
                      error={!!(props.touched.name && props.errors.name)}
                      fullWidth
                      helperText={props.touched.name && props.errors.name}
                      label={t(tokens.storylines.details.form.name)}
                      name="name"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.name}
                    />
                    <Box>
                      <TextField
                        select
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "40%",
                            md: "30%",
                          },
                        }}
                        error={
                          !!(
                            props.touched.contentGoalType &&
                            props.errors.contentGoalType
                          )
                        }
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        label={t(tokens.storylines.details.form.contentGoalType)}
                        name="contentGoalType"
                        value={props.values.contentGoalType}
                        helperText={
                          props.touched.contentGoalType &&
                          props.errors.contentGoalType
                        }
                      >
                        <MenuItem
                          key={ContentGoalType.None}
                          value={ContentGoalType.None}
                        >
                          {t(tokens.general.contentGoalTypes.notSelected)}
                        </MenuItem>
                        <MenuItem
                          key={ContentGoalType.Engagement}
                          value={ContentGoalType.Engagement}
                        >
                          {t(tokens.general.contentGoalTypes.engagement)}
                        </MenuItem>
                        <MenuItem
                          key={ContentGoalType.Community}
                          value={ContentGoalType.Community}
                        >
                          {t(tokens.general.contentGoalTypes.community)}
                        </MenuItem>
                        <MenuItem
                          key={ContentGoalType.Selling}
                          value={ContentGoalType.Selling}
                        >
                          {t(tokens.general.contentGoalTypes.selling)}
                        </MenuItem>
                      </TextField>
                    </Box>
                    <TextField
                      error={
                        !!(
                          props.touched.description && props.errors.description
                        )
                      }
                      fullWidth
                      multiline
                      rows={5}
                      helperText={
                        props.touched.description && props.errors.description
                      }
                      label={t(tokens.storylines.details.form.description)}
                      name="description"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.description}
                    />
                  </Stack>
                </CardContent>
              </Card>

              <Card sx={{ display: "flex", flexDirection: "column" }}>
                <CardHeader
                  title={t(tokens.storylines.details.form.categories)}
                />
                <Divider />
                {props.values.categories.length === 0 && (
                  <>
                    <CardContent>
                      <Typography variant="body2">
                        {t(tokens.storylines.details.form.noCategories)}
                      </Typography>
                    </CardContent>
                    <Divider />
                  </>
                )}
                <Box sx={{ overflowX: "auto" }}>
                  <List disablePadding>
                    <FieldArray
                      name="categories"
                      render={(arrayHelpers) => (
                        <>
                          {props.values.categories.map((category, index) => {
                            const name = `categories[${index}].name`;
                            const touchedName = getIn(props.touched, name);
                            const errorName = getIn(props.errors, name);

                            const description = `categories[${index}].description`;
                            const touchedDescription = getIn(
                              props.touched,
                              description
                            );
                            const errorDescription = getIn(
                              props.errors,
                              description
                            );

                            return (
                              <ListItem
                                key={index}
                                divider
                                sx={{ p: 2, width: "100%" }}
                              >
                                <Stack
                                  spacing={1}
                                  direction={{
                                    xs: "column",
                                    sm: "row",
                                  }}
                                  alignItems={"center"}
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <Stack
                                    spacing={1}
                                    sx={{ width: "100%" }}
                                    direction="column"
                                  >
                                    <TextField
                                      error={!!(touchedName && errorName)}
                                      fullWidth
                                      helperText={touchedName && errorName}
                                      name={name}
                                      inputProps={{
                                        style: { fontWeight: "500" },
                                      }}
                                      placeholder={t(
                                        tokens.storylines.details.form
                                          .placeholders.name
                                      )}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                      value={category.name}
                                    />

                                    <TextField
                                      error={
                                        !!(
                                          touchedDescription && errorDescription
                                        )
                                      }
                                      fullWidth
                                      helperText={
                                        touchedDescription && errorDescription
                                      }
                                      placeholder={t(
                                        tokens.storylines.details.form
                                          .placeholders.description
                                      )}
                                      name={description}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                      value={category.description}
                                    />
                                  </Stack>
                                  <IconButton
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <SvgIcon>
                                      <DeleteOutlinedIcon color="error" />
                                    </SvgIcon>
                                  </IconButton>
                                </Stack>
                              </ListItem>
                            );
                          })}

                          <Box sx={{ p: 2 }}>
                            <Button
                              onClick={() =>
                                arrayHelpers.push({ name: "", description: "" })
                              }
                              variant="outlined"
                              color="primary"
                            >
                              {t(tokens.general.buttons.add)}
                            </Button>
                          </Box>
                        </>
                      )}
                    />
                  </List>
                </Box>
              </Card>

              <Stack
                alignItems="center"
                direction="row-reverse"
                justifyContent="flex-end"
                spacing={1}
              >
                <Button
                  href={createRoute(paths.storylines.details).link({
                    storylineId: storyline.id,
                  })}
                  color="inherit"
                  component={RouterLink}
                >
                  {t(tokens.general.buttons.cancel)}
                </Button>
                <LoadingButton
                  type="submit"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </>
      )}
    </Formik>
  );
};
