import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { useSearchParams } from "src/hooks/use-search-params";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { useLocation } from "react-router";
import { useSelector } from "src/store";

const loginPaths: Record<Issuer, string> = {
  [Issuer.JWT]: paths.account.signIn,
};

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const location = useLocation();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  const { isAuthenticated, issuer } = useAuth();
  const [checked, setChecked] = useState<boolean>(false);

  //const { items, isLoading, hasLoadingError } = useSelector(state => state.strategies);

  const check = useCallback(async () => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();
      const href = loginPaths[issuer] + `?${searchParams}`;
      router.replace(href);
    } else {
      //this is needed to allow an authenticated user to create a company
      // if (location.pathname === paths.account.createCompany) {
      setChecked(true);
      // }
      // else

      if (returnTo) {
        router.push(returnTo);
      }
    }
  }, [isAuthenticated, issuer, router, returnTo]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
