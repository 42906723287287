import { type FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { paths } from "src/paths";
import { thunks } from "src/thunks/target-audience";
import { useDispatch, useSelector } from "src/store";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { TargetAudience } from "src/types/target-audience";
import { TextEditor } from "src/components/core/text-editor/text-editor";
import { createRoute } from "react-router-url-params";
import { de } from "date-fns/locale";

interface Values {
  name: string;
  description: string;
  idealCustomer: string;
  content: string;
  goals: string;
  submit: null;
}

interface TargetAudienceEditFormProps {
  targetAudience: TargetAudience;
}

export const TargetAudienceEditForm: FC<TargetAudienceEditFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targetAudience } = props;

  const { strategyId } = useSelector((state) => state.settings);

  const initialValues: Values = {
    name: targetAudience.name,
    description: targetAudience.description,
    idealCustomer: targetAudience.idealCustomer,
    goals: targetAudience.goals,
    content: targetAudience.content,
    submit: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      const updatedTargetAudience = await dispatch(
        thunks.updateTargetAudience(strategyId!, targetAudience.id, {
          name: values.name,
          idealCustomer: values.idealCustomer,
          description: values.description,
          content: values.content,
          goals: values.goals,
        })
      );
      if (updatedTargetAudience) {
        toast.success(t(tokens.targetAudiences.edit.form.successMessage));
      } else {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label={t(tokens.targetAudiences.edit.form.name)}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <Stack spacing={1}>
                <Typography variant="body2">
                  {t(tokens.targetAudiences.edit.form.description)}
                </Typography>
                <TextEditor
                  showHeaderTool={true}
                  content={formik.values.description}
                  onUpdate={(content) => {
                    formik.setFieldValue(
                      "description",
                      content.editor.getHTML()
                    );
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2">
                  {t(tokens.targetAudiences.edit.form.idealCustomer)}
                </Typography>
                <TextEditor
                  showHeaderTool={true}
                  content={formik.values.idealCustomer}
                  onUpdate={(content) => {
                    formik.setFieldValue(
                      "idealCustomer",
                      content.editor.getHTML()
                    );
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2">
                  {t(tokens.targetAudiences.edit.form.goals)}
                </Typography>
                <TextEditor
                  showHeaderTool={true}
                  content={formik.values.goals}
                  onUpdate={(content) => {
                    formik.setFieldValue("goals", content.editor.getHTML());
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2">
                  {t(tokens.targetAudiences.edit.form.pains)}
                </Typography>
                <TextEditor
                  showHeaderTool={true}
                  content={formik.values.content}
                  onUpdate={(content) => {
                    formik.setFieldValue("content", content.editor.getHTML());
                  }}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Stack
          alignItems="center"
          direction="row-reverse"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            href={createRoute(paths.targetAudiences.details).link({
              targetAudienceId: targetAudience.id,
            })}
            color="inherit"
            component={RouterLink}
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
          <LoadingButton
            type="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t(tokens.general.buttons.update)}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
