import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
const LayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%'
  })
);

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <>
      <LayoutRoot>
        <Box
        >
          <Container
            maxWidth="lg"
          >
            {children}
          </Container>
        </Box>
      </LayoutRoot>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};
