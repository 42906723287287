import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { urlParams } from 'src/utils/url-params';
import { api } from 'src/config';
import { GetUsersQueryParams } from 'src/types/host';

const getUsers = (params: GetUsersQueryParams): AppThunk => async (dispatch): Promise<any> => {
  const users = await fetchWrapper.get(`${api}/host/users${urlParams(params)}`, {});
  return users;
};

export const thunks = {
  getUsers
};
