import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface SettingsState {
  strategyId: string | undefined;
}

type SetStrategyIdAction = PayloadAction<string | undefined>;

const initialState: SettingsState = {
  strategyId: undefined
};

const reducers = {
  setStrategyId(state: SettingsState, action: SetStrategyIdAction): void {
    state.strategyId = action.payload;
  },
};

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers
});

export const { reducer } = slice;
