import React, { type FC } from 'react';
import { Alert, Box, Card, Chip, Divider, FormControlLabel, IconButton, Link, List, ListItem, ListItemText, Skeleton, Stack, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DomainAvailabilityStatus } from 'src/types/domain';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

export interface GeneratedDomain {
  name: string;
  status: DomainAvailabilityStatus;
  isSaved: boolean;
}

interface DomainGeneratedListProps {
  isLoading: boolean;
  hasError: boolean;
  showOnlyAvailable?: boolean;
  emptyText: string;
  domains: GeneratedDomain[];
  onShowOnlyAvailable?: (showOnlyAvailable: boolean) => void;
  onAddToBookmarks?: (domain: GeneratedDomain) => Promise<void>;
  onRemoveFromBookmarks?: (domain: GeneratedDomain) => Promise<void>;
}

export const DomainGeneratedList: FC<DomainGeneratedListProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { isLoading, hasError, domains, emptyText, onAddToBookmarks, showOnlyAvailable, onShowOnlyAvailable, onRemoveFromBookmarks } = props;

  return (
    <>
      <Card
        sx={{
          p: 3
        }}>
        {showOnlyAvailable !== undefined &&
          <FormControlLabel
            control={<Switch
              onChange={(e) => onShowOnlyAvailable?.(e.target.checked)}
              checked={showOnlyAvailable}
            />}
            label="Show available only" />
        }

        {!isLoading && domains.length === 0 &&
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack sx={{ width: '100%' }}
            >
              <Alert
                severity="info">
                {emptyText}
              </Alert>
            </Stack>
          </Box>
        }
        {isLoading && <>
          {[...Array(20)].map((item, index) => (
            <Skeleton
              key={index}
              height={50} />
          ))}
        </>}

        {!isLoading && domains.length > 0 &&
          <>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {(showOnlyAvailable ? domains.filter(x => x.status === DomainAvailabilityStatus.available) : domains).map((value, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <>
                        <Stack
                          direction={"row"}
                          spacing={1}>
                          {value.status === DomainAvailabilityStatus.available && <>
                            <Chip
                              label="Available"
                              color="success"
                              variant="outlined" />
                          </>}
                          {value.status === DomainAvailabilityStatus.unavailable && <>
                            <Chip
                              label="Unavailable"
                              color="error"
                              variant="outlined" />
                          </>}
                          {value.status === DomainAvailabilityStatus.unknown && <>
                            <Chip
                              label="Unknown"
                              color="warning"
                              variant="outlined" />
                          </>}
                          <Box>
                            {value.isSaved ?
                              <IconButton
                                onClick={async () => onRemoveFromBookmarks?.(value)}
                                size="small">
                                <BookmarkIcon />
                              </IconButton>
                              :
                              <IconButton
                                onClick={async () => onAddToBookmarks?.(value)}
                                size="small">
                                <BookmarkBorderIcon />
                              </IconButton>
                            }

                          </Box>
                        </Stack>
                      </>
                    }
                  >
                    <ListItemText primary={<>
                      <Link target='_blank'
                        href={`http://${value.name}`}>{value.name}</Link>
                    </>} />

                  </ListItem>
                  <Divider variant="inset"
                    component="li" />
                </React.Fragment>
              ))}
            </List>
          </>
        }
      </Card >
    </>
  );
};