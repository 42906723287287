import React, { forwardRef, useImperativeHandle, useRef } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { thunks } from "src/thunks/topic";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Topic } from "src/types/topic";
import moment from "moment";
import { TextEditor } from "src/components/core/text-editor/text-editor";
import { TemplateButton } from "../common/editor/template-button";
import { ContentGoalType } from "src/types/content-goal-type";
import { Editor } from "@tiptap/react";
import { PromptMessage, PromptMessageUser } from "src/types/prompts";
import { PROMPTS } from "src/consts";

interface Values {
  id: string | undefined;
  name: string;
  description: string;
  content: string;
  storyLineId?: string;
  productId?: string;
  date: Date | undefined;
  contentGoalType: ContentGoalType;
  submit: null;
}

interface TopicGenericEditFormProps {
  topic?: Topic;
  hideBorder?: boolean;
  onSubmitStatus: (isSubmitting: boolean) => void;
  onSubmit?: (topicId: string) => void;
  date?: Date | null;
  ref: any;
}

export type TopicGenericEditHandle = {
  handleSubmit: () => void;
};

export const TopicGenericEditForm = forwardRef<
  TopicGenericEditHandle,
  TopicGenericEditFormProps
>((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { topic, onSubmitStatus, onSubmit, hideBorder, date } = props;
  const editorRef = useRef<Editor | null>(null);

  const { strategyId } = useSelector((state) => state.settings);

  const initialValues: Values = {
    id: topic?.id ?? undefined,
    name: topic?.name ?? "",
    description: topic?.description ?? "",
    content: topic?.content ?? "",
    productId: topic?.product?.id ?? "",
    storyLineId: topic?.storyLine?.id ?? "",
    contentGoalType: topic?.contentGoalType ?? ContentGoalType.None,
    date: topic?.date
      ? moment(topic.date).toDate()
      : date
        ? moment(date).toDate()
        : undefined,
    submit: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
    contentGoalType: Yup.mixed<ContentGoalType>().oneOf(
      [
        ContentGoalType.Community,
        ContentGoalType.Engagement,
        ContentGoalType.Selling,
      ],
      t(tokens.general.validators.required) as string
    ),
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: formik.submitForm,
  }));

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (values, helpers): Promise<void> => {
      onSubmitStatus(true);
      try {
        if (values.id) {
          await dispatch(
            thunks.updateTopic(strategyId!, values.id, {
              name: values.name,
              date: values.date ? moment(values.date).valueOf() : null,
              description: values.description,
              content: values.content,
              productId: values.productId,
              storyLineId: values.storyLineId,
              contentGoalType: values.contentGoalType,
            })
          );
          onSubmit?.(values.id);
          toast.success(t(tokens.topics.details.form.updateSuccessMessage));
        } else {
          const newTopic = await dispatch(
            thunks.createTopic(strategyId!, {
              name: values.name,
              date: values.date ? moment(values.date).valueOf() : null,
              description: values.description,
              content: values.content,
              productId: values.productId,
              storyLineId: values.storyLineId,
              contentGoalType: values.contentGoalType,
            })
          );
          onSubmit?.(newTopic.id);
          formik.setFieldValue("id", newTopic.id);
          toast.success(t(tokens.topics.details.form.createSuccessMessage));
        }
      } catch (error) {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      } finally {
        onSubmitStatus(false);
      }
    },
  });

  return (
    <Stack
      spacing={4}
      sx={{
        // height: hideBorder ? "100%" : "inherit",
        overflow: hideBorder ? "auto" : "inherit",
      }}
    >
      <Card
        sx={{
          height: "100%",
          overflow: "auto",
          borderRadius: hideBorder ? 0 : 2,
        }}
      >
        <CardContent>
          <Stack spacing={3}>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label={t(tokens.topics.details.form.name)}
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />

                <TextField
                  error={
                    !!(formik.touched.description && formik.errors.description)
                  }
                  multiline
                  minRows={3}
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  label={t(tokens.topics.details.form.description)}
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />

                {/* <Stack
                  spacing={3}
                  direction={{
                    xs: "column",
                    sm: "row",
                  }}
                >
                  

                  <Box>
                    <TextField
                      select
                      sx={{
                        width: "200px",
                      }}
                      error={
                        !!(
                          formik.touched.contentGoalType &&
                          formik.errors.contentGoalType
                        )
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      label={t(tokens.storylines.details.form.contentGoal)}
                      name="contentGoalType"
                      value={formik.values.contentGoalType}
                      helperText={
                        formik.touched.contentGoalType &&
                        formik.errors.contentGoalType
                      }
                    >
                      <MenuItem
                        key={ContentGoalType.Engagement}
                        value={ContentGoalType.Engagement}
                      >
                        {t(tokens.general.contentGoalTypes.engagement)}
                      </MenuItem>
                      <MenuItem
                        key={ContentGoalType.Community}
                        value={ContentGoalType.Community}
                      >
                        {t(tokens.general.contentGoalTypes.community)}
                      </MenuItem>
                      <MenuItem
                        key={ContentGoalType.Selling}
                        value={ContentGoalType.Selling}
                      >
                        {t(tokens.general.contentGoalTypes.selling)}
                      </MenuItem>
                    </TextField>
                  </Box>
                </Stack> */}
              </Stack>
            </form>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  mb: 1,
                }}
              >
                {t(tokens.topics.details.form.content)}
              </Typography>
              <TextEditor
                showHeaderTool={true}
                ref={editorRef}
                content={formik.values.content}
                onUpdate={(content) => {
                  formik.setFieldValue("content", content.editor.getHTML());
                }}
                placeholder={t(tokens.general.placeholders.editor)}
                customButtons={[
                  <TemplateButton
                    editor={editorRef.current}
                    getMessages={() => {
                      var messages = [
                        {
                          user: PromptMessageUser.System,
                          content: PROMPTS.LANDING_PAGE,
                        },
                      ] as PromptMessage[];
                      if (formik.values.name) {
                        messages.push({
                          user: PromptMessageUser.User,
                          content: `Topic title:\n ${formik.values.name}`,
                        });
                      }

                      if (formik.values.description) {
                        messages.push({
                          user: PromptMessageUser.User,
                          content: `Topic description:\n ${formik.values.description}`,
                        });
                      }

                      return messages;
                    }}
                    onTextGenerate={(text) => {
                      editorRef?.current?.commands.setContent(text);
                    }}
                    onFinish={() => {
                      formik.setFieldValue(
                        "content",
                        editorRef?.current?.getHTML()
                      );
                    }}
                  />,
                ]}
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
});
