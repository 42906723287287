import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultError } from "src/types/error";
import { Storyline } from "src/types/storyline";

interface StorylineState {
  isLoading: boolean;
  error: ResultError | undefined;
  item: Storyline | undefined;
}

type LoadStorylineAction = PayloadAction<Storyline | undefined>;
type StorylineLoadingAction = PayloadAction<boolean>;
type StorylineLoadingErrorAction = PayloadAction<ResultError | undefined>;

const initialState: StorylineState = {
  isLoading: false,
  error: undefined,
  item: undefined,
};

const reducers = {
  setLoadingStatus: (
    state: StorylineState,
    action: StorylineLoadingAction
  ): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: StorylineState,
    action: StorylineLoadingErrorAction
  ): void => {
    state.error = action.payload;
  },
  setStoryline: (state: StorylineState, action: LoadStorylineAction): void => {
    state.item = action.payload;
  },
};

export const slice = createSlice({
  name: "storyline",
  initialState,
  reducers,
});

export const { reducer } = slice;
