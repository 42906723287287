import { useState, type FC, useEffect, useRef } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface Values {
  title: string;
  description: string;
  submit: null;
}

interface TwoFieldsFormProps {
  title?: string;
  titlePlaceholder?: string;
  description?: string;
  descriptionPlaceholder?: string;
  isSubmitting?: boolean;
  onSubmit: (title: string, description: string) => void;
}

export const TwoFieldsForm: FC<TwoFieldsFormProps> = (props) => {

  const { onSubmit, title, description, titlePlaceholder, descriptionPlaceholder, isSubmitting } = props;
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<Values>(
    {
      title: title ?? '',
      description: description ?? '',
      submit: null
    });

  useEffect(() => {
    setInitialValues({
      title: title ?? '',
      description: description ?? '',
      submit: null
    });

  }, [title, description]);

  const textRef = useRef();
  const validationSchema = Yup.object({
    title: Yup
      .string()
      .max(1000, t(tokens.general.validators.maxLength1000) as string)
      .required(t(tokens.general.validators.required) as string),
    description: Yup
      .string()
      .max(1000, t(tokens.general.validators.maxLength1000) as string)
      .required(t(tokens.general.validators.required) as string)
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      onSubmit(values.title, values.description);
    }
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
    // {...props}
    >
      <Stack
        spacing={3}
      >
        <TextField
          inputRef={textRef}
          error={!!(formik.touched.title && formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          fullWidth
          sx={{
            '& input': {
              p: 2
            },
            '& textarea': {
              p: 2
            },
          }}

          InputProps={{
            sx: {
              p: 0
            }

          }}

          placeholder={titlePlaceholder ?? ""}
          name="title"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          value={formik.values.title}
        />

        <TextField
          error={!!(formik.touched.description && formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          fullWidth
          sx={{
            '& input': {
              p: 2
            },
            '& textarea': {
              p: 2
            },
          }}

          InputProps={{
            sx: {
              p: 0
            }

          }}

          placeholder={descriptionPlaceholder ?? ""}
          name="description"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          value={formik.values.description}
        />

        <Box>
          <LoadingButton
            type="submit"
            size="large"
            loading={isSubmitting}
            variant="contained"
            sx={{
              minWidth: 200,
              height: 56,
            }}
          >
            {t(tokens.general.buttons.next)}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};