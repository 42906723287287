import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as storylineCategorySlice } from "src/slices/storyline-category";
import { ERRORS } from "src/consts";

const clearStorylineCategory =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(storylineCategorySlice.actions.setStorylineCategory(undefined));
  };

const createStorylineCategory =
  (strategyId: string, storylineId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var storylineCategory = await fetchWrapper.post(
      `${api}/strategies/${strategyId}/storylines/${storylineId}/categories`,
      value
    );
    dispatch(
      storylineCategorySlice.actions.setStorylineCategory(storylineCategory)
    );
    return storylineCategory;
  };

const updateStorylineCategory =
  (
    strategyId: string,
    storylineId: string,
    storylineCategoryId: string,
    value: any
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    var storylineCategory = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/storylines/${storylineId}/categories/${storylineCategoryId}`,
      value
    );
    dispatch(
      storylineCategorySlice.actions.setStorylineCategory(storylineCategory)
    );
    return storylineCategory;
  };

const deleteStorylineCategory =
  (
    strategyId: string,
    storylineId: string,
    storylineCategoryId: string
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/strategies/${strategyId}/storylines/${storylineId}/categories/${storylineCategoryId}`,
      {}
    );
    dispatch(storylineCategorySlice.actions.setStorylineCategory(undefined));
  };

const getStorylineCategory =
  (
    strategyId: string,
    storylineId: string,
    storylineCategoryId: string
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(storylineCategorySlice.actions.setLoadingErrorStatus(undefined));
    dispatch(storylineCategorySlice.actions.setLoadingStatus(true));
    try {
      var storylineCategory = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/storylines/${storylineId}/categories/${storylineCategoryId}`,
        {}
      );
      dispatch(
        storylineCategorySlice.actions.setStorylineCategory(storylineCategory)
      );
    } catch (error) {
      if (error?.code === "StorylineCategory.StorylineCategoryNotFound") {
        dispatch(
          storylineCategorySlice.actions.setLoadingErrorStatus(
            ERRORS.NOT_FOUND_ERROR
          )
        );
      } else {
        dispatch(
          storylineCategorySlice.actions.setLoadingErrorStatus(
            ERRORS.GENERAL_ERROR
          )
        );
      }
    }
    dispatch(storylineCategorySlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createStorylineCategory,
  updateStorylineCategory,
  deleteStorylineCategory,
  clearStorylineCategory,
  getStorylineCategory,
};
