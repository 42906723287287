import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { CancelTokenSource } from "axios";

const runChatPrompt =
  (
    strategyId: string,
    lang: string,
    data: any,
    onProgress: (text: string) => void,
    onError: () => void,
    onFinish: (text: string) => void,
    cancelToken?: CancelTokenSource
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      return await fetchWrapper
        .post(
          `${api}/strategies/${strategyId}/chat`,
          data,
          cancelToken,
          lang,
          "stream",
          (progressEvent: any) => {
            try {
              var dataChunk = progressEvent.event.currentTarget.response;
              const str = parseChatPrompt(dataChunk);
              onProgress(str);
            } catch (e) {
              console.log(e);
            }
          }
        )
        .then((val: any) => {
          const str = parseChatPrompt(val);
          onFinish?.(str);
        })
        .catch(() => {
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

const parseChatPrompt = (text: string) => {
  text = text.replace("```json", "");
  text = text.replace("json", "");
  text = text.replace("```", "");
  text = text.replace("```", "");

  if (!text.endsWith("]")) {
    text += "]";
  }

  var json = JSON.parse(text) as [];
  var str = "";
  for (let i = 0; i < json.length; i++) {
    str += json[i] as any;
  }

  return str;
};

export const thunks = {
  runChatPrompt,
};
