import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultError } from "src/types/error";
import { ContentPlaceholder } from "src/types/content-placeholder";

interface ContentPlaceholderState {
  isLoading: boolean;
  error: ResultError | undefined;
  item: ContentPlaceholder | undefined;
}

const initialState: ContentPlaceholderState = {
  isLoading: false,
  error: undefined,
  item: undefined,
};

const reducers = {
  setLoadingStatus: (
    state: ContentPlaceholderState,
    action: PayloadAction<boolean>
  ): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: ContentPlaceholderState,
    action: PayloadAction<ResultError | undefined>
  ): void => {
    state.error = action.payload;
  },
  setContentPlaceholder: (
    state: ContentPlaceholderState,
    action: PayloadAction<ContentPlaceholder | undefined>
  ): void => {
    state.item = action.payload;
  },
};

export const slice = createSlice({
  name: "content-placeholder",
  initialState,
  reducers,
});

export const { reducer } = slice;
