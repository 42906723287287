"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import { useColorScheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { RouterLink } from "./link";
import { paths } from "src/paths";
import { Logo as DefaultLogo } from "src/components/logo";
import { NoSsr } from "./no-ssr";

const HEIGHT = 60;
const WIDTH = 60;

type Color = "dark" | "light";

export interface LogoProps {
  color?: Color;
  emblem?: boolean;
  height?: number;
  width?: number;
}

export function Logo({
  color = "dark",
  emblem,
  height = HEIGHT,
  width = WIDTH,
}: LogoProps): React.JSX.Element {
  let url: string;

  if (emblem) {
    url =
      color === "light"
        ? "/assets/logo-emblem.svg"
        : "/assets/logo-emblem--dark.svg";
  } else {
    url = color === "light" ? "/assets/logo.svg" : "/assets/logo--dark.svg";
  }

  return (
    <Box
      alt="logo"
      component="img"
      height={height}
      src={url}
      width={width}
    />
  );
}

export interface DynamicLogoProps {
  colorDark?: Color;
  colorLight?: Color;
  emblem?: boolean;
  height?: number;
  width?: number;
}

export function DynamicLogo({
  colorDark = "light",
  colorLight = "dark",
  height = HEIGHT,
  width = WIDTH,
  ...props
}: DynamicLogoProps): React.JSX.Element {
  const { colorScheme } = useColorScheme();
  const color = colorScheme === "dark" ? colorDark : colorLight;

  return (
    <NoSsr
      fallback={<Box sx={{ height: `${height}px`, width: `${width}px` }} />}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{ flexGrow: 1 }}
      >
        <Stack
          alignItems="center"
          direction="row"
          display="inline-flex"
          spacing={1}
          sx={{ textDecoration: "none" }}
        >
          <Box
            sx={{
              display: "inline-flex",
              height: 24,
              width: 24,
            }}
          >
            <DefaultLogo />
          </Box>
          {/* {mdUp && ( */}
          <Box
            sx={{
              // color: color,
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: 14,
              fontWeight: 800,
              letterSpacing: "0.3px",
              lineHeight: 2.5,
              "& span:last": {
                color: "primary.main",
              },
              "& span:first": {
                color: "text.primary",
              },
            }}
          >
            <span>MakeIt</span>
            <span>Lab</span>
          </Box>
          {/* )} */}
        </Stack>
      </Stack>
      {/* <Logo color={color} height={height} width={width} {...props} /> */}
    </NoSsr>
  );
}
