import React, { type FC, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { tokens } from "src/locales/tokens";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "src/hooks/use-auth";
import { Stack } from "@mui/system";
import { useParams } from "react-router";

export const WizardSignup: FC = () => {
  const { t } = useTranslation();

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const { strategyId } = useParams();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const auth = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t(tokens.general.validators.invalidEmail) as string)
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
  });

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values): Promise<void> => {
          if (isGenerating) {
            return;
          }
          setIsGenerating(true);
          try {
            await auth.signUp(values.email, strategyId!);
            setIsEmailSent(true);
            //router.push(paths.account.emailSent);
          } catch (err) {
            toast.error(t(tokens.general.formError));
            console.log(err);
          }
          setIsGenerating(false);
        }}
      >
        {(props) => (
          <Form placeholder={""}>
            <Stack
              direction={{
                xs: "column",
                sm: "row",
              }}
              alignItems={"center"}
              spacing={3}
              sx={{ mb: 4 }}
            >
              <img
                width={mdUp ? 200 : 150}
                height={mdUp ? 200 : 150}
                alt={"robot"}
                src={"/assets/robot.png"}
              />
              <Stack spacing={2}>
                <Typography variant="h3">
                  🏁 {t(tokens.wizard.signup.text)}
                </Typography>

                {isEmailSent && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 2,
                      }}
                    >
                      {t(tokens.wizard.signup.changeEmailText)}
                    </Typography>
                    <b>{props.values.email}</b>
                    <Stack
                      spacing={1}
                      direction={"row"}
                      alignItems={"center"}
                      alignContent={"center"}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setIsEmailSent(false);
                        }}
                      >
                        {t(tokens.wizard.signup.changeButtonText)}
                      </Button>
                    </Stack>
                  </>
                )}
                {!isEmailSent && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 2,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: t(tokens.wizard.signup.tooltip),
                      }}
                    ></Typography>

                    <Box sx={{ mb: 2 }}>
                      <TextField
                        error={!!(props.touched.email && props.errors.email)}
                        helperText={props.touched.email && props.errors.email}
                        fullWidth
                        sx={{
                          "& input": {
                            p: 2,
                          },
                          "& textarea": {
                            p: 2,
                          },
                        }}
                        InputProps={{
                          sx: {
                            p: 0,
                          },
                        }}
                        placeholder={"Email"}
                        name="email"
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        type="email"
                        value={props.values.email}
                      />
                    </Box>
                  </>
                )}

                {!isEmailSent && (
                  <Box>
                    <LoadingButton
                      type="submit"
                      size="large"
                      loading={props.isSubmitting}
                      variant="contained"
                      sx={{
                        minWidth: 200,
                        height: 56,
                      }}
                    >
                      {t(tokens.general.buttons.finish)}
                    </LoadingButton>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
