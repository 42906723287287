import { useCallback, useState, type FC } from "react";
import {
  Button,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { StorylineCategoryTopic } from "src/types/storyline-category";
import { ContentGoalType } from "src/types/content-goal-type";
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/topic";
import LoadingButton from "@mui/lab/LoadingButton";
import { RouterLink } from "src/components/core/link";
import { ItemMenu } from "src/sections/common/item-menu";
import { Topic } from "src/types/topic";

interface EditStorylineCategoryTopicItemProps {
  storylineId: string;
  storyLineCategoryId: string;
  topic: StorylineCategoryTopic;
  showDivider: boolean;
  contentGoalType: ContentGoalType;
  onUpdate?: (topic: Topic) => void;
  onDelete?: (id: string) => void;
}

export const EditStorylineCategoryTopicItem: FC<
  EditStorylineCategoryTopicItemProps
> = (props) => {
  const {
    topic,
    showDivider,
    storylineId,
    storyLineCategoryId,
    contentGoalType,
    onUpdate,
    onDelete,
  } = props;
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = React.useState(!topic.id);
  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, []);

  const deleteStoryline = useCallback(async () => {
    try {
      if (strategyId && topic) {
        handleCloseDeleteDialog();
        await dispatch(thunks.deleteTopic(strategyId, topic.id));
        onDelete && onDelete(topic.id);
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleCloseDeleteDialog, onDelete, strategyId, t, topic]);

  return (
    <>
      {!isEditing ? (
        <ListItem
          divider={showDivider}
          sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
        >
          <ListItemText
            disableTypography
            primary={<Typography variant="body1">{topic.name}</Typography>}
          />
          <ItemMenu
            onDelete={() => {
              setOpenDeleteDialog(true);
            }}
            onEdit={() => {
              setIsEditing(true);
            }}
          />
          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t(tokens.storylinesCategories.details.deleteTopicDialogTitle)}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(
                  tokens.storylinesCategories.details
                    .deleteTopicDialogDescription
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={deleteStoryline}
                color="error"
              >
                {t(tokens.general.buttons.delete)}
              </Button>
              <Button onClick={handleCloseDeleteDialog}>
                {t(tokens.general.buttons.cancel)}
              </Button>
            </DialogActions>
          </Dialog>
        </ListItem>
      ) : (
        <>
          <Formik
            validateOnChange={false}
            initialValues={{
              name: topic.name,
              submit: null,
            }}
            onSubmit={async (values, helpers): Promise<void> => {
              try {
                var updateTopic = await dispatch(
                  thunks.updateTopic(strategyId!, topic.id, {
                    name: values.name,
                    // date: values.date ? moment(values.date).valueOf() : null,
                    description: topic.description ?? "",
                    content: topic.content ?? "",
                    // productId: values.productId,
                    storyLineId: storylineId,
                    storyLineCategoryId: storyLineCategoryId,
                    contentGoalType: contentGoalType,
                  })
                );
                onUpdate && onUpdate(updateTopic);
                toast.success(
                  t(
                    tokens.storylinesCategories.details.form.topicUpdatedMessage
                  )
                );
                setIsEditing(false);
              } catch (error) {
                toast.error(t(tokens.general.formError));
                helpers.setStatus({ success: false });
                helpers.setSubmitting(false);
              }
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(255, t(tokens.general.validators.maxLength255) as string)
                .required(t(tokens.general.validators.required) as string),
            })}
          >
            {(props) => (
              <>
                <Form placeholder={""}>
                  <ListItem
                    divider={showDivider}
                    alignItems="flex-start"
                    sx={{ p: 2 }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <TextField
                          error={!!(props.touched.name && props.errors.name)}
                          fullWidth
                          helperText={props.touched.name && props.errors.name}
                          name="name"
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          value={props.values.name}
                        />
                      }
                    />
                    <Stack
                      direction="row"
                      alignItems={"start"}
                      justifyContent={"flex-start"}
                      spacing={1}
                    >
                      <LoadingButton
                        type="submit"
                        disabled={props.isSubmitting}
                        loading={props.isSubmitting}
                        variant="contained"
                      >
                        {t(tokens.general.buttons.update)}
                      </LoadingButton>
                      <Button
                        onClick={() => {
                          setIsEditing(false);
                        }}
                        color="inherit"
                        component={RouterLink}
                      >
                        {t(tokens.general.buttons.cancel)}
                      </Button>
                    </Stack>
                  </ListItem>
                </Form>
              </>
            )}
          </Formik>
        </>
      )}
    </>
  );
};
