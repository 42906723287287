import { useCallback, useState, type FC } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { map } from "lodash";
import toast from "react-hot-toast";
import { GeneratedTopic, Topic } from "src/types/topic";
import { thunks as topicThunks } from "src/thunks/topic";
import { thunks as promptsThunks } from "src/thunks/prompts";
//import { thunks as targetAudiencesThunks } from "src/thunks/target-audiences";
import axios, { CancelTokenSource } from "axios";
import { ContentGoalType } from "src/types/content-goal-type";
import { PromptMessage, PromptMessageUser } from "src/types/prompts";
import { parse } from "partial-json";
import { StorylineCategory } from "src/types/storyline-category";

interface Values {
  name: string;
  instruction: string;
  status: number;
  submit: null;
}

const initialValues: Values = {
  name: "",
  instruction: "",
  status: 0,
  submit: null,
};

interface TopicGenerationFormProps {
  category: StorylineCategory;
  showVerticalFilters?: boolean;
  onTopicAdd?: (topic: Topic) => void;
  onTopicRemove?: (topicId: String) => void;
}

export const TopicGenerationForm: FC<TopicGenerationFormProps> = (props) => {
  const {
    onTopicAdd,
    onTopicRemove,
    showVerticalFilters = false,
    category,
  } = props;
  const { t, i18n } = useTranslation();
  const { strategyId } = useSelector((state) => state.settings);
  const { item: strategy } = useSelector((state) => state.strategy);
  const [isMoreClicked, setIsMoreClicked] = useState(false);

  const validationSchema = Yup.object({
    // storyline: Yup.object().required(
    //   t(tokens.general.validators.required) as string
    // ),
  });
  const [topics, setTopics] = useState<GeneratedTopic[]>([]);
  const dispatch = useDispatch();

  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const [messages, setMessages] = useState<PromptMessage[]>([]);

  const getInitialMessages = useCallback(
    (instruction: String) => {
      var messages = [] as PromptMessage[];

      messages.push({
        user: PromptMessageUser.System,
        content: `Generate 10 well-defined topics that you can write about, each related to your specified Storyline Category.
# Steps

1. **Identify the Category**: Understand the specified Storyline Category to ensure all topics align with it.
2. **Topic Development**: Create engaging and relevant topics, each with a descriptive name between 10-15 words.
3. **Structure**: Format each topic as a JSON object with the key "name".
4. **Examples**. Use my examples of existing topics to understand what I expect to get as output.

# Output Format

Provide a response in the JSON format:
[{"name": ""}]  
`,
      });

      // if (category.storyline) {
      //   var storylineContent = `Storyline Name:\n${category.storyline.name} - ${category.storyline.description}\n\n`;
      //   messages.push({
      //     user: PromptMessageUser.User,
      //     content: storylineContent,
      //   });
      // }

      if (strategy && strategy.product) {
        var productContent = `Product Name:\n${strategy.product.name} - ${strategy.product.description}\n\n`;
        messages.push({
          user: PromptMessageUser.User,
          content: productContent,
        });
      }

      if (category.topics.length > 0) {
        var existingTopicsContent = `Existing topics:\n`;
        category.topics.forEach((topic) => {
          existingTopicsContent += `- ${topic.name}\n`;
        });
        messages.push({
          user: PromptMessageUser.User,
          content: existingTopicsContent,
        });
      }

      if (instruction) {
        messages.push({
          user: PromptMessageUser.User,
          content: `Additional Instructions: ${instruction}`,
        });
      }

      var categoryContent = `Storyline Category Name:\n${category.name} - ${category.description}\n\n`;
      messages.push({
        user: PromptMessageUser.User,
        content: categoryContent,
      });

      //var userInput = "";

      // if (category.storyline) {
      //   userInput += `Storyline Name:\n${category.storyline.name}\n\n`;
      //   userInput += `Storyline Description:\n${category.storyline.description}\n\n`;
      // }

      // userInput += `My Storyline Category:\n${category.name} - ${category.description}\n\n`;

      // if (strategy && strategy.product) {
      //   userInput += `Product Name:\n${strategy.product.name}\n\n`;
      //   userInput += `Product Description:\n${strategy.product.description}\n\n`;
      // }

      // if (category.topics.length > 0) {
      //   userInput += `Existing topics:\n`;
      //   category.topics.forEach((topic) => {
      //     userInput += `- ${topic.name}\n`;
      //   });
      // }

      // debugger;
      // messages.push({
      //   user: PromptMessageUser.User,
      //   content: userInput,
      // });

      // if (instruction) {
      //   userInput = ""
      //   messages.push({
      //     user: PromptMessageUser.User,
      //     content: `${instruction}`,
      //   });
      // }

      /*
      if (category.storyline) {
        var storylineContent = `Storyline Name:\n ${category.storyline.name}\n\n`;
        storylineContent += `Storyline Description:\n ${category.storyline.description}`;
        messages.push({
          user: PromptMessageUser.User,
          content: storylineContent,
        });
      }

      var categoryContent = `Storyline Category Name:\n ${category.name}\n\n`;
      categoryContent += `Storyline Category Description:\n ${category.description}\n\n`;
      messages.push({
        user: PromptMessageUser.User,
        content: categoryContent,
      });

      if (strategy && strategy.product) {
        var productContent = `Product Name:\n ${strategy.product.name}\n\n`;
        productContent += `Product Description:\n ${strategy.product.description}\n\n`;
        messages.push({
          user: PromptMessageUser.User,
          content: productContent,
        });
      }

      if (category.topics.length > 0) {
        var existingTopicsContent = `Existing topics:\n`;
        category.topics.forEach((topic) => {
          existingTopicsContent += `- ${topic.name}\n`;
        });
        messages.push({
          user: PromptMessageUser.User,
          content: existingTopicsContent,
        });
      }

      if (instruction) {
        messages.push({
          user: PromptMessageUser.User,
          content: `${instruction}`,
        });
      }
      */

      return messages;
    },
    [category.description, category.name, category.topics, strategy]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      await dispatch(
        promptsThunks.runChatPrompt(
          strategyId!,
          i18n.language,
          {
            messages: messages,
          },
          (text) => {
            var output = parse(text);
            const newTopics = output as GeneratedTopic[];
            const prevCompletedTopics = topics.filter(
              (topic) => topic.isCompleted
            );
            setTopics([...prevCompletedTopics, ...newTopics]);
          },
          () => {
            toast.error(t(tokens.general.formError));
          },
          (text) => {
            var output = parse(text);
            const newTopics = output as GeneratedTopic[];
            newTopics.forEach((topic) => {
              topic.isCompleted = true;
            });
            const prevCompletedTopics = topics.filter(
              (topic) => topic.isCompleted
            );
            setTopics([...prevCompletedTopics, ...newTopics]);

            var message = {
              user: PromptMessageUser.Assistant,
              content: JSON.stringify(newTopics),
            };

            setMessages([...messages, message]);
          },
          cancelTokenSource
        )
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Stack
                spacing={2}
                justifyContent={"space-between"}
                direction={{
                  xs: "column",
                  sm: showVerticalFilters ? "column" : "row",
                }}
              ></Stack>

              <TextField
                error={
                  !!(formik.touched.instruction && formik.errors.instruction)
                }
                fullWidth
                multiline
                minRows={2}
                helperText={
                  formik.touched.instruction && formik.errors.instruction
                }
                label={t(tokens.topics.generator.form.instruction)}
                name="instruction"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.instruction}
              />
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-start"
                spacing={1}
              >
                <LoadingButton
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting && !isMoreClicked}
                  variant="contained"
                  onClick={() => {
                    setIsMoreClicked(false);
                    setTopics([]);
                    var messages = getInitialMessages(
                      formik.values.instruction
                    );
                    setMessages(messages);
                    formik.submitForm();
                  }}
                >
                  {t(tokens.topics.generator.buttons.generateTopics)}
                </LoadingButton>

                {formik.isSubmitting && !isMoreClicked && (
                  <LoadingButton
                    type="button"
                    color="error"
                    onClick={() => {
                      setIsMoreClicked(false);
                      cancelTokenSource.cancel();
                      setCancelTokenSource(axios.CancelToken.source());
                    }}
                    variant="outlined"
                  >
                    {t(tokens.general.buttons.cancel)}
                  </LoadingButton>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        {topics.length > 0 && (
          <Card>
            <List disablePadding>
              {map(topics, (topic, index) => (
                <GeneratedTopicItem
                  showDivider={index < topics.length - 1}
                  key={index}
                  productId={strategy?.product?.id}
                  storylineId={category.storyline?.id}
                  categoryId={category.id}
                  contentGoalType={category.storyline?.contentGoalType}
                  topic={topic}
                  onTopicAdd={onTopicAdd}
                  onTopicRemove={onTopicRemove}
                />
              ))}
            </List>
          </Card>
        )}

        {topics.length > 0 && (
          <Box
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <LoadingButton
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              variant="outlined"
              onClick={() => {
                var message = {
                  user: PromptMessageUser.User,
                  content:
                    "Generate 10 more topics that don't overlap using the same json structure.",
                };
                var newMessages = [...messages, message];
                setIsMoreClicked(true);
                setMessages(newMessages);
                formik.submitForm();
              }}
            >
              {t(tokens.general.buttons.more)}
            </LoadingButton>
          </Box>
        )}
      </Stack>
    </form>
  );
};

interface GeneratedTopicItemProps {
  topic: GeneratedTopic;
  storylineId: string;
  categoryId: string;
  showDivider: boolean;
  contentGoalType?: ContentGoalType;
  productId?: string;
  onTopicAdd?: (topic: Topic) => void;
  onTopicRemove?: (topicId: String) => void;
}

export const GeneratedTopicItem: FC<GeneratedTopicItemProps> = (props) => {
  const { t } = useTranslation();
  const {
    topic,
    storylineId,
    categoryId,
    productId,
    contentGoalType = ContentGoalType.Engagement,
    onTopicAdd,
    showDivider,
    onTopicRemove,
  } = props;
  const { strategyId } = useSelector((state) => state.settings);
  const [isAdded, setIsAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [topicId, setTopicId] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const addTopic = useCallback(
    async (topic: GeneratedTopic) => {
      setIsLoading(true);
      try {
        const newTopic = await dispatch(
          topicThunks.createTopic(strategyId!, {
            name: topic.name,
            description: topic.description ?? "",
            storyLineCategoryId: categoryId,
            contentGoalType: contentGoalType,
            storyLineId: storylineId,
            productId: productId,
          })
        );
        setTopicId(newTopic?.id);
        setIsAdded(true);
        onTopicAdd?.(newTopic);
      } catch (error) {
        toast.error(t(tokens.general.formError));
      }
      setIsLoading(false);
    },

    [
      contentGoalType,
      dispatch,
      onTopicAdd,
      productId,
      categoryId,
      storylineId,
      strategyId,
      t,
    ]
  );

  const removeTopic = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(topicThunks.deleteTopic(strategyId!, topicId!));
      setIsAdded(false);
      onTopicRemove?.(topicId!);
    } catch (error) {
      toast.error(t(tokens.general.formError));
    }
    setIsLoading(false);
    setTopicId(undefined);
  }, [dispatch, onTopicRemove, strategyId, t, topicId]);

  return (
    <ListItem divider={showDivider}>
      <ListItemText
        disableTypography
        primary={<Typography variant="body1">{topic.name}</Typography>}
      />
      <Box>
        <LoadingButton
          disabled={isLoading}
          loading={isLoading}
          onClick={async () => {
            if (!isAdded) {
              addTopic(topic);
            } else {
              removeTopic();
            }
          }}
          color={isAdded ? "error" : "primary"}
          variant="outlined"
          size="small"
        >
          {!isAdded
            ? t(tokens.topics.generator.buttons.addTopic)
            : t(tokens.topics.generator.buttons.removeTopic)}
        </LoadingButton>
      </Box>
    </ListItem>
  );
};
