import { useState, type FC } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
} from "@mui/material";
import { StrategyPartType } from "src/types/strategy";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch, useSelector } from "src/store";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { GenerateAgain } from "../common/generate-again";
import EditIcon from "@mui/icons-material/Edit";
import { ContentEditor } from "../common/content-editor";
import { useStrategyPart } from "../hooks/use-strategy-part";

export const FearsPart: FC = () => {
  const { t } = useTranslation();

  const { item } = useSelector((state) => state.strategy);
  const part = useStrategyPart(StrategyPartType.Fears);

  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h4">{t(tokens.fears.header)}</Typography>
        <Stack
          direction={"row"}
          spacing={2}
        >
          {/* <IconButton
            size="small"
            disabled={part?.isGenerating || isEditing}
            onClick={createPart}
          >
            <SvgIcon>
              <RefreshIcon />
            </SvgIcon>
          </IconButton> */}

          <IconButton
            size="small"
            disabled={part?.item?.isGenerating || isEditing}
            onClick={() => {
              setIsEditing(!isEditing);
            }}
          >
            <SvgIcon>
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>

      {/* <Typography variant="body1">
        {t(tokens.strategy.details.parts.fears.description)}
      </Typography> */}

      {!part?.item?.isGenerating && !part?.item?.isReady && (
        <GenerateAgain
          createPart={part.createPart}
          error={t(tokens.recommendations.fears.noDataFound)}
          isGenerating={part?.item?.isGenerating ?? false}
        />
      )}

      {(part?.item?.isReady || part?.item?.isGenerating) && (
        <Card>
          <CardContent>
            {part?.item?.isReady && !isEditing && (
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: item?.fears ?? "",
                }}
              ></Typography>
            )}

            {isEditing && (
              <ContentEditor
                onCancel={async () => {
                  setIsEditing(false);
                }}
                showHeaderTool={true}
                text={item?.fears ?? ""}
                onSave={async (content) => {
                  var newStrategy = { ...item };
                  newStrategy.fears = content;
                  await dispatch(
                    strategyThunks.updateStrategy(item!.id, newStrategy)
                  );
                  setIsEditing(false);
                }}
              />
            )}

            {part?.item?.isGenerating && (
              <>
                {[...Array(5)].map((_, index) => (
                  <Skeleton key={index} />
                ))}
              </>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};
