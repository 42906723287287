import type { AppThunk } from 'src/store';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { api } from 'src/config';
import { slice as domainSlice } from 'src/slices/domain';
import { Domain } from 'src/types/domain';

const generateDomains = (instruction: string, modelName: string, zones: string[]): AppThunk => async (dispatch): Promise<Domain[]> => {
  dispatch(domainSlice.actions.setLoadingStatus(true));
  dispatch(domainSlice.actions.setErrorStatus(false));

  try {
    var domains = await fetchWrapper.post(`${api}/domains`, {
      instruction: instruction,
      modelName: modelName,
      zones: zones
    });
    dispatch(domainSlice.actions.generateDomains(domains));
    return domains;
  }
  catch (error) {
    dispatch(domainSlice.actions.setErrorStatus(true));
    console.log(error);
  }
  finally {
    dispatch(domainSlice.actions.setLoadingStatus(false));
  }
  return [];
}

const saveDomain = (domain: Domain): AppThunk => async (dispatch): Promise<void> => {
  dispatch(domainSlice.actions.saveDomain(domain));
}

const removeDomain = (domain: Domain): AppThunk => async (dispatch): Promise<void> => {
  dispatch(domainSlice.actions.removeDomain(domain));
}

const loadDomains = (domains: Domain[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(domainSlice.actions.loadDomain(domains));
}

export const thunks = {
  generateDomains,
  saveDomain,
  removeDomain,
  loadDomains
};
