"use client";

import * as React from "react";
import Button from "@mui/material/Button";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";

import type { ViewMode } from "./types";
import { Option } from "src/components/core/option";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import { CaretLeft as CaretLeftIcon } from "@phosphor-icons/react/dist/ssr/CaretLeft";
import { CaretRight as CaretRightIcon } from "@phosphor-icons/react/dist/ssr/CaretRight";

export interface ToolbarProps {
  date: Date;
  onAdd?: () => void;
  onViewChange?: (view: ViewMode) => void;
  onNext?: () => void;
  onPrev?: () => void;
  onToday?: () => void;
  onPlanSchedule?: () => void;
  view: ViewMode;
}

export function Toolbar({
  date,
  onAdd,
  onViewChange,
  onNext,
  onPrev,
  onPlanSchedule,
  onToday,
  view,
}: ToolbarProps): React.JSX.Element {
  const handleViewChange = React.useCallback(
    (event: SelectChangeEvent) => {
      onViewChange?.(event.target.value as ViewMode);
    },
    [onViewChange]
  );

  return (
    <Stack
      spacing={3}
      sx={{
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ alignItems: "center" }}
      >
        <Typography variant="h5">{dayjs(date).format("MMMM")}</Typography>
        <Typography
          sx={{ fontWeight: 400 }}
          variant="h5"
        >
          {dayjs(date).format("YYYY")}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        sx={{ alignItems: "center" }}
      >
        {/* <Select
          name="view"
          onChange={handleViewChange}
          sx={{ minWidth: "120px", order: { xs: -1, md: 0 } }}
          value={view}
        >
          <Option value="dayGridMonth">Month</Option>
          <Option value="timeGridWeek">Week</Option>
          <Option value="timeGridDay">Day</Option>
          <Option value="listWeek">Agenda</Option>
        </Select> */}
        <Button
          onClick={onPlanSchedule}
          startIcon={<PlusIcon />}
          sx={{ width: { xs: "100%", md: "auto" } }}
          variant="contained"
        >
          Schedule
        </Button>

        <Stack
          direction="row"
          spacing={3}
          sx={{ justifyContent: "center" }}
        >
          <IconButton onClick={onPrev}>
            <CaretLeftIcon />
          </IconButton>
          <Button
            color="secondary"
            onClick={onToday}
            variant="outlined"
          >
            Today
          </Button>
          <IconButton onClick={onNext}>
            <CaretRightIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
