import { type FC } from "react";
import {
  Button,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { ContentGoalType } from "src/types/content-goal-type";
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/topic";
import LoadingButton from "@mui/lab/LoadingButton";
import { RouterLink } from "src/components/core/link";
import { Topic } from "src/types/topic";

interface CreateStorylineCategoryTopicItemProps {
  storylineId: string;
  storyLineCategoryId: string;
  showDivider: boolean;
  contentGoalType: ContentGoalType;
  onCreate?: (topic: Topic) => void;
  onCancel?: () => void;
}

export const CreateStorylineCategoryTopicItem: FC<
  CreateStorylineCategoryTopicItemProps
> = (props) => {
  const {
    showDivider,
    storylineId,
    storyLineCategoryId,
    contentGoalType,
    onCreate: onSave,
    onCancel,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);

  return (
    <>
      <Formik
        validateOnChange={false}
        initialValues={{
          name: "",
          submit: null,
        }}
        onSubmit={async (values, helpers): Promise<void> => {
          try {
            var topic = await dispatch(
              thunks.createTopic(strategyId!, {
                name: values.name,
                // date: values.date ? moment(values.date).valueOf() : null,
                description: "",
                content: "",
                // productId: values.productId,
                storyLineId: storylineId,
                storyLineCategoryId: storyLineCategoryId,
                contentGoalType: contentGoalType,
              })
            );
            onSave && onSave(topic);
            toast.success(
              t(tokens.storylinesCategories.details.form.topicUpdatedMessage)
            );
            //setIsEditing(false);
          } catch (error) {
            toast.error(t(tokens.general.formError));
            helpers.setStatus({ success: false });
            helpers.setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255, t(tokens.general.validators.maxLength255) as string)
            .required(t(tokens.general.validators.required) as string),
        })}
      >
        {(props) => (
          <>
            <Form placeholder={""}>
              <ListItem
                divider={showDivider}
                alignItems="flex-start"
                sx={{ p: 2 }}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <TextField
                      error={!!(props.touched.name && props.errors.name)}
                      fullWidth
                      helperText={props.touched.name && props.errors.name}
                      name="name"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.name}
                    />
                  }
                />
                <Stack
                  direction="row"
                  alignItems={"start"}
                  justifyContent={"flex-start"}
                  spacing={1}
                >
                  <LoadingButton
                    type="submit"
                    disabled={props.isSubmitting}
                    loading={props.isSubmitting}
                    variant="contained"
                  >
                    {t(tokens.general.buttons.update)}
                  </LoadingButton>
                  <Button
                    onClick={() => {
                      onCancel && onCancel();
                    }}
                    color="inherit"
                    component={RouterLink}
                  >
                    {t(tokens.general.buttons.cancel)}
                  </Button>
                </Stack>
              </ListItem>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
