import { useCallback, useEffect, useState, type FC } from "react";
import {
  Button,
  Stack,
  Unstable_Grid2 as Grid,
  CardActionArea,
  Chip,
  Box,
} from "@mui/material";
import { Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { ContentPlaceholderDrawer } from "../calendar/content-placeholder-edit-drawer";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/content-placeholders";
import moment from "moment";
import { getChipColor } from "src/utils/content-goal-type";
import { ContentGoalType } from "src/types/content-goal-type";
import { ContentPlaceholder } from "src/types/content-placeholder";

interface InstagramGridProps {
  strategyId: string;
}

export const InstagramGrid: FC<InstagramGridProps> = (props) => {
  const { strategyId } = props;
  const { t } = useTranslation();

  const [isContentDrawerOpen, setIsContentDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const [date, setDate] = useState<Date>(new Date());
  const [contentPlaceholder, setContentPlaceholder] = useState<
    ContentPlaceholder | undefined
  >(undefined);

  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.contentPlaceholders
  );

  const { items: contentTypes } = useSelector((state) => state.contentTypes);

  const loadItems = useCallback(
    async (startDate: Date, endDate: Date) => {
      await dispatch(
        thunks.getContentPlaceholders(strategyId!, {
          startDate: moment(startDate).valueOf().toString(),
          endDate: moment(endDate).valueOf().toString(),
          contentTypes: contentTypes
            .filter((x) => !x.isTemporary)
            .map((item) => item.id),
        })
      );
    },
    [contentTypes, dispatch, strategyId]
  );

  useEffect(() => {
    loadItems(
      moment(date).startOf("month").subtract(7, "days").toDate(),
      moment(date).endOf("month").add(7, "days").toDate()
    );
  }, [date, loadItems, strategyId, isContentDrawerOpen]);

  const getContentGoalType = useCallback(
    (contentGoalType: ContentGoalType) => {
      switch (contentGoalType) {
        case ContentGoalType.Engagement:
          return t(tokens.general.contentGoalTypes.engagement);
        case ContentGoalType.Community:
          return t(tokens.general.contentGoalTypes.community);
        case ContentGoalType.Selling:
          return t(tokens.general.contentGoalTypes.selling);
        default:
          return "";
      }
    },
    [t]
  );

  return (
    <>
      <Stack
        sx={{ mt: 2 }}
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h4">Gallery</Typography>
        <Button
          variant="contained"
          onClick={() => {
            setIsContentDrawerOpen(true);
            setContentPlaceholder(undefined);
          }}
        >
          {t(tokens.general.buttons.add)}
        </Button>
      </Stack>
      <Grid
        container
        spacing={4}
      >
        {items.map((item, index) => (
          <Grid
            key={index}
            xs={12}
            md={4}
          >
            <Card>
              <CardActionArea
                onClick={() => {
                  setContentPlaceholder(item);
                  setIsContentDrawerOpen(true);
                }}
              >
                <CardContent
                  sx={{
                    minHeight: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Stack spacing={2}>
                      <Box>
                        <Chip
                          size="small"
                          variant="filled"
                          color={getChipColor(item.contentGoalType)}
                          label={item.contentType?.name}
                        />
                      </Box>
                      {item.topic && (
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "500",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {item.topic?.name}
                        </Typography>
                      )}
                      {!item.topic && (
                        <Box
                          sx={{
                            //Dashed border with radius border
                            border: "1px dashed",
                            borderColor: "var(--mui-palette-secondary-400)",
                            borderRadius: 1,
                            pl: 2,
                            pr: 2,
                            pt: 1,
                            pb: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="var(--mui-palette-secondary-400)"
                          >
                            {t(
                              tokens.recommendations.instagram.noTopicSelected
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontSize: "1em !important",
                        p: "0 !important",
                        m: "0 !important",
                      }}
                    >
                      {moment(item.date).format("DD.MM.YYYY")}
                    </Typography>
                  </Box>
                  {/* <Badge
                    badgeContent={index + 1}
                    color="secondary"
                  ></Badge> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <ContentPlaceholderDrawer
        contentPlaceholder={contentPlaceholder}
        isOpen={isContentDrawerOpen}
        date={
          contentPlaceholder?.date
            ? new Date(contentPlaceholder.date)
            : undefined
        }
        contentTypes={contentTypes.filter((x) => !x.isTemporary)}
        onClose={() => {
          setIsContentDrawerOpen(false);
          //setCurrentEventId(undefined);
          //onTopicUpdate?.();
        }}
      />
    </>
  );
};
