import { Box, Button, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import { tokens } from "src/locales/tokens";
import { LeadMagnetCreateForm } from "src/sections/dashboard/lead-magnets/lead-magnet-create-form";

const Page: PageType = () => {
  const { t } = useTranslation();
  usePageView();

  return (
    <>
      <Seo title={t(tokens.leadMagnets.create.pageTitle) as string} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Stack spacing={3}>
          <div>
            <Button
              component={RouterLink}
              href={paths.leadMagnets.list}
              startIcon={
                <SvgIcon>
                  <ArrowLeftIcon />
                </SvgIcon>
              }
              size="small"
              color="inherit"
              variant="text"
            >
              {t(tokens.leadMagnets.create.backButton)}
            </Button>
          </div>
          <Stack spacing={1}>
            <Typography variant="h4">
              {t(tokens.leadMagnets.create.pageHeader)}
            </Typography>
          </Stack>
          <LeadMagnetCreateForm />
        </Stack>
      </Box>
    </>
  );
};

export default Page;
