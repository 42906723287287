import React, {
  useState,
  type ChangeEvent,
  type FC,
  type MouseEvent,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/topic";
import { toast } from "react-hot-toast";
import { TableLoadingRow } from "src/sections/common/table-loading-row";
import { Topic } from "src/types/topic";
// import moment from "moment";
import { ContentGoalType } from "src/types/content-goal-type";

interface TopicListTableProps {
  count: number | null;
  items: Topic[] | [];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  page: number;
  top: number;
  isLoading: boolean;
  hasError: boolean;
}

export const TopicListTable: FC<TopicListTableProps> = (props) => {
  const { t } = useTranslation();
  const {
    count = 0,
    items = null,
    onPageChange = () => {},
    onRowsPerPageChange,
    onReset = () => {},
    page = 0,
    top = 10,
    isLoading,
    hasError,
  } = props;
  const showPagination = !!page && !!top && !!count;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [topicId, setTopicId] = useState("");

  const { strategyId } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const handleCloseDeleteDialog = useCallback(() => {
    setTopicId("");
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenDeleteDialog = useCallback((id: string) => {
    setTopicId(id);
    setOpenDeleteDialog(true);
  }, []);

  const deleteTopic = useCallback(async () => {
    try {
      if (topicId && strategyId) {
        handleCloseDeleteDialog();
        await dispatch(thunks.deleteTopic(strategyId, topicId));
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleCloseDeleteDialog, onReset, topicId, strategyId, t]);

  const getContentGoalType = useCallback(
    (contentGoalType: ContentGoalType) => {
      switch (contentGoalType) {
        case 1:
          return t(tokens.general.contentGoalTypes.engagement);
        case 2:
          return t(tokens.general.contentGoalTypes.community);
        case 3:
          return t(tokens.general.contentGoalTypes.selling);
        default:
          return "";
      }
    },
    [t]
  );

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {t(tokens.topics.list.table.name) as string}
              </TableCell>
              {/* <TableCell>
                {t(tokens.topics.list.table.date) as string}
              </TableCell> */}
              <TableCell>
                {t(tokens.topics.list.table.contentGoalType) as string}
              </TableCell>
              {/* <TableCell>
                {t(tokens.topics.list.table.product) as string}
              </TableCell> */}
              <TableCell
                align="right"
                sx={{
                  minWidth: 60,
                  pl: 3,
                }}
              >
                {t(tokens.topics.list.table.actions)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoadingRow
              colSpan={4}
              isLoading={isLoading}
              hasError={hasError}
              itemsCount={items?.length ?? 0}
              noItemsText={t(tokens.topics.list.noTopicsFound) as string}
              errorLoadingText={
                t(tokens.topics.list.errorLoadingTopics) as string
              }
            />
            {items?.map((topic) => {
              return (
                <TableRow
                  hover
                  key={topic.id}
                >
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      href={createRoute(paths.topics.details).link({
                        topicId: topic.id,
                      })}
                      variant="subtitle2"
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                      >
                        {topic.name}
                      </Stack>
                    </Link>
                    {topic.product && (
                      <>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          href={createRoute(paths.products.details).link({
                            productId: topic.product.id,
                          })}
                          sx={{
                            color: "text.secondary",
                          }}
                          variant="subtitle2"
                        >
                          {topic.product.name}
                        </Link>
                      </>
                    )}
                  </TableCell>
                  {/* <TableCell>
                    {topic.date && <>{moment(topic.date).format("LL")}</>}
                  </TableCell> */}
                  <TableCell>
                    {getContentGoalType(topic.contentGoalType)}
                    {/* {topic.storyLine && (
                      <>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          href={createRoute(paths.storylines.details).link({
                            storylineId: topic.storyLine.id,
                          })}
                          variant="subtitle2"
                        >
                          {topic.storyLine.name}
                        </Link>
                      </>
                    )} */}
                  </TableCell>
                  {/* <TableCell>
                    {topic.product && (
                      <>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          href={createRoute(paths.products.details).link({
                            productId: topic.product.id,
                          })}
                          variant="subtitle2"
                        >
                          {topic.product.name}
                        </Link>
                      </>
                    )}
                  </TableCell> */}
                  <TableCell align="right">
                    <Tooltip
                      title="Delete"
                      placement="top"
                    >
                      <IconButton
                        onClick={() => handleOpenDeleteDialog(topic.id)}
                      >
                        <SvgIcon>
                          <DeleteOutlineOutlinedIcon color="error" />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {showPagination && (
        <TablePagination
          labelRowsPerPage={t(tokens.general.paginationRowsPerPage)}
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page - 1}
          rowsPerPage={top}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.topics.list.deleteTopicDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(tokens.topics.list.deleteTopicDialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteTopic}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleCloseDeleteDialog}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
