import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as strategyWizardSlice } from "src/slices/strategy-wizard";
import { StrategyStepConfiguration } from "src/types/strategy";
import { parse } from "partial-json";

const createStrategy =
  (lang: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(strategyWizardSlice.actions.setIsInitializing(true));
    dispatch(strategyWizardSlice.actions.setHasInitializationError(false));
    try {
      var strategyId = await fetchWrapper.post(
        `${api}/strategies`,
        {},
        undefined,
        lang
      );
      dispatch(strategyWizardSlice.actions.setCurrentStep(0));
      return strategyId;
    } catch (error) {
      dispatch(strategyWizardSlice.actions.setHasInitializationError(true));
      return undefined;
    } finally {
      dispatch(strategyWizardSlice.actions.setIsInitializing(false));
    }
  };

const saveStrategySection =
  (strategyId: string, key: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(
      strategyWizardSlice.actions.setSectionSavingStatus({
        key: key,
        isSaving: true,
      })
    );
    dispatch(
      strategyWizardSlice.actions.setSectionErrorStatus({
        key: key,
        hasError: false,
      })
    );
    try {
      await fetchWrapper.put(
        `${api}/strategies/${strategyId}/sections/${key}`,
        { value: value }
      );
      dispatch(
        strategyWizardSlice.actions.saveSection({
          key: key,
          value: value,
        })
      );
    } catch (error) {
      dispatch(
        strategyWizardSlice.actions.setSectionErrorStatus({
          key: key,
          hasError: true,
        })
      );
      throw error;
    } finally {
      dispatch(
        strategyWizardSlice.actions.setSectionSavingStatus({
          key: key,
          isSaving: false,
        })
      );
    }
  };

const getStrategySteps =
  (steps: StrategyStepConfiguration[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(strategyWizardSlice.actions.setStrategySteps(steps));
  };

const getStrategySectionValue =
  (strategyId: string, sectionKey: string, lang: string): AppThunk =>
  async (dispatch): Promise<any> => {
    const value = await fetchWrapper.get(
      `${api}/strategies/${strategyId}/sections/${sectionKey}`,
      {},
      undefined,
      lang
    );
    dispatch(strategyWizardSlice.actions.setSectionValue(value.value));
  };

const navigateToNextStep =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(strategyWizardSlice.actions.navigateToNextStep());
  };

const navigateToPrevStep =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(strategyWizardSlice.actions.navigateToPrevSection());
  };

const setCurrentStep =
  (index: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(strategyWizardSlice.actions.setCurrentStep(index));
  };

const generateOptions =
  (strategyId: string, key: string, lang: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(
        strategyWizardSlice.actions.setSectionGeneratingStatus({
          key: key,
          isLoading: true,
          hasError: false,
        })
      );
      await fetchWrapper
        .post(
          `${api}/strategies/${strategyId}/wizard`,
          { key: key },
          undefined,
          lang,
          "stream",
          (progressEvent: any) => {
            var dataChunk = progressEvent.event.currentTarget.response;
            var text = "";
            try {
              dataChunk = dataChunk.replace("```json", "");
              dataChunk = dataChunk.replace("json", "");
              dataChunk = dataChunk.replace("```", "");
              dataChunk = dataChunk.replace("```", "");

              if (!dataChunk.endsWith("]")) {
                dataChunk += "]";
              }
              var json = JSON.parse(dataChunk) as [];
              var str = "";
              for (let i = 0; i < json.length; i++) {
                str += (json[i] as any).value;
              }

              text = str;
              var output = parse(str);

              dispatch(
                strategyWizardSlice.actions.setSectionGeneratedOptions({
                  key: key,
                  options: output,
                })
              );
            } catch (e) {
              console.log(e);
              console.log(dataChunk);
              console.log(text);
            }
          }
        )
        .then(() => {
          dispatch(
            strategyWizardSlice.actions.setSectionGeneratingStatus({
              key: key,
              isLoading: false,
              hasError: false,
            })
          );
        })
        .catch(() => {
          dispatch(
            strategyWizardSlice.actions.setSectionGeneratingStatus({
              key: key,
              isLoading: false,
              hasError: true,
            })
          );
        });
    } catch (error) {
      dispatch(
        strategyWizardSlice.actions.setSectionGeneratingStatus({
          key: key,
          isLoading: false,
          hasError: true,
        })
      );
    }
  };

export const thunks = {
  createStrategy,
  getStrategySectionValue,
  saveStrategySection,
  getStrategySteps,
  setCurrentStep,
  generateOptions,
  navigateToNextStep,
  navigateToPrevStep,
};
