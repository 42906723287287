import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { StrategyStepConfiguration, StrategyStep } from "src/types/strategy";

interface StrategyState {
  steps: StepState[];
  wizardStep: number;
  isFinished: boolean;

  isInitializing: boolean;
  hasInitializationError: boolean;
}

interface StepState {
  isSaving: boolean;
  hasSavingError: boolean;
  strategyStep: StrategyStep;
  //value: any;
}

interface GeneratedSectionOptions {
  key: string;
  options: any[];
}

interface SectionGeneratingOptionsStatus {
  key: string;
  isLoading: boolean;
  hasError: boolean;
}

interface SectionSavingStatus {
  key: string;
  isSaving: boolean;
}

interface SectionErrorStatus {
  key: string;
  hasError: boolean;
}

interface StrategyStepValue {
  key: string;
  value: any;
}

type SetSectionSavingStatusAction = PayloadAction<SectionSavingStatus>;
type SetSectionErrorStatusAction = PayloadAction<SectionErrorStatus>;
type SaveStepValueAction = PayloadAction<StrategyStepValue>;
type GetStrategySectionsAction = PayloadAction<StrategyStepConfiguration[]>;
type SetProfileCurrentSectionNumber = PayloadAction<number>;
type SetCurrentValue = PayloadAction<any>;
type RemoveSectionValue = PayloadAction<number>;
type SetGeneratedOptions = PayloadAction<GeneratedSectionOptions>;
type SetSectionGeneratingOptionsStatus =
  PayloadAction<SectionGeneratingOptionsStatus>;
type SetIsInitialized = PayloadAction<boolean>;
type SetIsInitializationError = PayloadAction<boolean>;

const initialState: StrategyState = {
  steps: [],
  wizardStep: 0,
  isFinished: false,
  isInitializing: false,
  hasInitializationError: false,
};

const reducers = {
  setStrategySteps(
    state: StrategyState,
    action: GetStrategySectionsAction
  ): void {
    state.steps = action.payload.map((x) => {
      return {
        isSaving: false,
        hasSavingError: false,
        strategyStep: {
          configuration: x,
          value: null,
          instructionResult: undefined,
        },
      };
    });
  },

  setIsInitializing(state: StrategyState, action: SetIsInitialized): void {
    state.isInitializing = action.payload;
  },

  setHasInitializationError(
    state: StrategyState,
    action: SetIsInitializationError
  ): void {
    state.hasInitializationError = action.payload;
  },

  setSectionSavingStatus(
    state: StrategyState,
    action: SetSectionSavingStatusAction
  ): void {
    const sectionState = state.steps.find(
      (x) => x.strategyStep.configuration.sectionKey === action.payload.key
    );
    if (sectionState) {
      sectionState.isSaving = action.payload.isSaving;
    }
  },
  setSectionErrorStatus(
    state: StrategyState,
    action: SetSectionErrorStatusAction
  ): void {
    const sectionState = state.steps.find(
      (x) => x.strategyStep.configuration.sectionKey === action.payload.key
    );
    if (sectionState) {
      sectionState.hasSavingError = action.payload.hasError;
    }
  },
  saveSection(state: StrategyState, action: SaveStepValueAction): void {
    const section = state.steps.find(
      (x) => x.strategyStep.configuration.sectionKey === action.payload.key
    );
    if (section) {
      section.strategyStep.value = action.payload.value;
    }
  },

  setSectionGeneratingStatus(
    state: StrategyState,
    action: SetSectionGeneratingOptionsStatus
  ): void {
    const sectionState = state.steps.find(
      (x) => x.strategyStep.configuration.sectionKey === action.payload.key
    );
    if (sectionState) {
      sectionState.strategyStep.instructionResult = {
        options: sectionState.strategyStep.instructionResult?.options,
        isOptionsLoading: action.payload.isLoading,
        isOptionsError: action.payload.hasError,
      };
    }
  },
  setSectionGeneratedOptions(
    state: StrategyState,
    action: SetGeneratedOptions
  ): void {
    const sectionState = state.steps.find(
      (x) => x.strategyStep.configuration.sectionKey === action.payload.key
    );
    if (sectionState) {
      sectionState.strategyStep.instructionResult = {
        options: action.payload.options,
        isOptionsLoading: true,
        isOptionsError: false,
      };
    }
  },
  setCurrentStep(
    state: StrategyState,
    action: SetProfileCurrentSectionNumber
  ): void {
    state.wizardStep = action.payload;
    if (action.payload === 0) {
      state.isFinished = false;
    }
  },
  navigateToNextStep(state: StrategyState): void {
    if (state.wizardStep < state.steps.length - 1) {
      state.wizardStep++;
    } else {
      state.isFinished = true;
    }
  },
  navigateToPrevSection(state: StrategyState): void {
    if (state.wizardStep > 0 && !state.isFinished) {
      state.wizardStep--;
    }
    state.isFinished = false;
  },
  setSectionValue(state: StrategyState, action: SetCurrentValue): void {
    var stepState = state.steps[state.wizardStep];
    if (stepState) {
      stepState.strategyStep.value = action.payload;
    }
  },

  removeSectionOption(state: StrategyState, action: RemoveSectionValue): void {
    var stepState = state.steps[state.wizardStep];
    if (stepState) {
      if (stepState.strategyStep.instructionResult) {
        const items = stepState.strategyStep.instructionResult!.options as any[];
        items.splice(action.payload, 1);
        stepState.strategyStep.instructionResult!.options = items;
      }
    }
  },
};

export const slice = createSlice({
  name: "strategyWizard",
  initialState,
  reducers,
});

export const { reducer } = slice;
