import { useCallback, useState, type FC } from "react";
//import Camera01Icon from '@untitled-ui/icons-react/build/esm/Camera01';
//import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import {
  // Avatar,
  // Box,
  // Button,
  Card,
  CardContent,
  // Divider,
  Stack,
  //SvgIcon,
  // Switch,
  TextField,
  Unstable_Grid2 as Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useAuth } from "src/hooks/use-auth";
import { AuthContextType } from "src/contexts/auth/jwt-context";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { FileImage, FilePickerScenario } from "src/components/file-dropzone";
import { ImagePicker } from "src/sections/common/image-picker";

interface AccountGeneralSettingsProps {
  avatar: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImageId?: string;
  profileImageUrl?: string;
}

export const AccountGeneralSettings: FC<AccountGeneralSettingsProps> = (
  props
) => {
  const { firstName, lastName, email, profileImageId, profileImageUrl } = props;
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const { updateAccount } = useAuth<AuthContextType>();

  const [uploadedProfileImageId, setUploadedProfileImageId] = useState<
    string | undefined
  >(profileImageId);
  const [uploadedProfileImageUrl, setUploadedProfileImageUrl] = useState<
    string | undefined
  >(profileImageUrl);

  const onUpload = useCallback(async (file: FileImage) => {
    setUploadedProfileImageId(file.id);
    setUploadedProfileImageUrl(file.resizedUrl);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      profileImageId: uploadedProfileImageId,
      profileImageUrl: uploadedProfileImageUrl,
      submit: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(255, t(tokens.general.validators.maxLength255) as string)
        .required(t(tokens.general.validators.required) as string),
      lastName: Yup.string()
        .max(255, t(tokens.general.validators.maxLength255) as string)
        .required(t(tokens.general.validators.required) as string),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await updateAccount(
          values.firstName,
          values.lastName,
          values.profileImageId,
          values.profileImageUrl
        );
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success(t(tokens.account.profile.form.accountUpdatedMessage));
      } catch (err) {
        console.error(err);
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.Message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Card>
          {/* <CardHeader
            title={t(tokens.account.profile.accountDetailsHeader) as string}
          /> */}
          {/* <CardContent sx={{ pt: 0 }}> */}
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  error={
                    !!(formik.touched.firstName && formik.errors.firstName)
                  }
                  fullWidth
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  label={t(tokens.account.profile.form.firstName) as string}
                  name="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  error={!!(formik.touched.lastName && formik.errors.lastName)}
                  fullWidth
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  label={t(tokens.account.profile.form.lastName) as string}
                  name="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label={t(tokens.account.profile.form.email) as string}
                  disabled={true}
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </Grid>
              <Grid
                xs={12}
                md={12}
              >
                <Typography
                  sx={{
                    mb: 2,
                  }}
                  variant="h6"
                >
                  {t(tokens.account.profile.accountProfileImage)}
                </Typography>
                <Stack spacing={3}>
                  {uploadedProfileImageUrl && (
                    <Stack spacing={3}>
                      <img
                        width={300}
                        src={uploadedProfileImageUrl}
                      />
                      <Box>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            setUploadedProfileImageId(undefined);
                            setUploadedProfileImageUrl(undefined);
                          }}
                          size="small"
                        >
                          {t(tokens.general.buttons.delete)}
                        </Button>
                      </Box>
                    </Stack>
                  )}
                  <ImagePicker
                    scenario={FilePickerScenario.File}
                    onGenerate={onUpload}
                    onRemove={() => {
                      setUploadedProfileImageId(undefined);
                      setUploadedProfileImageUrl(undefined);
                    }}
                    //onUpload={onUpload}
                  />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>

          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            flexWrap="wrap"
            spacing={3}
            sx={{ p: 3 }}
          >
            <LoadingButton
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              type="submit"
              variant="contained"
            >
              {t(tokens.general.buttons.update)}
            </LoadingButton>
          </Stack>
        </Card>
      </Stack>
    </form>
  );
};
