import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as targetAudiencesSlice } from "src/slices/target-audiences";
import { GetTargetAudiencesQueryParams } from "src/types/target-audience";
import { urlParams } from "src/utils/url-params";

const getTargetAudiences =
  (strategyId: string, params: GetTargetAudiencesQueryParams): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(targetAudiencesSlice.actions.setLoadingStatus(true));
    dispatch(targetAudiencesSlice.actions.setErrorStatus(false));
    try {
      var targetAudience = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/target-audiences${urlParams(params)}`,
        {}
      );
      dispatch(targetAudiencesSlice.actions.getTargetAudiences(targetAudience));
    } catch (error) {
      dispatch(targetAudiencesSlice.actions.setErrorStatus(true));
    }
    dispatch(targetAudiencesSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  getTargetAudiences,
};
