import { useState, type FC } from "react";
import { Card, CardContent, Skeleton } from "@mui/material";
import { SectionTitle } from "src/sections/common/section-title";
import { ContentEditor } from "../common/content-editor";


interface BrandPartTextItemProps {
  title: string;
  emoji: string;
  text?: string;
  isLoading: boolean;
  onUpdate?: (text: string) => Promise<void>;
}

export const BrandPartTextItem: FC<BrandPartTextItemProps> = (props) => {
  const { title, emoji, text, isLoading, onUpdate } = props;
  const [isEditing, setIsEditing] = useState(false);
  const isEditAvailable = onUpdate !== undefined;

  return (
    <>
      <Card>
        <CardContent>
          <SectionTitle
            isLoading={isLoading}
            emoji={emoji}
            text={title}
            onEdit={
              !isEditAvailable
                ? undefined
                : () => {
                    setIsEditing(!isEditing);
                  }
            }
          />

          {isEditing && (
            <ContentEditor
              onCancel={async () => {
                setIsEditing(false);
              }}
              showHeaderTool={true}
              text={text ?? ""}
              onSave={async (content) => {
                await onUpdate?.(content);
                setIsEditing(false);
              }}
            />
          )}

          {!isEditing && (
            <>
              {!isLoading ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: (text ?? "").replaceAll("\n", "<br />"),
                  }}
                ></div>
              ) : (
                <>
                  <Skeleton height={40} />
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
