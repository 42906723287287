import { type FC } from "react";
import { Box, Card, CardContent, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { useDispatch } from "src/store";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Strategy } from "src/types/strategy";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";

interface Values {
  name: string;
  submit: null;
}

interface StrategyNameProps {
  strategy: Strategy;
}

export const StrategyName: FC<StrategyNameProps> = (props) => {
  const { t } = useTranslation();
  const { strategy } = props;
  const auth = useAuth();
  const isHost = auth.account?.isHost;
  const router = useRouter();

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
  });

  const initialValues: Values = {
    name: strategy?.name ?? "",
    submit: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      var newStrategy = { ...strategy };
      newStrategy.name = values.name;
      try {
        await dispatch(
          strategyThunks.updateStrategy(strategy!.id, newStrategy)
        );
        toast.success(t(tokens.strategySettings.form.successMessage));
      } catch (err) {
        console.error(err);
        toast.error(t(tokens.general.formError));
      }
      helpers.setStatus({ success: false });
      helpers.setSubmitting(false);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label={t(tokens.strategySettings.form.name)}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <Stack
                spacing={2}
                direction={"row"}
              >
                <Box>
                  <LoadingButton
                    type="submit"
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    variant="contained"
                  >
                    {t(tokens.general.buttons.update)}
                  </LoadingButton>
                </Box>
                {isHost && (
                  <Box>
                    <LoadingButton
                      type="submit"
                      onClick={async () => {
                        router.push(
                          createRoute(paths.wizard.step).link({
                            step: "0",
                            strategyId: strategy.id,
                          })
                        );
                      }}
                      variant="outlined"
                    >
                      {t(tokens.general.buttons.edit)}
                    </LoadingButton>
                  </Box>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </form>
    </>
  );
};
