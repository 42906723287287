import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as topicSlice } from "src/slices/lead-magnet";
import { ERRORS } from "src/consts";

const clearLeadMagnet =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicSlice.actions.setLeadMagnet(undefined));
  };

const createLeadMagnet =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicSlice.actions.setSavingErrorStatus(false));
    dispatch(topicSlice.actions.setSavingStatus(true));
    try {
      var topic = await fetchWrapper.post(
        `${api}/strategies/${strategyId}/lead-magnets`,
        value
      );
      dispatch(topicSlice.actions.setLeadMagnet(topic));
      return topic;
    } catch (error) {
      dispatch(topicSlice.actions.setSavingErrorStatus(true));
    }
    dispatch(topicSlice.actions.setSavingStatus(false));
    return undefined;
  };

const updateLeadMagnet =
  (strategyId: string, topicId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicSlice.actions.setSavingErrorStatus(false));
    dispatch(topicSlice.actions.setSavingStatus(true));
    try {
      var topic = await fetchWrapper.put(
        `${api}/strategies/${strategyId}/lead-magnets/${topicId}`,
        value
      );
      dispatch(topicSlice.actions.setLeadMagnet(topic));
      return topic;
    } catch (error) {
      dispatch(topicSlice.actions.setSavingErrorStatus(true));
    }
    dispatch(topicSlice.actions.setSavingStatus(false));
    return undefined;
  };

const deleteLeadMagnet =
  (strategyId: string, topicId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/strategies/${strategyId}/lead-magnets/${topicId}`,
      {}
    );
    dispatch(topicSlice.actions.setLeadMagnet(undefined));
  };

const getLeadMagnet =
  (strategyId: string, topicId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(topicSlice.actions.setLoadingStatus(true));
    try {
      var topic = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/lead-magnets/${topicId}`,
        {}
      );
      dispatch(topicSlice.actions.setLeadMagnet(topic));
    } catch (error) {
      if (error?.code === "LeadMagnet.LeadMagnetNotFound") {
        dispatch(
          topicSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR)
        );
      } else {
        dispatch(
          topicSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR)
        );
      }
    }
    dispatch(topicSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createLeadMagnet,
  updateLeadMagnet,
  deleteLeadMagnet,
  clearLeadMagnet,
  getLeadMagnet,
};
