import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as contentTemplatesSlice } from "src/slices/content-templates";
import { GetContentTemplatesQueryParams } from "src/types/content-template";
import { urlParams } from "src/utils/url-params";

const getContentTemplates =
  (strategyId: string, params: GetContentTemplatesQueryParams): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(contentTemplatesSlice.actions.setLoadingStatus(true));
    dispatch(contentTemplatesSlice.actions.setErrorStatus(false));
    try {
      var contentTemplates = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/content-templates${urlParams(params)}`,
        {}
      );
      dispatch(
        contentTemplatesSlice.actions.getContentTemplates(contentTemplates)
      );
    } catch (error) {
      dispatch(contentTemplatesSlice.actions.setErrorStatus(true));
    }
    dispatch(contentTemplatesSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  getContentTemplates,
};
