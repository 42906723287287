import * as React from "react";
import type { EventContentArg } from "@fullcalendar/core";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ContentGoalType } from "src/types/content-goal-type";
import { Chip } from "@mui/material";
import { getChipColor, getContentTypeColor } from "src/utils/content-goal-type";

export interface EventContentProps extends EventContentArg {
  event: EventContentArg["event"] & {
    extendedProps: {
      description?: string;
      contentGoalTypeTitle?: string;
      priority?: "high" | "medium" | "low";
      contentGoalType: ContentGoalType;
      contentTypeName?: string;
    };
  };
}

export const EventContent: React.FC<EventContentProps> = (props) => {
  const {
    priority = "low",
    contentGoalType,
    contentGoalTypeTitle,
    contentTypeName,
  } = props.event.extendedProps;

  const color = "var(--mui-palette-primary-main)";

  // const color =
  //   priority === "high"
  //     ? "var(--mui-palette-error-main)"
  //     : priority === "medium"
  //       ? "var(--mui-palette-warning-main)"
  //       : "transparent";

  return (
    <Paper
      sx={{
        border: "1px solid var(--mui-palette-divider)",
        boxShadow: "var(--mui-shadows-1)",
        borderRadius: 1,
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      <Stack
        direction="row"
        spacing={0}
      >
        <Box
          sx={{
            bgcolor: getContentTypeColor(contentGoalType),
            flex: "0 0 auto",
            width: "4px",
          }}
        />
        <Stack
          sx={{ p: 1 }}
          spacing={1}
        >
          <Typography
            sx={{
              // overflow: "hidden",
              // textOverflow: "ellipsis",
              textWrap: "wrap",
            }}
            variant="body2"
          >
            {props.event.title.length ? props.event.title : "No topic"}
          </Typography>
          {contentGoalTypeTitle && (
            <Box>
              <Chip
                size="small"
                variant="outlined"
                color={getChipColor(contentGoalType)}
                label={
                  <Typography sx={{ fontSize: "0.9em" }}>
                    {contentGoalTypeTitle}
                  </Typography>
                }
              />
            </Box>
          )}
          {contentTypeName && (
            <Box>
              <Chip
                size="small"
                variant="outlined"
                color={"default"}
                label={
                  <Typography sx={{ fontSize: "0.9em" }}>
                    {contentTypeName}
                  </Typography>
                }
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
