"use client";

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { TextB as TextBIcon } from "@phosphor-icons/react/dist/ssr/TextB";
import { TextItalic as TextItalicIcon } from "@phosphor-icons/react/dist/ssr/TextItalic";
import { TextStrikethrough as TextStrikethroughIcon } from "@phosphor-icons/react/dist/ssr/TextStrikethrough";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Sparkle as SparkleIcon } from "@phosphor-icons/react/dist/ssr/Sparkle";

import type { Editor } from "@tiptap/react";
import {
  Button,
  FormControl,
  Menu,
  MenuItem,
  MenuProps,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Option } from "../option";
import { styled, alpha } from "@mui/material/styles";
import { usePopover } from "src/hooks/use-popover";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    sx={{ zIndex: 1300000 }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export interface TextBubbleEditorToolbarProps {
  editor: Editor | null;
  showHeaderTool: boolean;
  onAskAI: () => void;
}

export function TextBubbleEditorToolbar({
  editor,
  showHeaderTool,
  onAskAI,
}: TextBubbleEditorToolbarProps): React.JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //setAnchorEl(event.currentTarget);
    onAskAI();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const linkPopover = usePopover<HTMLLIElement>();

  return (
    <React.Fragment>
      <Stack
        className="tiptap-toolbar"
        spacing={1}
        sx={{
          border: "1px solid var(--mui-palette-divider)",
          borderRadius: 1,
          p: "8px",
          minHeight: "57px",
          backgroundColor: "var(--mui-palette-background-paper)",
        }}
      >
        {editor ? (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ alignItems: "center", flexWrap: "wrap" }}
          >
            <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="outlined"
                size="small"
                disableElevation
                onClick={handleClick}
                startIcon={<SparkleIcon />}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Ask AI
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    onAskAI();
                    handleClose();
                  }}
                  ref={linkPopover.anchorRef}
                  // onClick={() => {
                  //   const { state, dispatch } = editor.view;
                  //   const { selection, tr } = state;

                  //   if (selection.empty) {
                  //     return false;
                  //   }
                  //   editor.commands.deleteSelection();
                  //   editor.chain().focus();
                  //   editor.commands.insertContent("sdfsdfsdf");
                  //   handleClose();
                  // }}
                  disableRipple
                >
                  Edit
                </MenuItem>
              </StyledMenu>
            </div>
            <ToolbarButton
              active={editor.isActive("bold")}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              onClick={() => {
                editor.chain().focus().toggleBold().run();
              }}
            >
              <TextBIcon />
            </ToolbarButton>
            <ToolbarButton
              active={editor.isActive("italic")}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              onClick={() => {
                editor.chain().focus().toggleItalic().run();
              }}
            >
              <TextItalicIcon />
            </ToolbarButton>
            <ToolbarButton
              active={editor.isActive("strike")}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
              onClick={() => {
                editor.chain().focus().toggleStrike().run();
              }}
            >
              <TextStrikethroughIcon />
            </ToolbarButton>
          </Stack>
        ) : null}
      </Stack>
      <Popover
        anchorEl={linkPopover.anchorRef.current}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => {
          linkPopover.handleClose();

          //setLink("");
        }}
        open={linkPopover.open}
        slotProps={{ paper: { sx: { p: 2 } } }}
      >
        <FormControl>22222222</FormControl>
      </Popover>
    </React.Fragment>
  );
}

interface ToolbarButtonProps {
  active?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
}

const ToolbarButton = React.forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  function ToolbarButton(
    { active, children, disabled, onClick },
    ref
  ): React.JSX.Element {
    return (
      <IconButton
        color={active ? "primary" : "secondary"}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </IconButton>
    );
  }
);
