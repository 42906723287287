import React from 'react';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { DomainSection } from 'src/sections/domain/domain-section';
import type { Page as PageType } from 'src/types/page';

const Page: PageType = () => {
  usePageView();
  return (
    <>
      <Seo title='Domain name generator' />
      <DomainSection />
    </>
  );
};

export default Page;
