import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as storylinesSlice } from "src/slices/storylines";
import { GetStorylinesQueryParams } from "src/types/storyline";
import { urlParams } from "src/utils/url-params";

const getStorylines =
  (strategyId: string, params: GetStorylinesQueryParams): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(storylinesSlice.actions.setLoadingStatus(true));
    dispatch(storylinesSlice.actions.setErrorStatus(false));
    try {
      var storylines = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/storylines${urlParams(params)}`,
        {}
      );
      dispatch(storylinesSlice.actions.getStorylines(storylines));
    } catch (error) {
      dispatch(storylinesSlice.actions.setErrorStatus(true));
    }
    dispatch(storylinesSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  getStorylines,
};
