import React, { useCallback, useState, type FC } from "react";
import { Strategy } from "src/types/strategy";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import toast from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "src/store";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { thunks as settingsThunks } from "src/thunks/settings";
import { CONSTS } from "src/consts";
import { useRouter } from "src/hooks/use-router";

interface StrategyDeleteProps {
  strategy: Strategy;
}

export const StrategyDelete: FC<StrategyDeleteProps> = (props) => {
  const { t } = useTranslation();
  const { strategy } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const router = useRouter();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, []);

  const deleteStrategy = useCallback(async () => {
    setIsLoading(true);
    try {
      handleCloseDeleteDialog();
      await dispatch(strategyThunks.deleteStrategy(strategy!.id));
      await dispatch(settingsThunks.setStrategyId(undefined));
      localStorage.removeItem(CONSTS.STRATEGY_ID_KEY);
      router.refresh();
    } catch (error) {
      console.error(error);
      toast.error(t(tokens.general.formError));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, handleCloseDeleteDialog, router, strategy, t]);

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Typography variant="h6">
              {t(tokens.strategySettings.detele.header)}
            </Typography>
            <Typography variant="body2">
              {t(tokens.strategySettings.detele.explanation)}
            </Typography>
            <Box>
              <LoadingButton
                variant="contained"
                color="error"
                loading={isLoading}
                disabled={isLoading}
                onClick={async () => {
                  setOpenDeleteDialog(true);
                }}
              >
                {t(tokens.strategySettings.buttons.delete)}
              </LoadingButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.strategySettings.detele.deleteStrategyDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(tokens.strategySettings.detele.deleteStrategyDialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteStrategy}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleCloseDeleteDialog}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
