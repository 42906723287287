import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ContentType } from "src/types/content-type";

interface ContentTypesState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: ContentType[];
}

const initialState: ContentTypesState = {
  isLoading: false,
  hasLoadingError: false,
  items: [],
};

const reducers = {
  setLoadingStatus: (
    state: ContentTypesState,
    action: PayloadAction<boolean>
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: ContentTypesState,
    action: PayloadAction<boolean>
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getContentTypes: (
    state: ContentTypesState,
    action: PayloadAction<ContentType[]>
  ): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: "content-types",
  initialState,
  reducers,
});

export const { reducer } = slice;
