export enum ContentGoalType {
  None,
  Engagement,
  Community,
  Selling,
}

export enum StorylineType {
  None,
  Expertise,
  Lifestyle,
  ProductOrService,
}
