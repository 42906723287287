import { useCallback, useState, type FC } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import {
  StorylineCategory,
  StorylineCategoryTopic,
} from "src/types/storyline-category";
import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { ItemMenu } from "src/sections/common/item-menu";
import { EditStorylineCategoryTopicItem } from "./edit-storyline-category-topic-item";
import { CreateStorylineCategoryTopicItem } from "./create-storyline-category-topic-item";
import { useDispatch, useSelector } from "src/store";
import toast from "react-hot-toast";
import { thunks as storylineCategoryThunks } from "src/thunks/storyline-category";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { RouterLink } from "src/components/core/link";
import { GenerateTopicDrawer } from "./generate-topic-drawer";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

interface StorylineCategoryDetailsProps {
  category: StorylineCategory;
  onDelete?: (id: string) => void;
}

export const StorylineCategoryDetails: FC<StorylineCategoryDetailsProps> = (
  props
) => {
  const { category, onDelete } = props;
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { strategyId } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [openGenerateTopicDrawer, setOpenGenerateTopicDrawer] = useState(false);

  const [categoryName, setCategoryTitle] = React.useState(category.name);
  const [categoryDescription, setCategoryDescription] = React.useState(
    category.description
  );

  const [topics, setTopics] = React.useState<StorylineCategoryTopic[]>([
    ...category.topics,
  ]);

  const handleCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, []);

  const deleteCategory = useCallback(async () => {
    try {
      if (category && strategyId) {
        handleCloseDeleteDialog();
        await dispatch(
          storylineCategoryThunks.deleteStorylineCategory(
            strategyId,
            category.storyline.id,
            category.id
          )
        );
        onDelete && onDelete(category.id);
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [category, dispatch, handleCloseDeleteDialog, onDelete, strategyId, t]);

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          {isEditing ? (
            <>
              <Formik
                validateOnChange={false}
                initialValues={{
                  name: categoryName,
                  description: categoryDescription,
                  submit: null,
                }}
                onSubmit={async (values, helpers): Promise<void> => {
                  try {
                    var updateCategory = await dispatch(
                      storylineCategoryThunks.updateStorylineCategory(
                        strategyId!,
                        category.storyline.id,
                        category.id,
                        {
                          name: values.name,
                          description: values.description,
                        }
                      )
                    );
                    setCategoryTitle(updateCategory.name);
                    setCategoryDescription(updateCategory.description);
                    // onUpdate && onUpdate(updateTopic);
                    toast.success(
                      t(
                        tokens.storylinesCategories.details.form
                          .topicUpdatedMessage
                      )
                    );

                    setIsEditing(false);
                  } catch (error) {
                    toast.error(t(tokens.general.formError));
                    helpers.setStatus({ success: false });
                    helpers.setSubmitting(false);
                  }
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .max(
                      255,
                      t(tokens.general.validators.maxLength255) as string
                    )
                    .required(t(tokens.general.validators.required) as string),

                  description: Yup.string()
                    .max(
                      1000,
                      t(tokens.general.validators.maxLength1000) as string
                    )
                    .required(t(tokens.general.validators.required) as string),
                })}
              >
                {(props) => (
                  <>
                    <Form placeholder={""}>
                      <Stack spacing={2}>
                        <TextField
                          error={!!(props.touched.name && props.errors.name)}
                          fullWidth
                          helperText={props.touched.name && props.errors.name}
                          name="name"
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          value={props.values.name}
                        />
                        <TextField
                          error={
                            !!(
                              props.touched.description &&
                              props.errors.description
                            )
                          }
                          fullWidth
                          helperText={
                            props.touched.description &&
                            props.errors.description
                          }
                          name="description"
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          value={props.values.description}
                        />

                        <Stack
                          direction="row"
                          alignItems={"start"}
                          justifyContent={"flex-start"}
                          spacing={1}
                        >
                          <LoadingButton
                            type="submit"
                            disabled={props.isSubmitting}
                            loading={props.isSubmitting}
                            variant="contained"
                          >
                            {t(tokens.general.buttons.update)}
                          </LoadingButton>
                          <Button
                            onClick={() => {
                              setIsEditing(false);
                            }}
                            color="inherit"
                            component={RouterLink}
                          >
                            {t(tokens.general.buttons.cancel)}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                  </>
                )}
              </Formik>
            </>
          ) : (
            <>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Typography variant="h4">{category.name}</Typography>
                <ItemMenu
                  onDelete={() => {
                    setOpenDeleteDialog(true);
                  }}
                  onEdit={() => {
                    setIsEditing(true);
                  }}
                />
              </Stack>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: category.description.replaceAll("\n", "<br/>"),
                }}
              />
            </>
          )}
        </CardContent>
      </Card>

      <Card sx={{ display: "flex", flexDirection: "column" }}>
        <CardHeader
          title={
            <>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
              >
                <Typography variant="h6">
                  {t(tokens.storylinesCategories.details.form.topics)}
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AutoAwesomeOutlinedIcon />}
                  onClick={() => {
                    setOpenGenerateTopicDrawer(true);
                  }}
                >
                  {t(tokens.storylinesCategories.details.form.generateTopics)}
                </Button>
              </Stack>
            </>
          }
        />
        <Divider />
        {topics.length === 0 && (
          <CardContent>
            <Typography variant="body2">
              {t(tokens.storylinesCategories.details.form.noTopics)}
            </Typography>
          </CardContent>
        )}
        <Box sx={{ overflowX: "auto" }}>
          <List disablePadding>
            {topics.map((item, index) =>
              item.id ? (
                <EditStorylineCategoryTopicItem
                  key={index}
                  storylineId={category.storyline.id}
                  storyLineCategoryId={category.id}
                  contentGoalType={category.storyline.contentGoalType}
                  topic={item}
                  showDivider={index < topics.length - 1}
                  onUpdate={(topic) => {
                    var newList = [...topics];
                    newList[index] = topic;
                    setTopics(newList);
                  }}
                  onDelete={(id) => {
                    var newList = [...topics].filter((x) => x.id !== id);
                    setTopics(newList);
                  }}
                />
              ) : (
                <CreateStorylineCategoryTopicItem
                  key={index}
                  storylineId={category.storyline.id}
                  storyLineCategoryId={category.id}
                  contentGoalType={category.storyline.contentGoalType}
                  showDivider={index < topics.length - 1}
                  onCancel={() => {
                    var newList = [...topics].filter((x, i) => i !== index);
                    setTopics(newList);
                  }}
                  onCreate={(topic) => {
                    //replace the topic with the new one
                    var newList = [...topics];
                    newList[index] = topic;
                    setTopics(newList);
                  }}
                />
              )
            )}
          </List>
        </Box>
      </Card>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            var topic = {
              id: "",
              name: "",
              description: "",
              content: "",
            };

            setTopics([...topics, topic]);
          }}
        >
          {t(tokens.storylinesCategories.details.form.addTopic)}
        </Button>
      </Box>

      <GenerateTopicDrawer
        open={openGenerateTopicDrawer}
        category={category}
        onClose={() => {
          setOpenGenerateTopicDrawer(false);
        }}
        onTopicAdd={(topic) => {
          setTopics([...topics, topic]);
        }}
        onTopicRemove={(topicId) => {
          setTopics([...topics].filter((x) => x.id !== topicId));
        }}
      />

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.storylinesCategories.details.deleteTopicDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              tokens.storylinesCategories.details.deleteTopicDialogDescription
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteCategory}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleCloseDeleteDialog}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
