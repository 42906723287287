import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { StrategyPartType } from "src/types/strategy";
import { debounce } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";

export const useStrategyPart = (partType: StrategyPartType) => {
  const { item } = useSelector((state) => state.strategy);
  const part = item?.parts.find((x) => x.partType === partType);

  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);
  const { i18n } = useTranslation();

  const fetchPart = useMemo(
    () =>
      debounce(async () => {
        await dispatch(strategyThunks.getStrategyPart(strategyId!, partType));
      }, 500),
    [dispatch, strategyId, partType]
  );

  useEffect(() => {
    let active = true;
    if (active && !part?.isReady) {
      fetchPart();
    }

    return () => {
      active = false;
    };
  }, [dispatch, fetchPart, part?.isReady]);

  const createPart = useCallback(async () => {
    await dispatch(
      strategyThunks.createStrategyPart(item!.id, partType, i18n.language)
    );
  }, [dispatch, i18n.language, item, partType]);

  return {
    item: part,
    createPart,
  };
};
