import { useState, type FC, useRef, useEffect } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface Values {
  text: string;
  submit: null;
}

interface TextFormProps {
  value?: string;
  placeholder?: string;
  isMultiline?: boolean;
  isSubmitting?: boolean;
  onSubmit: (text: string) => Promise<any>;
}

export const TextForm: FC<TextFormProps> = (props) => {

  const { onSubmit, value, placeholder, isSubmitting, isMultiline } = props;
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<Values>(
    {
      text: value ?? '',
      submit: null
    });

  useEffect(() => {
    setInitialValues({
      text: value ?? '',
      submit: null
    });

  }, [value]);

  const validationSchema = Yup.object({
    text: Yup
      .string()
      .max(1000, t(tokens.general.validators.maxLength1000) as string)
      .required(t(tokens.general.validators.required) as string)
  });

  const textRef = useRef();
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      await onSubmit(values.text);
    }
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
    // {...props}
    >
      <Stack
        spacing={3}
      >
        <TextField
          inputRef={textRef}
          error={!!(formik.touched.text && formik.errors.text)}
          helperText={formik.touched.text && formik.errors.text}
          fullWidth
          autoFocus
          multiline={isMultiline}
          minRows={3}
          sx={{
            '& input': {
              p: 2
            },
            '& textarea': {
              p: 2
            },
          }}

          InputProps={{
            sx: {
              p: 0
            }

          }}

          placeholder={placeholder ?? ""}
          name="text"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          value={formik.values.text}
        />

        <Box>
          <LoadingButton
            type="submit"
            size="large"
            loading={isSubmitting}
            variant="contained"
            sx={{
              minWidth: 200,
              height: 56,
            }}
          >
            {t(tokens.general.buttons.next)}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};