import type { FC } from "react";
import { IconButton, Skeleton, SvgIcon, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";

interface SectionTitleProps {
  emoji: string;
  text?: string;
  isLoading?: boolean;
  onEdit?: () => void;
}

export const SectionTitle: FC<SectionTitleProps> = ({
  text,
  emoji,
  isLoading,
  onEdit,
}) => {
  return (
    <Typography variant={"h6"}>
      {isLoading && <Skeleton />}
      {!isLoading && (
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          spacing={1}
        >
          <Stack
            alignContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <span>{emoji}</span>
            <span>{text}</span>
          </Stack>
          {onEdit && (
            <IconButton
              size="small"
              onClick={onEdit}
            >
              <SvgIcon>
                <EditIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
      )}
    </Typography>
  );
};
