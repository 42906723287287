import {
  Box,
  Chip,
  Container,
  Divider,
  Stack,
  Switch,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { PricingFaqs } from "src/sections/pricing/pricing-faqs";
import { PricingPlan } from "src/sections/pricing/pricing-plan";
import { PricingPlanIcon } from "src/sections/pricing/pricing-plan-icon";
import type { Page as PageType } from "src/types/page";
import { PlanType } from "src/types/strategy";
import { thunks } from "src/thunks/payment";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { PaymentOptions, PlanPrice } from "src/types/payment";

const Page: PageType = () => {
  usePageView();
  const router = useRouter();
  const { i18n, t } = useTranslation();
  const [paymentOptions, setPaymentOptions] = useState<
    PaymentOptions | undefined
  >(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        var plans = await thunks.getPlans(i18n.language);
        setPaymentOptions(plans);
      } catch (error) {
        toast.error(tokens.general.formError);
      }
    }

    fetchData();
  }, [i18n.language]);

  const getPrice = useCallback((planPrice: PlanPrice | undefined) => {
    if (!planPrice) {
      return "-";
    } else if (planPrice.currency === "UAH") {
      return planPrice.price.toString() + "₴";
    }

    return "$" + planPrice.price.toString();
  }, []);

  return (
    <>
      <Seo title="Pricing" />

      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mb: 4,
          }}
        >
          <Typography variant="h3">Upgrade your account today 🔥</Typography>
          <Typography
            color="text.secondary"
            sx={{ my: 2 }}
            variant="body1"
          >
            Finish your content strategy and start growing your audience with
            clarity.
          </Typography>
          {/* <Stack
            alignItems="center"
            direction="row"
            spacing={1}
          >
            <Switch checked />
            <Typography variant="body1">Yearly Payment</Typography>
            <Chip
              color="primary"
              label="25% OFF"
              size="small"
            />
          </Stack> */}
        </Box>
        <Grid
          container
          spacing={4}
        >
          <Grid
            xs={12}
            md={4}
          >
            <PricingPlan
              cta=""
              description="1 strategy"
              features={["1 strategy", "5+ products", "Email alerts"]}
              icon={<PricingPlanIcon name="startup" />}
              name={t(tokens.general.plans.free)}
              price={getPrice(undefined)}
              sx={{
                height: "100%",
                maxWidth: 460,
                mx: "auto",
              }}
              onClick={() => {
                router.replace(
                  paths.payment.index +
                    `?planType=${PlanType.Strategy}&productName=Strategy`
                );
              }}
            />
          </Grid>
          <Grid
            xs={12}
            md={4}
          >
            <PricingPlan
              cta="Upgrade Now"
              description="1 strategy"
              features={[
                "All previous",
                "Highlights reporting",
                "Data history",
                "Unlimited users",
              ]}
              icon={<PricingPlanIcon name="standard" />}
              name={t(tokens.general.plans.basic)}
              popular
              price={getPrice(paymentOptions?.basic)}
              sx={{
                height: "100%",
                maxWidth: 460,
                mx: "auto",
              }}
              onClick={() => {
                router.replace(
                  paths.payment.index +
                    `?planType=${PlanType.ContentPlan}&productName=Strategy and Content Plan`
                );
              }}
            />
          </Grid>
          <Grid
            xs={12}
            md={4}
          >
            <PricingPlan
              cta="Upgrade Now"
              description="Multiple strategies"
              features={[
                "All previous",
                "Unlimited contacts",
                "Analytics platform",
                "Public API access",
                "Send and sign unlimited contracts",
              ]}
              icon={<PricingPlanIcon name="business" />}
              name={t(tokens.general.plans.professional)}
              price={getPrice(paymentOptions?.professional)}
              sx={{
                height: "100%",
                maxWidth: 460,
                mx: "auto",
              }}
            />
          </Grid>
        </Grid>
        {/* <Box sx={{ mt: 4 }}>
          <Typography
            align="center"
            color="text.secondary"
            component="p"
            variant="caption"
          >
            30% of our income goes into Whale Charity
          </Typography>
        </Box> */}
      </Box>
      <Divider />
      <PricingFaqs />
    </>
  );
};

export default Page;
