import { useCallback, useEffect } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import { Box, Button, Stack, SvgIcon } from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
// import { AccountBillingSettings } from 'src/sections/dashboard/account/account-billing-settings';
// import { AccountNotificationsSettings } from 'src/sections/dashboard/account/account-notifications-settings';
// import { AccountTeamSettings } from 'src/sections/dashboard/account/account-team-settings';
// import { AccountSecuritySettings } from 'src/sections/dashboard/account/account-security-settings';
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks as targetAudienceThunks } from "src/thunks/target-audience";
import { useParams } from "react-router";
import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import NotFound from "src/sections/errors/not-found";
import { ERRORS } from "src/consts";
import ServerError from "src/sections/errors/server-error";
import { TargetAudienceDetails } from "src/sections/dashboard/target-audiences/target-audience-details";
import EditIcon from "@mui/icons-material/Edit";
import { createRoute } from "react-router-url-params";
import { useRouter } from "src/hooks/use-router";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { targetAudienceId } = useParams();
  const router = useRouter();

  const {
    item: targetAudience,
    error: targetAudienceError,
    //isLoading: isProductLoading,
  } = useSelector((state) => state.targetAudience);
  const { strategyId } = useSelector((state) => state.settings);

  const loadData = useCallback(async () => {
    if (targetAudienceId) {
      await dispatch(
        targetAudienceThunks.getTargetAudience(strategyId!, targetAudienceId)
      );
    }
  }, [dispatch, targetAudienceId, strategyId]);

  useEffect(() => {
    loadData();

    return () => {
      dispatch(targetAudienceThunks.clearTargetAudience());
    };
  }, [targetAudienceId, loadData, dispatch]);

  if (targetAudienceError) {
    if (targetAudienceError.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!targetAudienceId || !targetAudience) {
    return;
  }

  return (
    <>
      <Seo title={targetAudience.name} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Button
              component={RouterLink}
              href={paths.targetAudiences.list}
              startIcon={
                <SvgIcon>
                  <ArrowLeftIcon />
                </SvgIcon>
              }
              size="small"
              color="inherit"
              variant="text"
            >
              {t(tokens.targetAudiences.edit.backToTargetAudiencessButton)}
            </Button>

            {/* <Button
              component={RouterLink}
              href={createRoute(paths.targetAudiences.edit).link({
                targetAudienceId: targetAudience.id,
              })}
              startIcon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              size="small"
              color="inherit"
              variant="outlined"
            >
              {t(tokens.general.buttons.edit)}
            </Button> */}
          </Stack>

          <TargetAudienceDetails
            targetAudience={targetAudience}
            onEdit={() => {
              var path = createRoute(paths.targetAudiences.edit).link({
                targetAudienceId: targetAudience.id,
              });
              router.push(path);
            }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default Page;
