import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { PlanType } from "src/types/strategy";
import { PaymentOptions } from "src/types/payment";

const getWayForPayConfiguration = (
  strategyId: string,
  productName: string,
  planType: PlanType,
  lang: string
): Promise<any> =>
  fetchWrapper.post(
    `${api}/payment/way-for-pay/configuration`,
    {
      planType: PlanType[planType],
      strategyId: strategyId,
      productName: productName,
    },
    undefined,
    lang
  );

const getPlans = (lang: string): Promise<PaymentOptions> =>
  fetchWrapper.get(`${api}/payment/plans`, {}, undefined, lang);

export const thunks = {
  getConfiguration: getWayForPayConfiguration,
  getPlans: getPlans,
};
