import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as strategiesSlice } from "src/slices/strategies";
import { StrategyList } from "src/types/strategies";

const getStrategies =
  (): AppThunk =>
  async (dispatch): Promise<StrategyList[]> => {
    dispatch(strategiesSlice.actions.setLoadingStatus(true));
    dispatch(strategiesSlice.actions.setErrorStatus(false));
    try {
      var strategies = await fetchWrapper.get(`${api}/strategies`, {});
      dispatch(strategiesSlice.actions.getStrategies(strategies));
      return strategies;
    } catch (error) {
      dispatch(strategiesSlice.actions.setErrorStatus(true));
      throw error;
    } finally {
      dispatch(strategiesSlice.actions.setLoadingStatus(false));
    }
  };

const deleteStrategy =
  (strategyId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(`${api}/strategies/${strategyId}`, {});
    dispatch(strategiesSlice.actions.deleteStrategy(strategyId));
  };

export const thunks = {
  getStrategies,
  deleteStrategy,
};
