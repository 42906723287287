import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as contentPlaceholderSlice } from "src/slices/content-placeholder";
import { slice as contentPlaceholdersSlice } from "src/slices/content-placeholders";
import { ERRORS } from "src/consts";

const clearContentPlaceholder =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(contentPlaceholderSlice.actions.setContentPlaceholder(undefined));
  };

const createContentPlaceholder =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var contentPlaceholder = await fetchWrapper.post(
      `${api}/strategies/${strategyId}/content-placeholders`,
      value
    );
    dispatch(contentPlaceholderSlice.actions.setContentPlaceholder(contentPlaceholder));
    return contentPlaceholder;
  };

const updateContentPlaceholder =
  (strategyId: string, contentPlaceholderId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var contentPlaceholder = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/content-placeholders/${contentPlaceholderId}`,
      value
    );
    dispatch(contentPlaceholderSlice.actions.setContentPlaceholder(contentPlaceholder));
    dispatch(contentPlaceholdersSlice.actions.updateContentPlaceholder(contentPlaceholder));
    return contentPlaceholder;
  };

const deleteContentPlaceholder =
  (strategyId: string, contentPlaceholderId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/strategies/${strategyId}/content-placeholders/${contentPlaceholderId}`,
      {}
    );
    dispatch(contentPlaceholderSlice.actions.setContentPlaceholder(undefined));
  };

const getContentPlaceholder =
  (strategyId: string, contentPlaceholderId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(contentPlaceholderSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(contentPlaceholderSlice.actions.setLoadingStatus(true));
    try {
      var contentPlaceholder = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/content-placeholders/${contentPlaceholderId}`,
        {}
      );
      dispatch(contentPlaceholderSlice.actions.setContentPlaceholder(contentPlaceholder));
    } catch (error) {
      if (error?.code === "ContentPlaceholder.ContentPlaceholderNotFound") {
        dispatch(
          contentPlaceholderSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR)
        );
      } else {
        dispatch(
          contentPlaceholderSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR)
        );
      }
    }
    dispatch(contentPlaceholderSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createContentPlaceholder,
  updateContentPlaceholder,
  deleteContentPlaceholder,
  clearContentPlaceholder,
  getContentPlaceholder,
};
