import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultError } from "src/types/error";
import { SocialProfile } from "src/types/social-profile";

interface SocialProfileState {
  item: SocialProfile | undefined;
  isLoading: boolean;
  error: ResultError | undefined;
}

const initialState: SocialProfileState = {
  item: undefined,
  isLoading: false,
  error: undefined,
};

const reducers = {
  setLoadingStatus: (
    state: SocialProfileState,
    action: PayloadAction<boolean>
  ): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: SocialProfileState,
    action: PayloadAction<ResultError | undefined>
  ): void => {
    state.error = action.payload;
  },
  getSocialProfile: (
    state: SocialProfileState,
    action: PayloadAction<SocialProfile | undefined>
  ): void => {
    state.item = action.payload;
  },
};

export const slice = createSlice({
  name: "social-profile",
  initialState,
  reducers,
});

export const { reducer } = slice;
