import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as targetAudienceSlice } from "src/slices/target-audience";
import { ERRORS } from "src/consts";

const clearTargetAudience =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(targetAudienceSlice.actions.setTargetAudience(undefined));
  };

const createTargetAudience =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(targetAudienceSlice.actions.setSavingErrorStatus(false));
    dispatch(targetAudienceSlice.actions.setSavingStatus(true));
    try {
      var targetAudience = await fetchWrapper.post(
        `${api}/strategies/${strategyId}/target-audiences`,
        value
      );
      dispatch(targetAudienceSlice.actions.setTargetAudience(targetAudience));
      return targetAudience;
    } catch (error) {
      dispatch(targetAudienceSlice.actions.setSavingErrorStatus(true));
    }
    dispatch(targetAudienceSlice.actions.setSavingStatus(false));
    return undefined;
  };

const updateTargetAudience =
  (strategyId: string, targetAudienceId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(targetAudienceSlice.actions.setSavingErrorStatus(false));
    dispatch(targetAudienceSlice.actions.setSavingStatus(true));
    try {
      var targetAudience = await fetchWrapper.put(
        `${api}/strategies/${strategyId}/target-audiences/${targetAudienceId}`,
        value
      );
      dispatch(targetAudienceSlice.actions.setTargetAudience(targetAudience));
      return targetAudience;
    } catch (error) {
      dispatch(targetAudienceSlice.actions.setSavingErrorStatus(true));
    }
    dispatch(targetAudienceSlice.actions.setSavingStatus(false));
    return undefined;
  };

const deleteTargetAudience =
  (strategyId: string, targetAudienceId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/strategies/${strategyId}/target-audiences/${targetAudienceId}`,
      {}
    );
    dispatch(targetAudienceSlice.actions.setTargetAudience(undefined));
  };

const getTargetAudience =
  (strategyId: string, targetAudienceId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(targetAudienceSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(targetAudienceSlice.actions.setLoadingStatus(true));
    try {
      var targetAudience = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/target-audiences/${targetAudienceId}`,
        {}
      );
      dispatch(targetAudienceSlice.actions.setTargetAudience(targetAudience));
    } catch (error) {
      if (error?.code === "TargetAudience.TargetAudienceNotFound") {
        dispatch(
          targetAudienceSlice.actions.setLoadingErrorStatus(
            ERRORS.NOT_FOUND_ERROR
          )
        );
      } else {
        dispatch(
          targetAudienceSlice.actions.setLoadingErrorStatus(
            ERRORS.GENERAL_ERROR
          )
        );
      }
    }
    dispatch(targetAudienceSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createTargetAudience,
  updateTargetAudience,
  deleteTargetAudience,
  clearTargetAudience,
  getTargetAudience,
};
