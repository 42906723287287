import * as React from "react";
//import { AuthGuard } from "src/components/auth/auth-guard";
import { DynamicLayout } from "./dynamic-layout";
import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSelector } from "src/store";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = withAuthGuard((props) => {
  const { children } = props;

  const { items } = useSelector((state) => state.strategies);
  const router = useRouter();

  React.useEffect(() => {
    if (items.length === 0) {
      //router.push(paths.index, {  });
      window.location.href = paths.index;
    }
  }, [items, router]);

  return <DynamicLayout>{children}</DynamicLayout>;
});
