import { useRef, useState, type FC } from "react";
import { Button, Stack } from "@mui/material";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  TopicGenericEditForm,
  TopicGenericEditHandle,
} from "./topic-edit-generic-form";

export const TopicCreateForm: FC = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const childRef = useRef<TopicGenericEditHandle>(null);

  return (
    <Stack spacing={4}>
      <TopicGenericEditForm
        onSubmitStatus={(isSubmitting) => {
          setIsSubmitting(isSubmitting);
        }}
        onSubmit={(topicId) => {
          router.push(
            createRoute(paths.topics.details).link({
              topicId: topicId,
            })
          );
        }}
        ref={childRef}
        topic={undefined}
      />
      <Stack
        alignItems="center"
        direction="row-reverse"
        justifyContent="flex-end"
        spacing={1}
      >
        <Button
          href={paths.topics.list}
          color="inherit"
          component={RouterLink}
        >
          {t(tokens.general.buttons.cancel)}
        </Button>
        <LoadingButton
          type="submit"
          onClick={() => {
            childRef.current?.handleSubmit();
          }}
          disabled={isSubmitting}
          loading={isSubmitting}
          variant="contained"
        >
          {t(tokens.general.buttons.create)}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
