import type { FC } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { InstagramLogo } from "@phosphor-icons/react/dist/ssr/InstagramLogo";
import Grid from "@mui/material/Grid";
import { tokens } from "src/locales/tokens";
import { Icon } from "@phosphor-icons/react/dist/lib/types";
import { SmileyNervous } from "@phosphor-icons/react/dist/ssr/SmileyNervous";
import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import { CrownSimple as CrownSimpleInon } from "@phosphor-icons/react/dist/ssr/CrownSimple";

interface PaidAccessProps {
  title: string;
}

export const PaidAccess: FC<PaidAccessProps> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card
        sx={{
          mt: 1,
        }}
      >
        <CardContent>
          <Box sx={{ mb: 5, mt: 3 }}>
            <Typography
              variant="h2"
              align="center"
              color="var(--mui-palette-primary-main)"
            >
              Unlock full access to the rest of strategy
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Stack spacing={3}>
                <PaidAccessItem
                  text="Рекомендації Інстраграм"
                  icon={InstagramLogo}
                />
                <PaidAccessItem
                  text="Рекомендації як працювати зі страхами"
                  icon={SmileyNervous}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Stack spacing={3}>
                <PaidAccessItem
                  text="Рекомендації Інстраграм"
                  icon={InstagramLogo}
                />
                <PaidAccessItem
                  text="Рекомендації Інстраграм"
                  icon={InstagramLogo}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Stack spacing={3}>
                <PaidAccessItem
                  text="Рекомендації Інстраграм"
                  icon={InstagramLogo}
                />
                <PaidAccessItem
                  text="Рекомендації Інстраграм"
                  icon={InstagramLogo}
                />
              </Stack>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              variant="contained"
              size="large"
              component={RouterLink}
              // sx={{
              //   display: { xs: "none", lg: "inline-flex" },
              // }}
              href={paths.pricing}
              startIcon={<CrownSimpleInon />}
            >
              {/* {t(tokens.nav.upgradeAccount)} */}
              Get Full Access
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

interface PaidAccessItemProps {
  text: string;
  icon: Icon;
}

export const PaidAccessItem: FC<PaidAccessItemProps> = ({ text, icon }) => {
  const Icon = icon;
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
      >
        <Icon
          color="var(--mui-palette-primary-main)"
          fontSize="var(--icon-fontSize-xl)"
        />
        <Box>{text}</Box>
      </Stack>
    </>
  );
};
