"use client";

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { TextB as TextBIcon } from "@phosphor-icons/react/dist/ssr/TextB";
import { TextItalic as TextItalicIcon } from "@phosphor-icons/react/dist/ssr/TextItalic";
import { TextStrikethrough as TextStrikethroughIcon } from "@phosphor-icons/react/dist/ssr/TextStrikethrough";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Sparkle as SparkleIcon } from "@phosphor-icons/react/dist/ssr/Sparkle";
import ReactMarkdown from "react-markdown";

import type { Editor } from "@tiptap/react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
  MenuProps,
  OutlinedInput,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Option } from "../option";
import { styled, alpha } from "@mui/material/styles";
import { usePopover } from "src/hooks/use-popover";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "src/store";
import { thunks as promptsThunks } from "src/thunks/prompts";
import { useTranslation } from "react-i18next";
import axios, { CancelTokenSource } from "axios";
import toast from "react-hot-toast";
import { tokens } from "src/locales/tokens";
import { PromptMessage, PromptMessageUser } from "src/types/prompts";

export interface AiTextFieldProps {
  editor: Editor;
  onInsert: (text: string) => void;
}

const schema = zod.object({
  text: zod.string().min(1, { message: "Text is required" }),
});

type Values = zod.infer<typeof schema>;

const defaultValues = {
  text: "",
} satisfies Values;

export function AiTextField({
  editor,
  onInsert,
}: AiTextFieldProps): React.JSX.Element {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Values>({ defaultValues, resolver: zodResolver(schema) });
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { strategyId } = useSelector((state) => state.settings);
  const [cancelTokenSource, setCancelTokenSource] =
    React.useState<CancelTokenSource>(axios.CancelToken.source());

  const [generatedText, setGeneratedText] = React.useState<string>("");
  const { state } = editor!.view;

  const { from, to, empty } = editor.state.selection;

  const onSubmit = React.useCallback(
    async (values: string): Promise<void> => {
      const selectedText = editor.state.doc.textBetween(from, to, " ");

      var messages = [
        {
          user: PromptMessageUser.System,
          content: `Rewrite text in the markdown format:\n${selectedText}`,
        },
      ] as PromptMessage[];

      setShowGeneratedText(true);
      await dispatch(
        promptsThunks.runChatPrompt(
          strategyId!,
          i18n.language,
          {
            messages: messages,
          },
          (text) => {
            setGeneratedText(text);
          },
          () => {
            toast.error(t(tokens.general.formError));
          },
          (text) => {
            console.log(text);
            setGeneratedText(text);
          },
          cancelTokenSource
        )
      );
    },
    [
      cancelTokenSource,
      dispatch,
      editor.state.doc,
      from,
      i18n.language,
      strategyId,
      t,
      to,
    ]
  );

  const [showGeneratedText, setShowGeneratedText] =
    React.useState<boolean>(false);

  return (
    <React.Fragment>
      {!showGeneratedText ? (
        <Controller
          control={control}
          name="text"
          render={({ field }) => (
            <FormControl error={Boolean(errors.text)}>
              <OutlinedInput
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    onSubmit(field.value);
                    ev.preventDefault();
                  }
                }}
                {...field}
                inputRef={(input) => input && input.focus()}
              />
              {errors.text ? (
                <FormHelperText>{errors.text.message}</FormHelperText>
              ) : null}
            </FormControl>
          )}
        />
      ) : (
        <>
          <Box
            sx={{
              p: 1,
              height: "200px",
              width: "100%",
              overflowY: "auto",
            }}
          >
            <ReactMarkdown>{generatedText}</ReactMarkdown>
          </Box>

          <Button
            onClick={() => {
              onInsert(generatedText);
            }}
          >
            Insert
          </Button>
        </>
      )}
    </React.Fragment>
  );
}
