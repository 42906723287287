import { styled } from "@mui/material/styles";
// import { ContentCategoriesPart } from 'src/sections/strategies/parts/content-categories-part';

const StrategyContentRoot = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  "& h2": {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.h5.lineHeight,
    marginBottom: theme.spacing(0),
  },
  "& h6": {
    //fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    //lineHeight: theme.typography.h3.lineHeight,
    marginBottom: theme.spacing(2),
  },
  "& p": {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  "& ul": {
    margin: 15,
    marginLeft: 0,
  },
  "& li": {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(1),
  },
  "& li:last-child": {
    marginBottom: 0,
  },
}));

export default StrategyContentRoot;
