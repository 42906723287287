import React, { type FC, useCallback } from "react";
import { Box, Button, Card, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { thunks as strategyThunks } from "src/thunks/strategy-wizard";
import { useDispatch, useSelector } from "src/store";
import { alpha } from "@mui/system";
// import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { slice as strategyWizardSlice } from "src/slices/strategy-wizard";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { toast } from "react-hot-toast";
import { tokens } from "src/locales/tokens";
import { SectionFormType } from "src/types/strategy";
import { useParams } from "react-router";

export const WizardAIOptions: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { strategyId } = useParams();
  const steps = useSelector((state) => state.strategyWizard.steps);
  const wizardStep = useSelector((state) => state.strategyWizard.wizardStep);
  const currentStep = steps[wizardStep];
  const currentValue = currentStep.strategyStep.value;

  const copyIdea = useCallback(
    async (value: any, index: number) => {
      if (
        currentStep?.strategyStep.configuration.formType ===
        SectionFormType.List
      ) {
        if (
          currentStep?.strategyStep?.configuration?.options?.maxItems &&
          currentValue?.items?.length >=
            currentStep?.strategyStep?.configuration?.options?.maxItems
        ) {
          toast.error(
            t(tokens.wizard.validators.maxItems) +
              currentStep?.strategyStep?.configuration?.options?.maxItems
          );
          return;
        }
        const items = [...(currentValue?.items ?? [])];
        items.push(value.title);
        dispatch(strategyWizardSlice.actions.setSectionValue({ items: items }));
        dispatch(strategyWizardSlice.actions.removeSectionOption(index));
      } else if (
        currentStep?.strategyStep.configuration.formType ===
        SectionFormType.LongText
      ) {
        dispatch(
          strategyWizardSlice.actions.setSectionValue({ text: value.title })
        );
      } else if (
        currentStep?.strategyStep.configuration.formType ===
        SectionFormType.ShortText
      ) {
        dispatch(
          strategyWizardSlice.actions.setSectionValue({ title: value.title })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, currentValue]
  );

  const regenerateIdeas = useCallback(async () => {
    try {
      await dispatch(
        strategyThunks.generateOptions(
          strategyId!,
          currentStep!.strategyStep.configuration.sectionKey!,
          i18n.language
        )
      );
    } catch (error) {
      toast.error(t(tokens.general.formError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, i18n.language, strategyId, t]);

  if (!currentStep?.strategyStep.configuration.hasInstruction) {
    return null;
  }

  return (
    <Card
      sx={{
        // backgroundColor: 'background.default',
        p: 4,
        mb: 4,
      }}
    >
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",

          mb: 3,
        }}
      >
        <Typography color="">
          <AutoAwesomeIcon
            color="primary"
            sx={{
              fontSize: "48px",
            }}
          />
        </Typography>
        <Typography>{t(tokens.wizard.aiHeader)}</Typography>
      </Stack>
      <Stack
        direction={"column"}
        // spacing={1}
      >
        {/* {currentStep?.step.instructionResult?.isOptionsLoading && (
          <Stack
            direction="column"
            alignItems={"center"}
          >
            <CircularProgress />
          </Stack>
        )} */}

        {currentStep?.strategyStep.instructionResult?.options &&
          currentStep?.strategyStep.instructionResult.options.map(
            (item, index) => {
              return (
                <React.Fragment key={index}>
                  <Box>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                    >
                      <Box sx={{ width: "100%" }}>
                        {(currentStep.strategyStep.configuration.formType ===
                          SectionFormType.List ||
                          currentStep.strategyStep.configuration.formType ===
                            SectionFormType.LongText ||
                          currentStep.strategyStep.configuration.formType ===
                            SectionFormType.ShortText) && (
                          <Typography
                            variant="body2"
                            key={index}
                          >
                            {item.title}
                          </Typography>
                        )}
                      </Box>
                      <Button
                        onClick={async () => {
                          await copyIdea(item, index);
                        }}
                        variant="outlined"
                        sx={{
                          pt: 0.5,
                          pb: 0.5,
                          pr: 2,
                          pl: 2,
                          minWidth: "auto",
                        }}
                      >
                        {currentStep.strategyStep.configuration.formType ===
                          SectionFormType.List && (
                          <>{t(tokens.general.buttons.add)}</>
                        )}
                        {(currentStep.strategyStep.configuration.formType ===
                          SectionFormType.LongText ||
                          currentStep.strategyStep.configuration.formType ===
                            SectionFormType.ShortText) && (
                          <>{t(tokens.general.buttons.copy)}</>
                        )}
                      </Button>
                    </Stack>
                    {index !==
                      currentStep.strategyStep.instructionResult!.options!
                        .length -
                        1 && (
                      <Box
                        sx={{
                          pt: 2,
                          pb: 2,
                        }}
                      >
                        <Divider
                          sx={{
                            borderColor: (theme) =>
                              alpha(theme.palette.grey[300], 0.8),
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </React.Fragment>
              );
            }
          )}

        {(!currentStep?.strategyStep.instructionResult?.isOptionsLoading ||
          currentStep.strategyStep.instructionResult?.isOptionsError) && (
          <Stack
            sx={{
              mt: 2,
            }}
            direction="column"
            alignItems={"center"}
          >
            <LoadingButton
              onClick={regenerateIdeas}
              startIcon={<AutoAwesomeIcon />}
              size="medium"
              loading={
                currentStep?.strategyStep.instructionResult?.isOptionsLoading
              }
              variant="outlined"
            >
              {t(tokens.general.buttons.generateAgain)}
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
