import { useCallback, useEffect } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
// import { AccountBillingSettings } from 'src/sections/dashboard/account/account-billing-settings';
// import { AccountNotificationsSettings } from 'src/sections/dashboard/account/account-notifications-settings';
// import { AccountTeamSettings } from 'src/sections/dashboard/account/account-team-settings';
// import { AccountSecuritySettings } from 'src/sections/dashboard/account/account-security-settings';
import type { Page as PageType } from "src/types/page";
import { useDispatch, useSelector } from "src/store";
import { thunks as productThunks } from "src/thunks/product";
import NotFound from "src/sections/errors/not-found";
import { ERRORS } from "src/consts";
import ServerError from "src/sections/errors/server-error";
import { ProductDetails } from "src/sections/dashboard/products/product-details";
import { useStrategyPart } from "src/sections/dashboard/hooks/use-strategy-part";
import { StrategyPartType } from "src/types/strategy";
import { GenerateAgain } from "src/sections/dashboard/common/generate-again";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/hooks/use-auth";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const Page: PageType = () => {
  usePageView();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    item: product,
    error: productError,
    //isLoading: isProductLoading,
  } = useSelector((state) => state.product);

  const { item } = useSelector((state) => state.strategy);
  const part = useStrategyPart(StrategyPartType.Product);
  const { strategyId } = useSelector((state) => state.settings);
  const { account } = useAuth();

  const loadData = useCallback(async () => {
    if (item?.product) {
      await dispatch(productThunks.getProduct(strategyId!, item.product.id));
    }
  }, [dispatch, item?.product, strategyId]);

  useEffect(() => {
    loadData();

    return () => {
      dispatch(productThunks.clearProduct());
    };
  }, [item?.product, loadData, dispatch]);

  if (productError) {
    if (productError.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!item?.product || !product) {
    return;
  }
  
  return (
    <>
      <Seo title={product.name} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          {!part?.item?.isGenerating && !part?.item?.isReady && (
            <GenerateAgain
              createPart={part.createPart}
              error={t(tokens.targetAudiences.list.noTargetAudiencesFound)}
              isGenerating={part?.item?.isGenerating ?? false}
            />
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                {t(tokens.products.details.pageHeader)}
              </Typography>
            </Stack>

            {part?.item?.isReady && account?.isHost && (
              <IconButton
                size="small"
                disabled={part?.item?.isGenerating}
                onClick={part.createPart}
              >
                <SvgIcon>
                  <RefreshOutlinedIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>

          {part?.item?.isGenerating && (
            <>
              <Card>
                <CardContent>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </CardContent>
              </Card>
            </>
          )}

          {part?.item?.isReady && <ProductDetails product={product} />}
        </Stack>
      </Box>
    </>
  );
};

export default Page;
