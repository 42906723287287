import { useCallback, useState, type FC } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { map } from "lodash";
import toast from "react-hot-toast";
import { ComplexityType, GeneratedLeadMagnet } from "src/types/lead-magnet";
import { thunks as topicsThunks } from "src/thunks/lead-magnets";
import { thunks as topicThunks } from "src/thunks/lead-magnet";
//import { thunks as targetAudiencesThunks } from "src/thunks/target-audiences";
import { Product } from "src/types/product";
import axios, { CancelTokenSource } from "axios";

interface Values {
  name: string;
  instruction: string;
  product: Product | null;
  status: number;
  submit: null;
}

const initialValues: Values = {
  name: "",
  instruction: "",
  status: 0,
  product: null,
  submit: null,
};

interface LeadMagnetGenerationFormProps {
  onLeadMagnetAdd?: (topic: GeneratedLeadMagnet) => void;
  onLeadMagnetRemove?: (topic: GeneratedLeadMagnet) => void;
}

export const LeadMagnetGenerationForm: FC<LeadMagnetGenerationFormProps> = (
  props
) => {
  const { onLeadMagnetAdd, onLeadMagnetRemove } = props;
  const { t, i18n } = useTranslation();
  const { strategyId } = useSelector((state) => state.settings);

  const validationSchema = Yup.object({
    // product: Yup.object().required(
    //   t(tokens.general.validators.required) as string
    // ),
  });
  const [leadMagnets, setLeadMagnets] = useState<GeneratedLeadMagnet[]>([]);
  const dispatch = useDispatch();

  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      setLeadMagnets([]);
      await dispatch(
        topicsThunks.generateLeadMagnets(
          strategyId!,
          i18n.language,
          {
            instruction: values.instruction,
          },
          (items) => {
            setLeadMagnets(items);
          },
          () => {
            toast.error(t(tokens.general.formError));
          },
          cancelTokenSource
        )
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <TextField
                error={
                  !!(formik.touched.instruction && formik.errors.instruction)
                }
                fullWidth
                multiline
                minRows={2}
                helperText={
                  formik.touched.instruction && formik.errors.instruction
                }
                label={t(tokens.leadMagnets.generator.form.instruction)}
                name="instruction"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.instruction}
              />
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-start"
                spacing={1}
              >
                <LoadingButton
                  type="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.leadMagnets.generator.buttons.generateLeadMagnets)}
                </LoadingButton>

                {formik.isSubmitting && (
                  <LoadingButton
                    type="button"
                    onClick={() => {
                      cancelTokenSource.cancel();
                      setCancelTokenSource(axios.CancelToken.source());
                    }}
                    variant="outlined"
                  >
                    {t(tokens.general.buttons.cancel)}
                  </LoadingButton>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        {map(leadMagnets, (leadMagnet, index) => (
          <GeneratedLeadMagnetItem
            key={index}
            productId={formik.values.product?.id}
            leadMagnet={leadMagnet}
            onLeadMagnetAdd={onLeadMagnetAdd}
            onLeadMagnetRemove={onLeadMagnetRemove}
          />
        ))}
      </Stack>
    </form>
  );
};

interface GeneratedLeadMagnetItemProps {
  leadMagnet: GeneratedLeadMagnet;
  productId?: string;
  onLeadMagnetAdd?: (topic: GeneratedLeadMagnet) => void;
  onLeadMagnetRemove?: (topic: GeneratedLeadMagnet) => void;
}

export const GeneratedLeadMagnetItem: FC<GeneratedLeadMagnetItemProps> = (
  props
) => {
  const { t } = useTranslation();
  const { leadMagnet, productId, onLeadMagnetAdd, onLeadMagnetRemove } = props;
  const { strategyId } = useSelector((state) => state.settings);
  const [isAdded, setIsAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [topicId, setLeadMagnetId] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const addLeadMagnet = useCallback(
    async (topic: GeneratedLeadMagnet) => {
      setIsLoading(true);
      try {
        const newLeadMagnet = await dispatch(
          topicThunks.createLeadMagnet(strategyId!, {
            name: topic.name,
            description: topic.description,
            productId: productId,
            type: topic.type,
            complexity: topic.complexity ?? 0,
          })
        );
        if (newLeadMagnet) {
          setLeadMagnetId(newLeadMagnet?.id);
          setIsAdded(true);
          onLeadMagnetAdd?.(topic);
        } else {
          toast.error(t(tokens.general.formError));
        }
      } catch (error) {
        toast.error(t(tokens.general.formError));
      }
      setIsLoading(false);
    },

    [dispatch, onLeadMagnetAdd, productId, strategyId, t]
  );

  const removeLeadMagnet = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(topicThunks.deleteLeadMagnet(strategyId!, topicId!));
      setIsAdded(false);
      onLeadMagnetRemove?.(leadMagnet);
    } catch (error) {
      toast.error(t(tokens.general.formError));
    }
    setIsLoading(false);
    setLeadMagnetId(undefined);
  }, [dispatch, onLeadMagnetRemove, strategyId, t, leadMagnet, topicId]);

  const getComplexity = useCallback(
    (complexity: ComplexityType) => {
      switch (complexity) {
        case ComplexityType.Easy:
          return t(tokens.general.complexity.easy) as string;
        case ComplexityType.Medium:
          return t(tokens.general.complexity.medium) as string;
        case ComplexityType.Complicated:
          return t(tokens.general.complexity.complicated) as string;
        default:
          return "";
      }
    },
    [t]
  );

  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body1"
              color="primary"
            >
              {leadMagnet.type}
            </Typography>

            {leadMagnet.complexity !== undefined &&
              leadMagnet.complexity !== ComplexityType.Unknown && (
                <Box>
                  <Chip
                    label={getComplexity(leadMagnet.complexity)}
                    color="primary"
                    variant="outlined"
                  />
                </Box>
              )}
          </Stack>
          <Typography variant="h6">{leadMagnet.name}</Typography>
          <Typography>{leadMagnet.description}</Typography>

          <Box>
            <LoadingButton
              disabled={isLoading}
              loading={isLoading}
              onClick={async () => {
                if (!isAdded) {
                  addLeadMagnet(leadMagnet);
                } else {
                  removeLeadMagnet();
                }
              }}
              color={isAdded ? "error" : "primary"}
              variant="outlined"
              size="small"
            >
              {!isAdded
                ? t(tokens.leadMagnets.generator.buttons.addLeadMagnet)
                : t(tokens.leadMagnets.generator.buttons.removeLeadMagnet)}
            </LoadingButton>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
