import type { FC } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { TargetAudience } from "src/types/target-audience";
import { SectionTitle } from "../../common/section-title";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";

interface TargetAudienceDetailsProps {
  targetAudience: TargetAudience;
  onEdit?: () => void;
}

export const TargetAudienceDetails: FC<TargetAudienceDetailsProps> = (
  props
) => {
  const { targetAudience } = props;
  const { t } = useTranslation();

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography variant="h4">{targetAudience.name}</Typography>
            <IconButton
              onClick={() => {
                props.onEdit && props.onEdit();
              }}
            >
              <EditIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{ mb: 2 }}
            direction="row"
            spacing={1}
            alignContent={"center"}
          >
            <Typography variant="body1">
              {targetAudience.description}
            </Typography>
          </Stack>
          {/* {targetAudience.products.length > 0 && (
            <Stack
              sx={{ mb: 2 }}
              direction="row"
              spacing={1}
              alignContent={"center"}
            >
              💎{" "}
              <Typography variant="body1">
                {targetAudience.products.map((x) => x.name).join(", ")}
              </Typography>
            </Stack>
          )} */}
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Stack
            spacing={2}
            direction={{
              xs: "column",
              sm: "row",
            }}
            textAlign={{
              xs: "center",
              sm: "left",
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Avatar
              sx={{ width: 128, height: 128 }}
              src={targetAudience.imageUrl ?? ""}
            />
            <Box
              sx={{
                textAlign: "left",
              }}
            >
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: targetAudience.idealCustomer,
                }}
              ></Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <SectionTitle
            emoji="🎯"
            text={t(tokens.targetAudiences.details.sections.goals)}
          />
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: targetAudience.goals,
            }}
          ></Typography>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <SectionTitle
            emoji="☹️"
            text={t(tokens.targetAudiences.details.sections.pains)}
          />
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: targetAudience.content,
            }}
          ></Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};
