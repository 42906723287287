import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as contentTypesSlice } from "src/slices/content-types";

const getContentTypes =
  (strategyId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(contentTypesSlice.actions.setLoadingStatus(true));
    dispatch(contentTypesSlice.actions.setErrorStatus(false));
    try {
      var contentTypes = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/content-types`,
        {}
      );
      dispatch(contentTypesSlice.actions.getContentTypes(contentTypes));
    } catch (error) {
      dispatch(contentTypesSlice.actions.setErrorStatus(true));
    }
    dispatch(contentTypesSlice.actions.setLoadingStatus(false));
  };

const updateContentTypes =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var items = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/content-types`,
      value
    );
    dispatch(contentTypesSlice.actions.getContentTypes(items));
    return items;
  };

export const thunks = {
  getContentTypes,
  updateContentTypes,
};
