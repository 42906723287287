"use client";

import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { Moon as MoonIcon } from "@phosphor-icons/react/dist/ssr/Moon";
import { Sun as SunIcon } from "@phosphor-icons/react/dist/ssr/Sun";

import { Option } from "./option";
import { ColorScheme } from "src/styles/theme/types";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";

export interface OptionsColorSchemeProps {
  onChange?: (value: ColorScheme) => void;
  value?: ColorScheme;
}

export function OptionsColorScheme({
  onChange,
  value,
}: OptionsColorSchemeProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <InputLabel>{t(tokens.account.colorScheme.scheme) as string}</InputLabel>
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", flexWrap: "wrap" }}
      >
        {(
          [
            {
              label: t(tokens.account.colorScheme.light),
              value: "light",
              icon: <SunIcon />,
            },
            {
              label: t(tokens.account.colorScheme.dark),
              value: "dark",
              icon: <MoonIcon />,
            },
          ] satisfies { label: string; value: string; icon: React.ReactNode }[]
        ).map((option) => (
          <Option
            icon={option.icon}
            key={option.value}
            label={option.label}
            onClick={() => {
              onChange?.(option.value as ColorScheme);
            }}
            selected={option.value === value}
          />
        ))}
      </Stack>
    </Stack>
  );
}
