import type { FC } from 'react';
import './styles/global.css';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SplashScreen } from './components/splash-screen';
import { Toaster } from './components/toaster';
import { SettingsProvider } from './contexts/settings';
import { AuthConsumer, AuthProvider } from './contexts/auth/jwt-context';
import { clarityConfig, gtmConfig, mixpanelConfig } from './config';
import { useAnalytics } from './hooks/use-analytics';
import { routes } from './routes';
import { store } from './store';
import ServerErrorPage from './pages/500';


// Remove if react-quill is not used
// import 'react-quill/dist/quill.snow.css';
// Remove if react-draft-wysiwyg is not used
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// Remove if simplebar is not used
import 'simplebar-react/dist/simplebar.min.css';
// Remove if mapbox is not used
// import 'mapbox-gl/dist/mapbox-gl.css';
// Remove if locales are not used
import './locales/i18n';
import toast from 'react-hot-toast';
import { useServiceWorker } from 'src/hooks/use-service-worker';
import { useTranslation } from 'react-i18next';
import { tokens } from './locales/tokens';
import { Language } from './language';
import { Button, Stack, Typography } from '@mui/material';
import { useClarity } from './hooks/use-clarity';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/uk';
import 'moment/locale/en-gb';
import { pl, uk, enUS } from 'date-fns/locale';
import { SignalRAppContext } from './contexts/signalr-context';
import { useMixPanel } from './hooks/use-mixpanel';
import { getSettings as getPersistedSettings } from './lib/settings/get-settings';
import { applyDefaultSettings } from './lib/settings/apply-default-settings';
import { ThemeProvider } from './components/core/theme-provider/theme-provider';
import { UserProvider } from './contexts/auth/user-context';

export const App: FC = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const { t, i18n } = useTranslation();
  const settings = React.useRef(applyDefaultSettings(getPersistedSettings()));

  moment.locale(i18n.language);

  useAnalytics(gtmConfig);
  useClarity(clarityConfig);
  useMixPanel(mixpanelConfig);
  // useNprogress();
  useEffect(() => {
    if (showReload && waitingWorker) {
      toast.success(
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Typography>{t(tokens.general.newAppVersion.description)}</Typography>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={reloadPage}
          >
            {t(tokens.general.newAppVersion.action)}
          </Button>
        </Stack>,
        {
          id: 'newVersionAvailable',
          duration: Infinity
        });
    }
  }, [waitingWorker, showReload, reloadPage, t]);

  const element = useRoutes(routes);

  const calendarLang = () => {
    switch (i18n.language as Language) {
      case 'en':
        return enUS;
      case 'uk':
        return uk;
      // case 'pl':
      //   return pl;
      default:
        return uk;
    }
  };

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}
        adapterLocale={calendarLang()}>
        <UserProvider>
          <AuthProvider>
            <AuthConsumer>
              {(auth) => {
                const showSlashScreen = !auth.isInitialized;
                const hasError = auth.hasError;
                return (
                  <SettingsProvider settings={settings.current}>

                    <ThemeProvider>
                      <SignalRAppContext>
                        <CssBaseline />
                        {
                          hasError ?
                            <>
                              <ServerErrorPage />
                            </> :
                            showSlashScreen
                              ? <SplashScreen />
                              : (
                                <>
                                  {element}
                                </>
                              )
                        }
                        <Toaster />

                      </SignalRAppContext>
                    </ThemeProvider>
                  </SettingsProvider>
                )
              }}
            </AuthConsumer>
          </AuthProvider>
        </UserProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
};
