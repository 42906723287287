import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { LeadMagnetResponse } from "src/types/lead-magnet";

interface LeadMagnetsState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: LeadMagnetResponse;
}

type GetLeadMagnetsAction = PayloadAction<LeadMagnetResponse>;
type LeadMagnetsLoadingStatusAction = PayloadAction<boolean>;
type LeadMagnetsErrorStatusAction = PayloadAction<boolean>;

const initialState: LeadMagnetsState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null,
  },
};

const reducers = {
  setLoadingStatus: (
    state: LeadMagnetsState,
    action: LeadMagnetsLoadingStatusAction
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: LeadMagnetsState,
    action: LeadMagnetsErrorStatusAction
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getLeadMagnets: (state: LeadMagnetsState, action: GetLeadMagnetsAction): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: "leadMagnets",
  initialState,
  reducers,
});

export const { reducer } = slice;
