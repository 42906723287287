import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as productsSlice } from "src/slices/products";
import { GetProductsQueryParams } from "src/types/product";
import { urlParams } from "src/utils/url-params";
import { CancelTokenSource } from "axios";

const getProducts =
  (strategyId: string, params: GetProductsQueryParams): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productsSlice.actions.setLoadingStatus(true));
    dispatch(productsSlice.actions.setErrorStatus(false));
    try {
      var products = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/products${urlParams(params)}`,
        {}
      );
      dispatch(productsSlice.actions.getProducts(products));
    } catch (error) {
      dispatch(productsSlice.actions.setErrorStatus(true));
    }
    dispatch(productsSlice.actions.setLoadingStatus(false));
  };

const generateLandingPage =
  (
    strategyId: string,
    lang: string,
    data: any,
    onProgress: (text: string) => void,
    onError: () => void,
    onFinish: () => void,
    cancelToken?: CancelTokenSource
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      return await fetchWrapper
        .post(
          `${api}/strategies/${strategyId}/products/generate-landing-page`,
          data,
          cancelToken,
          lang,
          "stream",
          (progressEvent: any) => {
            try {
              var dataChunk = progressEvent.event.currentTarget.response;
              dataChunk = dataChunk.replace("```json", "");
              dataChunk = dataChunk.replace("json", "");
              dataChunk = dataChunk.replace("```", "");
              dataChunk = dataChunk.replace("```", "");

              if (!dataChunk.endsWith("]")) {
                dataChunk += "]";
              }

              var json = JSON.parse(dataChunk) as [];
              var str = "";
              for (let i = 0; i < json.length; i++) {
                str += json[i] as any;
              }

              onProgress(str);
            } catch (e) {
              console.log(e);
            }
          }
        )
        .then(() => {
          onFinish?.();
        })
        .catch(() => {
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const thunks = {
  getProducts,
  generateLandingPage,
};
