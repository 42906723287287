import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { map } from "lodash";
import toast from "react-hot-toast";
import { GeneratedTopic } from "src/types/topic";
import { thunks as topicThunks } from "src/thunks/topic";
import { thunks as promptsThunks } from "src/thunks/prompts";
import { thunks as productsThunks } from "src/thunks/products";
import { thunks as storylinesThunks } from "src/thunks/storylines";
//import { thunks as targetAudiencesThunks } from "src/thunks/target-audiences";
import { Product } from "src/types/product";
import { Storyline, StorylineCategory } from "src/types/storyline";
import { TargetAudience } from "src/types/target-audience";
import axios, { CancelTokenSource } from "axios";
import { ContentGoalType } from "src/types/content-goal-type";
import { PromptMessage, PromptMessageUser } from "src/types/prompts";
import { parse } from "partial-json";

interface Values {
  name: string;
  instruction: string;
  product: Product | null;
  storyline: Storyline | null;
  category: StorylineCategory | null;
  targetAudience: TargetAudience | null;
  status: number;
  submit: null;
}

const initialValues: Values = {
  name: "",
  instruction: "",
  status: 0,
  product: null,
  storyline: null,
  category: null,
  targetAudience: null,
  submit: null,
};

interface TopicGenerationFormProps {
  showVerticalFilters?: boolean;
  onTopicAdd?: (topic: GeneratedTopic) => void;
  onTopicRemove?: (topic: GeneratedTopic) => void;
  onSelect?: (topic: GeneratedTopic) => void;
}

export const TopicGenerationForm: FC<TopicGenerationFormProps> = (props) => {
  const {
    onTopicAdd,
    onTopicRemove,
    onSelect,
    showVerticalFilters = false,
  } = props;
  const { t, i18n } = useTranslation();
  const { strategyId } = useSelector((state) => state.settings);
  const { item: strategy } = useSelector((state) => state.strategy);
  const { isLoading: isProductsLoading, items: products } = useSelector(
    (state) => state.products
  );
  const { isLoading: isStorylinesLoading, items: storylines } = useSelector(
    (state) => state.storylines
  );

  const validationSchema = Yup.object({
    storyline: Yup.object().required(
      t(tokens.general.validators.required) as string
    ),
  });
  const [topics, setTopics] = useState<GeneratedTopic[]>([]);
  const dispatch = useDispatch();

  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(
    axios.CancelToken.source()
  );

  // create a cancel token instance
  //const cancelTokenSource = CancelToken.source();

  const [productInputValue, setProductInputValue] = useState("");
  const [storylineInputValue, setStorylineInputValue] = useState("");
  //const [targetAudienceInputValue, setTargetAudienceInputValue] = useState("");

  // const fetchTargetAudiences = useMemo(
  //   () =>
  //     debounce(async (request: string) => {
  //       await dispatch(
  //         targetAudiencesThunks.getTargetAudiences(strategyId!, {
  //           top: 25,
  //           page: 1,
  //           text: request,
  //         })
  //       );
  //     }, 400),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [strategyId]
  // );

  const fetchProducts = useMemo(
    () =>
      debounce(async (request: string) => {
        await dispatch(
          productsThunks.getProducts(strategyId!, {
            top: 25,
            page: 1,
            text: request,
          })
        );
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [strategyId]
  );

  const fetchStorylines = useMemo(
    () =>
      debounce(async (request: string) => {
        await dispatch(
          storylinesThunks.getStorylines(strategyId!, {
            top: 25,
            page: 1,
            text: request,
          })
        );
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [strategyId]
  );

  useEffect(() => {
    let active = true;

    if (active) {
      fetchProducts(productInputValue);
    }

    return () => {
      active = false;
    };
  }, [fetchProducts, productInputValue]);

  // useEffect(() => {
  //   let active = true;

  //   if (active) {
  //     fetchTargetAudiences(targetAudienceInputValue);
  //   }

  //   return () => {
  //     active = false;
  //   };
  // }, [fetchTargetAudiences, targetAudienceInputValue]);

  useEffect(() => {
    let active = true;

    if (active) {
      fetchStorylines(storylineInputValue);
    }

    return () => {
      active = false;
    };
  }, [fetchStorylines, storylineInputValue]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      setTopics([]);

      var messages = [] as PromptMessage[];

      messages.push({
        user: PromptMessageUser.System,
        content: `You are an expert in building personal brands. 
Generate 10 topics that I can write about to attract followers based on the storyline and other inputs.
Each topic must contain:
- Name (10 words)
- Description (30-40 words)

Provide response in the JSON format:
[{
"name": "",
"description": ""
}]        
`,
      });

      if (formik.values.storyline) {
        var storylineContent = `Storyline Name:\n ${formik.values.storyline.name}\n\n`;
        storylineContent += `Storyline Description:\n ${formik.values.storyline.description}`;
        messages.push({
          user: PromptMessageUser.User,
          content: storylineContent,
        });

        if (
          formik.values.storyline.contentGoalType ===
            ContentGoalType.Engagement &&
          strategy?.expertContent
        ) {
          var expertContent = `My Expertise: ${strategy?.expertContent}\n\n`;
          messages.push({
            user: PromptMessageUser.User,
            content: expertContent,
          });
        }
      }

      if (formik.values.category) {
        var categoryContent = `Storyline Category Name:\n ${formik.values.category.name}\n\n`;
        categoryContent += `Storyline Category Description:\n ${formik.values.category.description}\n\n`;
        messages.push({
          user: PromptMessageUser.User,
          content: categoryContent,
        });
      }

      if (formik.values.product) {
        var productContent = `Product Name:\n ${formik.values.product.name}\n\n`;
        productContent += `Product Description:\n ${formik.values.product.description}\n\n`;
        messages.push({
          user: PromptMessageUser.User,
          content: productContent,
        });
      }

      if (formik.values.instruction) {
        messages.push({
          user: PromptMessageUser.User,
          content: `${formik.values.instruction}`,
        });
      }

      await dispatch(
        promptsThunks.runChatPrompt(
          strategyId!,
          i18n.language,
          {
            messages: messages,
          },
          (text) => {
            var output = parse(text);
            const topics = output as GeneratedTopic[];
            setTopics(topics);
          },
          () => {
            toast.error(t(tokens.general.formError));
          },
          (text) => {
            var output = parse(text);
            const topics = output as GeneratedTopic[];
            setTopics(topics);
          },
          cancelTokenSource
        )
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Stack
                spacing={2}
                justifyContent={"space-between"}
                direction={{
                  xs: "column",
                  sm: showVerticalFilters ? "column" : "row",
                }}
              >
                <Autocomplete
                  getOptionLabel={(option: Storyline) => `${option.name}`}
                  options={storylines.items}
                  fullWidth
                  value={formik.values.storyline}
                  loading={isStorylinesLoading}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  onChange={(event: any, newValue: Storyline | null) => {
                    formik.setFieldValue("storyline", newValue);
                    formik.setFieldValue("category", null);
                    formik.setFieldValue("product", null);
                    setStorylineInputValue("");
                  }}
                  onInputChange={(event, newInputValue) => {
                    setStorylineInputValue(newInputValue);
                  }}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      error={
                        !!(formik.touched.storyline && formik.errors.storyline)
                      }
                      helperText={
                        formik.touched.storyline && formik.errors.storyline
                      }
                      size="small"
                      fullWidth
                      label={t(tokens.topics.generator.form.storyline)}
                      name="storyline"
                    />
                  )}
                />
                <Autocomplete
                  getOptionLabel={(option: StorylineCategory) =>
                    `${option.name}`
                  }
                  options={formik.values.storyline?.categories ?? []}
                  fullWidth
                  filterSelectedOptions
                  value={formik.values.category}
                  loading={isStorylinesLoading}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(
                    event: any,
                    newValue: StorylineCategory | null
                  ) => {
                    formik.setFieldValue("category", newValue);
                  }}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label={t(tokens.topics.generator.form.category)}
                      name="category"
                    />
                  )}
                />
                <Autocomplete
                  getOptionLabel={(option: Product) => `${option.name}`}
                  options={products.items}
                  disabled={
                    formik.values.storyline?.contentGoalType !==
                    ContentGoalType.Selling
                  }
                  fullWidth
                  value={formik.values.product}
                  loading={isProductsLoading}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  onChange={(event: any, newValue: Product | null) => {
                    formik.setFieldValue("product", newValue);
                    setProductInputValue("");
                  }}
                  onInputChange={(event, newInputValue) => {
                    setProductInputValue(newInputValue);
                  }}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label={t(tokens.topics.generator.form.product)}
                      name="product"
                    />
                  )}
                />

                {/* <Autocomplete
                  getOptionLabel={(option: TargetAudience) => `${option.name}`}
                  options={targetAudiences.items}
                  fullWidth
                  disableClearable
                  value={formik.values.targetAudience}
                  loading={isTargetAudiencesLoading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event: any, newValue: TargetAudience | null) => {
                    formik.setFieldValue("targetAudience", newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setTargetAudienceInputValue(newInputValue);
                  }}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label="Target Audience"
                      name="targetAudience"
                    />
                  )}
                /> */}
              </Stack>

              <TextField
                error={
                  !!(formik.touched.instruction && formik.errors.instruction)
                }
                fullWidth
                multiline
                minRows={2}
                helperText={
                  formik.touched.instruction && formik.errors.instruction
                }
                label={t(tokens.topics.generator.form.instruction)}
                name="instruction"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.instruction}
              />
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-start"
                spacing={1}
              >
                <LoadingButton
                  type="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.topics.generator.buttons.generateTopics)}
                </LoadingButton>

                {formik.isSubmitting && (
                  <LoadingButton
                    type="button"
                    onClick={() => {
                      cancelTokenSource.cancel();
                      setCancelTokenSource(axios.CancelToken.source());
                    }}
                    variant="outlined"
                  >
                    {t(tokens.general.buttons.cancel)}
                  </LoadingButton>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        {map(topics, (topic, index) => (
          <GeneratedTopicItem
            key={index}
            productId={formik.values.product?.id}
            storylineId={formik.values.storyline?.id}
            contentGoalType={formik.values.storyline?.contentGoalType}
            topic={topic}
            onSelect={onSelect}
            onTopicAdd={onTopicAdd}
            onTopicRemove={onTopicRemove}
          />
        ))}
      </Stack>
    </form>
  );
};

interface GeneratedTopicItemProps {
  topic: GeneratedTopic;
  storylineId?: string;
  contentGoalType?: ContentGoalType;
  productId?: string;
  onTopicAdd?: (topic: GeneratedTopic) => void;
  onTopicRemove?: (topic: GeneratedTopic) => void;
  onSelect?: (topic: GeneratedTopic) => void;
}

export const GeneratedTopicItem: FC<GeneratedTopicItemProps> = (props) => {
  const { t } = useTranslation();
  const {
    topic,
    storylineId,
    productId,
    contentGoalType = ContentGoalType.Engagement,
    onTopicAdd,
    onTopicRemove,
    onSelect,
  } = props;
  const { strategyId } = useSelector((state) => state.settings);
  const [isAdded, setIsAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [topicId, setTopicId] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const addTopic = useCallback(
    async (topic: GeneratedTopic) => {
      setIsLoading(true);
      try {
        const newTopic = await dispatch(
          topicThunks.createTopic(strategyId!, {
            name: topic.name,
            description: topic.description,
            contentGoalType: contentGoalType,
            storyLineId: storylineId,
            productId: productId,
          })
        );
        setTopicId(newTopic?.id);
        setIsAdded(true);
        onTopicAdd?.(topic);
      } catch (error) {
        toast.error(t(tokens.general.formError));
      }
      setIsLoading(false);
    },

    [
      contentGoalType,
      dispatch,
      onTopicAdd,
      productId,
      storylineId,
      strategyId,
      t,
    ]
  );

  const removeTopic = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(topicThunks.deleteTopic(strategyId!, topicId!));
      setIsAdded(false);
      onTopicRemove?.(topic);
    } catch (error) {
      toast.error(t(tokens.general.formError));
    }
    setIsLoading(false);
    setTopicId(undefined);
  }, [dispatch, onTopicRemove, strategyId, t, topic, topicId]);

  const cardContent = (
    <CardContent>
      <Stack spacing={1}>
        <Typography variant="h6">{topic.name}</Typography>
        <Typography>{topic.description}</Typography>
        {(onTopicAdd || onTopicRemove) && (
          <Box>
            <LoadingButton
              disabled={isLoading}
              loading={isLoading}
              onClick={async () => {
                if (!isAdded) {
                  addTopic(topic);
                } else {
                  removeTopic();
                }
              }}
              color={isAdded ? "error" : "primary"}
              variant="outlined"
              size="small"
            >
              {!isAdded
                ? t(tokens.topics.generator.buttons.addTopic)
                : t(tokens.topics.generator.buttons.removeTopic)}
            </LoadingButton>
          </Box>
        )}
      </Stack>
    </CardContent>
  );

  return (
    <Card>
      {onSelect ? (
        <CardActionArea
          onClick={() => {
            onSelect?.(topic);
          }}
        >
          {cardContent}
        </CardActionArea>
      ) : (
        cardContent
      )}
    </Card>
  );
};
