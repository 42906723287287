import React, { type FC, useCallback, useEffect } from "react";
import { Card, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { thunks as strategyThunks } from "src/thunks/strategy-wizard";
import { useDispatch, useSelector } from "src/store";
import { Container } from "@mui/system";
import { WizardHeader } from "./wizard-header";
import { WizardAIOptions } from "./wizard-ai-options";
import { toast } from "react-hot-toast";
import { WizardSections } from "./wizard-sections";
import { tokens } from "src/locales/tokens";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";
import { useParams } from "react-router";

export const WizardStep: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const user = useAuth();

  const { strategyId } = useParams();
  const steps = useSelector((state) => state.strategyWizard.steps);
  const wizardStep = useSelector((state) => state.strategyWizard.wizardStep);
  const isFinished = useSelector((state) => state.strategyWizard.isFinished);
  const currentStep = steps[wizardStep];

  const router = useRouter();

  const navigateToPrevStep = useCallback(async () => {
    await dispatch(strategyThunks.navigateToPrevStep());
    if (wizardStep > 0 && !isFinished) {
      router.back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, router, wizardStep]);

  const navigateToNextStep = useCallback(async () => {
    await dispatch(strategyThunks.navigateToNextStep());
    if (wizardStep < steps.length - 1) {
      router.push(
        createRoute(paths.wizard.step).link({
          step: wizardStep + 1,
          strategyId: strategyId,
        })
      );
    } else {
      if (user.isAuthenticated) {
        router.push(
          createRoute(paths.wizard.finish).link({ strategyId: strategyId })
        );
      } else {
        router.push(
          createRoute(paths.wizard.signup).link({ strategyId: strategyId })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, wizardStep]);

  const saveChanges = useCallback(
    async (value: any) => {
      await dispatch(
        strategyThunks.saveStrategySection(
          strategyId!,
          currentStep.strategyStep.configuration.sectionKey!,
          value
        )
      );
      await navigateToNextStep();
      //await dispatch(strategyThunks.navigateToNextStep());
    },
    [
      currentStep.strategyStep.configuration.sectionKey,
      dispatch,
      navigateToNextStep,
      strategyId,
    ]
  );

  // useEffect(() => {
  //   localStorage.setItem(CONSTS.WIZARD_STEP_KEY, sectionIndex.toString());
  // }, [sectionIndex]);

  useEffect(() => {
    async function generateIdeas() {
      if (
        currentStep.strategyStep.configuration.hasInstruction &&
        ((!currentStep.strategyStep.instructionResult?.options &&
          !currentStep.strategyStep.instructionResult?.isOptionsLoading) ||
          currentStep.strategyStep.instructionResult?.isOptionsError)
      ) {
        await dispatch(
          strategyThunks.generateOptions(
            strategyId!,
            currentStep.strategyStep.configuration.sectionKey!,
            i18n.language
          )
        );
      }
    }
    generateIdeas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.strategyStep.configuration.sectionKey, i18n.language]);

  useEffect(() => {
    if (currentStep.hasSavingError) {
      toast.error(t(tokens.general.formError));
    }
  }, [currentStep.hasSavingError, t]);

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: {
          xs: 2,
          sm: 4,
        },
        mb: 4,
      }}
    >
      <Card
        sx={{
          p: 4,
          mb: 2,
        }}
      >
        <Stack
          direction={"column"}
          spacing={3}
        >
          <WizardHeader
            pageIndex={isFinished ? steps.length : wizardStep}
            totalPages={steps.length + 1}
            onBack={navigateToPrevStep}
          />

          <WizardSections
            onNext={navigateToNextStep}
            saveChanges={saveChanges}
          />
        </Stack>
      </Card>

      <WizardAIOptions />
    </Container>
  );
};
