import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { reducer as variousReducer } from "src/slices/various";
import { reducer as settingsReducer } from "src/slices/settings";
import { reducer as strategyReducer } from "src/slices/strategy";
import { reducer as strategyWizardReducer } from "src/slices/strategy-wizard";
import { reducer as strategiesReducer } from "src/slices/strategies";
import { reducer as productsReducer } from "src/slices/products";
import { reducer as productReducer } from "src/slices/product";
import { reducer as topicsReducer } from "src/slices/topics";
import { reducer as topicReducer } from "src/slices/topic";
import { reducer as leadMagnetsReducer } from "src/slices/lead-magnets";
import { reducer as leadMagnetReducer } from "src/slices/lead-magnet";
import { reducer as storylinesReducer } from "src/slices/storylines";
import { reducer as storylineReducer } from "src/slices/storyline";
import { reducer as storylineCategoryReducer } from "src/slices/storyline-category";
import { reducer as contentTemplatesReducer } from "src/slices/content-templates";
import { reducer as contentTemplateReducer } from "src/slices/content-template";
import { reducer as targetAudiencesReducer } from "src/slices/target-audiences";
import { reducer as targetAudienceReducer } from "src/slices/target-audience";
import { reducer as contentPlaceholderReducer } from "src/slices/content-placeholder";
import { reducer as contentPlaceholdersReducer } from "src/slices/content-placeholders";
import { reducer as contentTypesReducer } from "src/slices/content-types";
import { reducer as socialProfileReducer } from "src/slices/social-profile";
import { reducer as domainReducer } from "src/slices/domain";

const appReducer = combineReducers({
  settings: settingsReducer,
  various: variousReducer,
  strategy: strategyReducer,
  strategyWizard: strategyWizardReducer,
  strategies: strategiesReducer,
  products: productsReducer,
  product: productReducer,
  topics: topicsReducer,
  topic: topicReducer,
  leadMagnets: leadMagnetsReducer,
  leadMagnet: leadMagnetReducer,
  storylines: storylinesReducer,
  storyline: storylineReducer,
  storylineCategory: storylineCategoryReducer,
  contentTemplates: contentTemplatesReducer,
  contentTemplate: contentTemplateReducer,
  targetAudiences: targetAudiencesReducer,
  targetAudience: targetAudienceReducer,
  contentPlaceholder: contentPlaceholderReducer,
  contentPlaceholders: contentPlaceholdersReducer,
  domain: domainReducer,
  wizard: strategyWizardReducer,
  contentTypes: contentTypesReducer,
  socialProfile: socialProfileReducer,
});

export const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "DESTROY_SESSION") state = undefined;

  return appReducer(state, action);
};
