import { useTranslation } from 'react-i18next';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { tokens } from 'src/locales/tokens';
import ServerError from 'src/sections/errors/server-error';
import type { Page as PageType } from 'src/types/page';

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  return (
    <>
      <Seo title={t(tokens.errors.serverError.title) as string} />
      <ServerError
        showBackButton={true} />
    </>
  );
};

export default Page;

