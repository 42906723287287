import { type FC } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { ErrorMessage } from "src/sections/common/error-message";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";

interface GenerateAgainProps {
  error: string;
  isGenerating: boolean;
  createPart: () => void;
}

export const GenerateAgain: FC<GenerateAgainProps> = (props) => {
  const { error, isGenerating, createPart } = props;
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <CardContent>
          <ErrorMessage text={error} />
          <Box
            sx={{
              mt: 2,
            }}
          >
            <LoadingButton
              onClick={createPart}
              size="large"
              loading={isGenerating}
              variant="contained"
              sx={{
                minWidth: 200,
                height: 56,
              }}
            >
              {t(tokens.general.buttons.generateAgain)}
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
