import type { Icon } from "@phosphor-icons/react/dist/lib/types";
import { AddressBook as AddressBookIcon } from "@phosphor-icons/react/dist/ssr/AddressBook";
import { AlignLeft as AlignLeftIcon } from "@phosphor-icons/react/dist/ssr/AlignLeft";
import { CalendarCheck as CalendarCheckIcon } from "@phosphor-icons/react/dist/ssr/CalendarCheck";
import { ChartPie as ChartPieIcon } from "@phosphor-icons/react/dist/ssr/ChartPie";
import { ChatsCircle as ChatsCircleIcon } from "@phosphor-icons/react/dist/ssr/ChatsCircle";
import { CreditCard as CreditCardIcon } from "@phosphor-icons/react/dist/ssr/CreditCard";
import { Cube as CubeIcon } from "@phosphor-icons/react/dist/ssr/Cube";
import { CurrencyEth as CurrencyEthIcon } from "@phosphor-icons/react/dist/ssr/CurrencyEth";
import { EnvelopeSimple as EnvelopeSimpleIcon } from "@phosphor-icons/react/dist/ssr/EnvelopeSimple";
import { File as FileIcon } from "@phosphor-icons/react/dist/ssr/File";
import { FileDashed as FileDashedIcon } from "@phosphor-icons/react/dist/ssr/FileDashed";
import { FileX as FileXIcon } from "@phosphor-icons/react/dist/ssr/FileX";
import { Gear as GearIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { GraduationCap as GraduationCapIcon } from "@phosphor-icons/react/dist/ssr/GraduationCap";
import { House as HouseIcon } from "@phosphor-icons/react/dist/ssr/House";
import { Kanban as KanbanIcon } from "@phosphor-icons/react/dist/ssr/Kanban";
import { Link as LinkIcon } from "@phosphor-icons/react/dist/ssr/Link";
import { Lock as LockIcon } from "@phosphor-icons/react/dist/ssr/Lock";
import { ReadCvLogo as ReadCvLogoIcon } from "@phosphor-icons/react/dist/ssr/ReadCvLogo";
import { Receipt as ReceiptIcon } from "@phosphor-icons/react/dist/ssr/Receipt";
import { ShareNetwork as ShareNetworkIcon } from "@phosphor-icons/react/dist/ssr/ShareNetwork";
import { ShoppingBagOpen as ShoppingBagOpenIcon } from "@phosphor-icons/react/dist/ssr/ShoppingBagOpen";
import { ShoppingCartSimple as ShoppingCartSimpleIcon } from "@phosphor-icons/react/dist/ssr/ShoppingCartSimple";
import { SignOut as SignOutIcon } from "@phosphor-icons/react/dist/ssr/SignOut";
import { TextAlignLeft as TextAlignLeftIcon } from "@phosphor-icons/react/dist/ssr/TextAlignLeft";
import { Truck as TruckIcon } from "@phosphor-icons/react/dist/ssr/Truck";
import { Upload as UploadIcon } from "@phosphor-icons/react/dist/ssr/Upload";
import { Users as UsersIcon } from "@phosphor-icons/react/dist/ssr/Users";
import { WarningDiamond as WarningDiamondIcon } from "@phosphor-icons/react/dist/ssr/WarningDiamond";
import { Calendar as CalendarIcon } from "@phosphor-icons/react/dist/ssr/Calendar";
import { InstagramLogo } from "@phosphor-icons/react/dist/ssr/InstagramLogo";
import { Lightbulb } from "@phosphor-icons/react/dist/ssr/Lightbulb";
import { Jar as JarIcon } from "@phosphor-icons/react/dist/ssr/Jar";
import { BookOpenText } from "@phosphor-icons/react/dist/ssr/BookOpenText";
import { User as UserIcon } from "@phosphor-icons/react/dist/ssr/User";
import { HandCoins } from "@phosphor-icons/react/dist/ssr/HandCoins";
import { UsersThree } from "@phosphor-icons/react/dist/ssr/UsersThree";
import { SmileyNervous } from "@phosphor-icons/react/dist/ssr/SmileyNervous";
import { Gear as SettingsIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { Magnet as MagnetIcon } from "@phosphor-icons/react/dist/ssr/Magnet"; 
import { Copy as CopyIcon } from "@phosphor-icons/react/dist/ssr/Copy"; 


export const icons = {
  "address-book": AddressBookIcon,
  "align-left": AlignLeftIcon,
  "calendar-check": CalendarCheckIcon,
  "chart-pie": ChartPieIcon,
  "chats-circle": ChatsCircleIcon,
  "credit-card": CreditCardIcon,
  "currency-eth": CurrencyEthIcon,
  "envelope-simple": EnvelopeSimpleIcon,
  "file-dashed": FileDashedIcon,
  "file-x": FileXIcon,
  "graduation-cap": GraduationCapIcon,
  "read-cv-logo": ReadCvLogoIcon,
  "share-network": ShareNetworkIcon,
  "shopping-bag-open": ShoppingBagOpenIcon,
  "shopping-cart-simple": ShoppingCartSimpleIcon,
  "sign-out": SignOutIcon,
  "text-align-left": TextAlignLeftIcon,
  "warning-diamond": WarningDiamondIcon,
  instagram: InstagramLogo,
  "book-open-text": BookOpenText,
  "target-audience": UsersThree,
  settings: SettingsIcon,
  fear: SmileyNervous,
  value: HandCoins,
  user: UserIcon,
  lightbulb: Lightbulb,
  jar: JarIcon,
  calendar: CalendarIcon,
  cube: CubeIcon,
  file: FileIcon,
  house: HouseIcon,
  kanban: KanbanIcon,
  link: LinkIcon,
  lock: LockIcon,
  receipt: ReceiptIcon,
  truck: TruckIcon,
  upload: UploadIcon,
  gear: GearIcon,
  users: UsersIcon,
  magnet: MagnetIcon,
  copy: CopyIcon,
} as Record<string, Icon>;
