import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultError } from "src/types/error";
import { TargetAudience } from "src/types/target-audience";

interface TargetAudienceState {
  isLoading: boolean;
  error: ResultError | undefined;

  isSaving: boolean;
  hasSavingError: boolean;
  item: TargetAudience | undefined;
}

type LoadTargetAudienceAction = PayloadAction<TargetAudience | undefined>;
type TargetAudienceLoadingAction = PayloadAction<boolean>;
type TargetAudienceLoadingErrorAction = PayloadAction<ResultError | undefined>;
type TargetAudienceSavingAction = PayloadAction<boolean>;
type TargetAudienceSavingErrorAction = PayloadAction<boolean>;

const initialState: TargetAudienceState = {
  isLoading: false,
  error: undefined,
  isSaving: false,
  hasSavingError: false,
  item: undefined,
};

const reducers = {
  setLoadingStatus: (
    state: TargetAudienceState,
    action: TargetAudienceLoadingAction
  ): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: TargetAudienceState,
    action: TargetAudienceLoadingErrorAction
  ): void => {
    state.error = action.payload;
  },
  setTargetAudience: (
    state: TargetAudienceState,
    action: LoadTargetAudienceAction
  ): void => {
    state.item = action.payload;
  },
  setSavingStatus: (
    state: TargetAudienceState,
    action: TargetAudienceSavingAction
  ): void => {
    state.isSaving = action.payload;
  },
  setSavingErrorStatus: (
    state: TargetAudienceState,
    action: TargetAudienceSavingErrorAction
  ): void => {
    state.hasSavingError = action.payload;
  },
};

export const slice = createSlice({
  name: "targetAudience",
  initialState,
  reducers,
});

export const { reducer } = slice;
