import { useEffect, useMemo } from "react";
import { Seo } from "src/components/seo";
import { SECTION_KEYS } from "src/consts";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { WizardStep } from "src/sections/wizard/wizard-step";
import { useDispatch, useSelector } from "src/store";
// import { HomeSetGoal } from 'src/sections/home/home-set-goal';
import type { Page as PageType } from "src/types/page";
import { thunks as strategyThunks } from "src/thunks/strategy-wizard";
import { thunks as settingsThunks } from "src/thunks/settings";
import { useTranslation } from "react-i18next";
import { thunks } from "src/thunks/strategy-wizard";
import { SectionFormType, StrategyStepConfiguration } from "src/types/strategy";
import { useParams } from "react-router";
import { createRoute } from "react-router-url-params";
import { tokens } from "src/locales/tokens";

export const useStrategySteps = (): StrategyStepConfiguration[] => {
  useTranslation();

  return useMemo(
    () => [
      {
        title: "Привіт 👋",
        description:
          "Мене звати Robo. Я допоможу тобі  зі створенням персональної контент-стратегії.",
        hasInstruction: false,
      },
      {
        title: "Давай знайомитись 😉",
        description:
          "Я задаватиму питання, щоб зібрати інформацію. На основі твоїх відповідей я згенерую стратегію, а ти побачиш можливості розвитку.",
        hasInstruction: false,
      },
      {
        title: "👀 Як тебе звати і скільки тобі років?",
        description: "",
        example: "Мене звати Іра. Мені 32 роки.",
        sectionKey: SECTION_KEYS.ABOUT_ME,
        hasInstruction: false,
        formType: SectionFormType.LongText,
      },

      // {
      //   title: "👀 Про що твій особистий бренд?",
      //   description:
      //     "Розкажи про себе і свій шлях (вік, сімейний статус, професія, досвід...) Словом, усе, що вважаєш важливим.",
      //   example:
      //     "Мене звати Іра. Мені 32 роки. За освітою я - журналіст, працювала в журналі і на телебаченні. 9 років тому відкрила власний бізнес - жіночий бренд одягу: від виробництва до продажу. Є авторкою книги. У зв'язку з війною емігрувала. Це вплинуло на зміну діяльності. Зараз розвиваю особистий блог у сфері мобільної відеозйомки та сторітелінгу.",
      //   sectionKey: SECTION_KEYS.ABOUT_BLOG,
      //   hasInstruction: false,
      //   formType: SectionFormType.LongText,
      // },

      {
        title: "🤔 Чому хочеш розвивати особистий бренд?",
        description:
          "Напиши, що тобі дасть розвиток особистого бренду. Які твої мотиви? Які можливості ти бачиш?",
        example:
          "Прагну працювати на себе незалежно від місця проживання. Мені важливо поєднати творчість, вільний графік, монетизацію і мій досвід.",
        sectionKey: SECTION_KEYS.REASONS,
        hasInstruction: false,
        formType: SectionFormType.LongText,
      },
      {
        title: "🤔 Про що твій особистий бренд?",
        description: "Напиши на основі чого ти хочеш розвивати свій бренд.",
        example: "",
        sectionKey: SECTION_KEYS.AREA,
        hasInstruction: false,
        formType: SectionFormType.LongText,
      },
      {
        title: "🤹 Які твої навики та досвід, що допоможе тобі в цій сфері?",
        description:
          "Кожний пункт - це один досвід або навик, що може бути тобі корисним. ",
        example:
          "Вмію генерувати ідеї, вмію писати сценарії, знімати відео, запускати продукти з нуля, навчати людей...",
        sectionKey: SECTION_KEYS.SKILLS,
        hasInstruction: false,
        formType: SectionFormType.List,
      },

      {
        title: "🧙 Який продукт чи послугу ти плануєш розвивати?",
        description:
          "Напиши своє бачення або ж подивись на мої варіанти нижче. Можеш скопіювати і відредагувати.",
        example:
          "Створити курс 'Від сценарію до відеозйомки', консультації та менторство зі сторітелінгу, зйомки для брендів, реклама.",
        sectionKey: SECTION_KEYS.PRODUCT_OR_SERVICE,
        hasInstruction: true,
        formType: SectionFormType.LongText,
      },
      // {
      //   title: "🎯 Які твої цілі на цей рік?",
      //   description:
      //     "Сформуй цілі, які ставиш перед контентом і особистим брендом. Кожна ціль - це окремий пункт.",
      //   example:
      //     "Сформувати сильний бренд як експерта, вивести блог на стабільний дохід від ... $, запустити інформаційні продукти.",
      //   sectionKey: SECTION_KEYS.GOALS,
      //   hasInstruction: false,
      //   formType: SectionFormType.List,
      // },
      // {
      //   title: "🧙 Як ти плануєш досягнути цілей?",
      //   description:
      //     "Напиши своє бачення або ж подивись на мої варіанти нижче. Можеш скопіювати і відредагувати.",
      //   example:
      //     "Створити курс 'Від сценарію до відеозйомки', консультації та менторство зі сторітелінгу, зйомки для брендів, реклама.",
      //   sectionKey: SECTION_KEYS.PRODUCTS_OR_SERVICES,
      //   hasInstruction: true,
      //   formType: SectionFormType.List,
      // },
      // {
      //   title: "Які у тебе класні цілі і методи їх досягнення😉",
      //   description:
      //     "Для створення стратегії потрібно ще визначити пріоритетність, бо все одразу навіть я не потягну 🧙",
      //   hasInstruction: false,
      // },
      // {
      //   title: "⏳ З чого починаємо?",
      //   description: "Який продукт чи сервіс ти плануєш розвивати першим?",
      //   example: "Курс 'Від сценарію до відеозйомки'",
      //   sectionKey: SECTION_KEYS.PRODUCT_OR_SERVICE,
      //   hasInstruction: false,
      //   formType: SectionFormType.ShortText,
      // },
      {
        title: "👑 Для кого твій контент?",
        description:
          "Давай спробуємо розібратись, хто твоя цільова аудиторія. Я тобі генеруватиму варіанти. Ти можеш обирати з мого списку, редагувати його або ж доповнювати своїм. <br/> Максимальна кількість варіантів - 3.",
        sectionKey: SECTION_KEYS.TARGET_AUDIENCE,
        hasInstruction: true,
        options: { maxItems: 3 },
        formType: SectionFormType.List,
      },
      {
        title: "👑 Яка твоя унікальна торгова пропозиція (УТП)?",
        description:
          "Опиши, чим буде вирізнятись твій особистий бренд на фоні конкурентів. Дивись мої підказки як приклади твого УТП. Далі ми сформулюємо його коротко.",
        example: "Відеозйомка історій на телефон до хвилини",
        sectionKey: SECTION_KEYS.POSITIONING,
        hasInstruction: true,
        formType: SectionFormType.LongText,
      },
      {
        title: "💡 А тепер сформуємо слоган для твого УТП",
        description:
          "Ти зможеш використовувати у своєму контенті. Я тобі допоможу з варіантами.",
        example: "Кіно на хвилину",
        sectionKey: SECTION_KEYS.SLOGAN,
        hasInstruction: true,
        formType: SectionFormType.ShortText,
      },
      {
        title: "💎 Які твої цінності?",
        description:
          "Хочу допомогти сформувати тобі місію. Розкажи, що ти вкладаєш у свій продукт, що хочеш транслювати світу, чому це важливо отримати людям?",
        example:
          "Показувати, що у буденному є багато прекрасного, якщо вміти це помічати. Фільмувати життя як захоплюючу подорож. Надихати людей бути сміливими у своїх мріях і йти до них.",
        sectionKey: SECTION_KEYS.VALUES,
        hasInstruction: false,
        formType: SectionFormType.List,
      },
      {
        title: "🌄 Давай сформуємо місію",
        description:
          "Можеш відштовхуватись від моїх варіантів і відредагувати, якщо потрібно.",
        example: "",
        sectionKey: SECTION_KEYS.MISSION,
        hasInstruction: true,
        formType: SectionFormType.LongText,
      },
      {
        title: "💪 Який експертний досвід ти плануєш показувати у контенті?",
        description: "",
        example: "",
        sectionKey: SECTION_KEYS.EXPERT_CONTENT,
        hasInstruction: true,
        formType: SectionFormType.List,
      },
      {
        title: "🧘‍♂️ Які сфери життя ти хочеш показувати у контенті?",
        description: "",
        example:
          "Історії з життя (про себе, кота, сина, еміграцію). Історії з бізнесу, блогу, курсу. Про зйомку відео, сценарії, сторітелінг. Про подорожі. Про свої мрії, як будинок біля моря.",
        sectionKey: SECTION_KEYS.PERSONAL_CONTENT,
        hasInstruction: false,
        formType: SectionFormType.List,
      },
      // {
      //   title: "😨 Давай поговоримо і про страхи",
      //   description:
      //     "Знаєш, страх - необхідний нам для виживання. Його задача - ПОПЕРЕДИТИ про можливу небезпеку. І виникає він тоді, коли перед нами щось нове. Я покажу отобі, як конвертувати страх у дію.",
      //   hasInstruction: false,
      // },
      // {
      //   title: "😨 Що тебе зупиняє?",
      //   description:
      //     "Розкажи про свої страхи. Які перешкоди ти бачиш на шляху до своїх цілей? Я згенерую тобі ідеї, як перетворити страх на план дій.  <br/> Максимальна кількість елементів - 10.",
      //   example:
      //     "Велика конкуренція (не зможу вирізнятись). Ніхто не захоче прийти на мої послуги. Боюсь помилитись",
      //   sectionKey: SECTION_KEYS.FEARS,
      //   hasInstruction: false,
      //   options: { maxItems: 10 },
      //   formType: SectionFormType.List,
      // },
      // {
      //   title: "🔍 Яким ти бачиш свій контент?",
      //   description: "Опиши трьома словами або реченнями",
      //   example:
      //     "Мій контент має бути 'живим', креативним, дотепним, творчим і будуватись через історії.",
      //   sectionKey: SECTION_KEYS.CONTENT_VISION,
      //   hasInstruction: false,
      //   formType: SectionFormType.LongText,
      // },
      // {
      //   title: "💪 Які твої сильні сторони?",
      //   description: "Що допомагало тобі раніше досягати цілей?",
      //   example:
      //     "Власне бачення; вміння генерувати ідеї; цілеспрямованність; надійнійсть; вміння швидко навчатись і адаптовуватись; щирість; відповідальність",
      //   sectionKey: SECTION_KEYS.STRENGTHS,
      //   hasInstruction: false,
      //   formType: SectionFormType.List,
      // },
    ],
    []
  );
};

const Page: PageType = () => {
  usePageView();
  //const auth = useAuth();
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { strategyId } = useParams();
  // useEffect(() => {
  //   if (auth.isAuthenticated) {
  //     router.replace(paths.dashboard.index);
  //   }
  // }, [auth, router]);

  const configSteps = useStrategySteps();

  const { step } = useParams();
  const steps = useSelector((state) => state.strategyWizard.steps);

  useEffect(() => {
    async function initSteps() {
      if (strategyId) {
        await dispatch(thunks.getStrategySteps(configSteps));
        await dispatch(settingsThunks.setStrategyId(strategyId));
      } else {
        router.push(paths.index);
      }
    }
    initSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configSteps, strategyId]);

  useEffect(() => {
    async function fetchWizardStep() {
      const parsedStep = Number.parseInt(step ?? "0");
      if (parsedStep >= configSteps.length) {
        router.push(
          createRoute(paths.wizard.step).link({
            step: "0",
            strategyId: strategyId,
          })
        );
      } else {
        await dispatch(strategyThunks.setCurrentStep(parsedStep));
      }
    }
    fetchWizardStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (!steps.length) {
    return null;
  }

  return (
    <>
      <Seo title={t(tokens.wizard.step.title) as string} />
      <WizardStep />
    </>
  );
};

export default Page;
