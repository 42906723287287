import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ContentPlaceholder } from "src/types/content-placeholder";

interface ContentPlaceholdersState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: ContentPlaceholder[];
}

const initialState: ContentPlaceholdersState = {
  isLoading: false,
  hasLoadingError: false,
  items: [],
};

const reducers = {
  setLoadingStatus: (
    state: ContentPlaceholdersState,
    action: PayloadAction<boolean>
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: ContentPlaceholdersState,
    action: PayloadAction<boolean>
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getContentPlaceholders: (
    state: ContentPlaceholdersState,
    action: PayloadAction<ContentPlaceholder[]>
  ): void => {
    state.items = action.payload;
  },
  updateContentPlaceholder: (
    state: ContentPlaceholdersState,
    action: PayloadAction<ContentPlaceholder>
  ): void => {
    const topic = action.payload;
    const index = state.items.findIndex((t) => t.id === topic.id);
    if (index !== -1) {
      state.items[index] = topic;
    }
  },
};

export const slice = createSlice({
  name: "content-placeholders",
  initialState,
  reducers,
});

export const { reducer } = slice;
