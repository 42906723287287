export enum SocialProfileType {
  None,
  Instagram,
  Facebook,
  Twitter,
  LinkedIn,
}

export interface SocialProfile {
  type: SocialProfileType;
  data: any;
}
