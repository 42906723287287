"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import { useAuth } from "src/hooks/use-auth";
import { useSettings } from "src/hooks/use-settings";
import {
  Button,
  ListItemButton,
  ListItemText,
  SvgIcon,
  useColorScheme,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { OptionsColorScheme } from "src/components/core/settings/options-color-scheme";
import { ColorScheme } from "src/styles/theme/types";
import { setSettings as setPersistedSettings } from "../../../lib/settings/set-settings";
import { Issuer } from "src/utils/auth";
import { toast } from "react-hot-toast";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import { RouterLink } from "src/components/router-link";
import { Stack } from "@mui/system";

export interface UserPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  onImpersonate?: () => void;
  open: boolean;
}

export function UserPopover({
  anchorEl,
  onClose,
  open,
  onImpersonate,
}: UserPopoverProps): React.JSX.Element {
  const auth = useAuth();
  const abilityToImpersonate = auth.account?.isHost;
  const { settings, setSettings } = useSettings();
  const { setColorScheme } = useColorScheme();
  const { t } = useTranslation();
  const router = useRouter();

  const handleUpdate = async (value: ColorScheme): Promise<void> => {
    setColorScheme(value);

    settings.colorScheme = value;

    setPersistedSettings(settings);
    setSettings(settings);
  };

  const handleLogout = React.useCallback(async (): Promise<void> => {
    try {
      onClose?.();

      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error(t(tokens.general.formError) as string);
    }
  }, [onClose, auth, router, t]);

  const handleStopImpersonation = React.useCallback(async (): Promise<void> => {
    try {
      onClose?.();

      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.stopImpersonation();
          router.push(paths.calendar);
          //router.push(paths.topics.list);
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }
    } catch (err) {
      console.error(err);
      toast.error(t(tokens.general.formError) as string);
    }
  }, [onClose, auth, router, t]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      onClose={onClose}
      open={Boolean(open)}
      slotProps={{ paper: { sx: { width: "280px" } } }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      sx={{
        gap: 1,
      }}
    >
      <Box sx={{ p: 2 }}>
        {auth.account?.firstName && auth.account?.lastName ? (
          <>
            <Typography
              variant="body1"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {`${auth.account?.firstName ?? ""} ${auth.account?.lastName}` ?? ""}
            </Typography>
            <Typography
              color="text.secondary"
              variant="body2"
            >
              {auth.account?.email}
            </Typography>
          </>
        ) : (
          <Typography>{auth.account?.email}</Typography>
        )}
      </Box>
      <Divider />

      <Stack
        spacing={1}
        sx={{ p: 1 }}
      >
        {auth.isImpersonated && (
          <ListItemButton
            onClick={() => {
              handleStopImpersonation();
            }}
            sx={{
              gap: 2,
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <KeyIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">
                  {t(tokens.account.popover.stopImpersonation)}
                </Typography>
              }
            />
          </ListItemButton>
        )}

        <ListItemButton
          component={RouterLink}
          href={paths.account.settings}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            gap: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {t(tokens.account.popover.account)}
              </Typography>
            }
          />
        </ListItemButton>

        {abilityToImpersonate && !auth.isImpersonated && (
          <React.Fragment>
            <ListItemButton
              onClick={async () => {
                if (onClose) {
                  onClose();
                }
                //setImpersonateDrawerOpen(true);
                onImpersonate?.();
              }}
              sx={{
                borderRadius: 1,
                gap: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon>
                <SvgIcon fontSize="small">
                  <KeyIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {t(tokens.account.popover.impersonate)}
                  </Typography>
                }
              />
            </ListItemButton>

            <Divider />
          </React.Fragment>
        )}
      </Stack>
      <Box
        sx={{
          display: "flex",
          p: 2,
        }}
      >
        <OptionsColorScheme
          onChange={(value) => {
            handleUpdate(value);
          }}
          value={settings.colorScheme}
        />
      </Box>

      <Divider />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small"
        >
          {t(tokens.account.popover.signOut)}
        </Button>
      </Box>
    </Popover>
  );
}
