import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultError } from "src/types/error";
import { ContentTemplate } from "src/types/content-template";

interface ContentTemplateState {
  isLoading: boolean;
  error: ResultError | undefined;

  isSaving: boolean;
  hasSavingError: boolean;
  item: ContentTemplate | undefined;
}

type LoadContentTemplateAction = PayloadAction<ContentTemplate | undefined>;
type ContentTemplateLoadingAction = PayloadAction<boolean>;
type ContentTemplateLoadingErrorAction = PayloadAction<ResultError | undefined>;

const initialState: ContentTemplateState = {
  isLoading: false,
  error: undefined,
  isSaving: false,
  hasSavingError: false,
  item: undefined,
};

const reducers = {
  setLoadingStatus: (
    state: ContentTemplateState,
    action: ContentTemplateLoadingAction
  ): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: ContentTemplateState,
    action: ContentTemplateLoadingErrorAction
  ): void => {
    state.error = action.payload;
  },
  setContentTemplate: (
    state: ContentTemplateState,
    action: LoadContentTemplateAction
  ): void => {
    state.item = action.payload;
  },
};

export const slice = createSlice({
  name: "storyline",
  initialState,
  reducers,
});

export const { reducer } = slice;
