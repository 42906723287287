import { useEffect } from "react";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
// import { HomeSetGoal } from 'src/sections/home/home-set-goal';
import type { Page as PageType } from "src/types/page";
import { useTranslation } from "react-i18next";
import { Card, Container, Stack } from "@mui/material";
import { WizardHeader } from "src/sections/wizard/wizard-header";
import { WizardSignup } from "src/sections/wizard/wizard-signup";
import { useStrategySteps } from "./step";
import { tokens } from "src/locales/tokens";
import { useParams } from "react-router";
import { useDispatch } from "src/store";
import { thunks as settingsThunks } from "src/thunks/settings";

const Page: PageType = () => {
  usePageView();
  //const auth = useAuth();
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { strategyId } = useParams();
  const configSteps = useStrategySteps();

  useEffect(() => {
    async function initSteps() {
      if (!strategyId) {
        router.push(paths.index);
      } else {
        await dispatch(settingsThunks.setStrategyId(strategyId));
      }
    }
    initSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, strategyId]);

  return (
    <>
      <Seo title={t(tokens.wizard.signup.title) as string} />
      <Container
        maxWidth="md"
        sx={{
          mt: {
            xs: 2,
            sm: 4,
          },
          mb: 4,
        }}
      >
        <Card
          sx={{
            p: 4,
            mb: 2,
          }}
        >
          <Stack
            direction={"column"}
            spacing={3}
          >
            <WizardHeader
              pageIndex={configSteps.length}
              totalPages={configSteps.length + 1}
              onBack={() => {
                router.back();
              }}
            />

            <WizardSignup />
          </Stack>
        </Card>
      </Container>
    </>
  );
};

export default Page;
