import { type FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { thunks } from "src/thunks/target-audience";
import { useDispatch, useSelector } from "src/store";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextEditor } from "src/components/core/text-editor/text-editor";

interface Values {
  name: string;
  description: string;
  content: string;
  isStandalone: boolean;
  submit: null;
}

const initialValues: Values = {
  name: "",
  description: "",
  content: "",
  isStandalone: false,
  submit: null,
};

export const TargetAudienceCreateForm: FC = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const { strategyId } = useSelector((state) => state.settings);
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      const targetAudience = await dispatch(
        thunks.createTargetAudience(strategyId!, {
          name: values.name,
          description: values.description,
          content: values.content,
        })
      );
      if (targetAudience) {
        toast.success(t(tokens.targetAudiences.create.form.successMessage));
        router.push(
          createRoute(paths.targetAudiences.edit).link({
            targetAudienceId: targetAudience.id,
          })
        );
      } else {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        //helpers.setErrors({ submit: err.Message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label={t(tokens.targetAudiences.create.form.name)}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <Stack spacing={1}>
                <Typography variant="body2">
                  {t(tokens.targetAudiences.create.form.description)}
                </Typography>
                <TextEditor
                  showHeaderTool={true}
                  content={formik.values.content}
                  onUpdate={(content) => {
                    formik.setFieldValue(
                      "description",
                      content.editor.getHTML()
                    );
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2">
                  {t(tokens.targetAudiences.create.form.content)}
                </Typography>
                <TextEditor
                  showHeaderTool={true}
                  content={formik.values.content}
                  onUpdate={(content) => {
                    formik.setFieldValue("content", content.editor.getHTML());
                  }}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Stack
          alignItems="center"
          direction="row-reverse"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            href={paths.targetAudiences.list}
            color="inherit"
            component={RouterLink}
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
          <LoadingButton
            type="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t(tokens.general.buttons.create)}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
