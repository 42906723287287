"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NoteBlank as NoteBlankIcon } from "@phosphor-icons/react/dist/ssr/NoteBlank";
import { Sparkle as SparkleIcon } from "@phosphor-icons/react/dist/ssr/Sparkle";
import { BoxArrowDown as BoxArrowDownIcon } from "@phosphor-icons/react/dist/ssr/BoxArrowDown";
import {
  Card,
  CardActionArea,
  CardContent,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";

export interface TopicOptionsProps {
  onNewTopic?: () => void;
  onSelectFromExisting: () => void;
  onGenerateTopic: () => void;
}

export function TopicOptions({
  onNewTopic,
  onSelectFromExisting,
  onGenerateTopic,
}: TopicOptionsProps): React.JSX.Element {
  const { t } = useTranslation();

  const categoryOptions = React.useMemo(() => {
    return [
      {
        title: t(tokens.contentPlaceholders.options.newTopic),
        value: "newTopic",
        icon: <NoteBlankIcon size="36" />,
      },
      {
        title: t(tokens.contentPlaceholders.options.selectTopic),
        value: "existingTopic",
        icon: <BoxArrowDownIcon size="36" />,
      },
      // {
      //   title: t(tokens.contentPlaceholders.options.generateTopic),
      //   value: "generateTopic",
      //   icon: <SparkleIcon size="36" />,
      // },
    ] satisfies {
      title: string;
      value: string;
      disabled?: boolean;
      icon?: React.ReactNode;
    }[];
  }, [t]);

  return (
    <Grid
      container
      spacing={4}
    >
      {categoryOptions.map((option) => (
        <Grid xs={6}>
          <Card
            key={option.value}
            onClick={() => {
              if (option.value === "newTopic") {
                onNewTopic?.();
              } else if (option.value === "existingTopic") {
                onSelectFromExisting();
              } else if (option.value === "generateTopic") {
                onGenerateTopic();
              }
            }}
          >
            <CardActionArea
              sx={{
                height: "200px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Stack
                  direction={"column"}
                  spacing={2}
                  alignContent={"center"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    sx={{
                      color: "var(--mui-palette-primary-main)",
                    }}
                  >
                    {option.icon}
                  </Box>
                  <Typography
                    sx={{
                      color: "var(--mui-palette-text-primary)",
                      textAlign: "center",
                    }}
                    variant="h6"
                  >
                    {option.title}
                  </Typography>
                  {/* <Typography
                  sx={{
                    color: "var(--mui-palette-text-secondary)",
                  }}
                  variant="body2"
                >
                  {option.description}
                </Typography> */}
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
