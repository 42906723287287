import React, { FC } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { Topic } from "src/types/topic";
import { TopicGenerationForm } from "./topic-generation-form";
import { StorylineCategory } from "src/types/storyline-category";

interface GenerateTopicDrawerProps {
  open: boolean;
  category: StorylineCategory;
  onClose: () => void;
  onTopicAdd: (topic: Topic) => void;
  onTopicRemove: (topicId: String) => void;
}

export const GenerateTopicDrawer: FC<GenerateTopicDrawerProps> = (props) => {
  const { open, onClose, onTopicAdd, category, onTopicRemove } = props;
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      // hideBackdrop
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={onClose}
      // slotProps={{
      //   backdrop: {
      //     invisible: true,
      //   },
      // }}
      PaperProps={{
        sx: {
          overflow: "visible",
          width: "100%",
          maxWidth: 700,
        },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          pl: 3,
          pr: 3,
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="h6">
          {t(tokens.calendar.generateTopic.pageHeader)}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <IconButton
            size="small"
            color="inherit"
            onClick={() => {
              onClose();
            }}
          >
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      <div
        style={{
          display: "inline-block",
          height: "100%",
          overflow: "auto",
          paddingBottom: 1,
        }}
      >
        <Box
          sx={{
            p: 3,
          }}
        >
          <TopicGenerationForm
            category={category}
            showVerticalFilters={true}
            onTopicAdd={onTopicAdd}
            onTopicRemove={onTopicRemove}
          />
        </Box>
      </div>
    </Drawer>
  );
};
