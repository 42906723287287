"use client";

import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "src/store";

export interface Workspaces {
  name: string;
  avatar: string;
}

export interface WorkspacesPopoverProps {
  anchorEl: null | Element;
  onChange?: (tenant: string) => void;
  onClose?: () => void;
  open?: boolean;
}

export function WorkspacesPopover({
  anchorEl,
  onChange,
  onClose,
  open = false,
}: WorkspacesPopoverProps): React.JSX.Element {
  const { items } = useSelector((state) => state.strategies);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { width: "250px" } } }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
    >
      {items.map((item) => (
        <MenuItem
          sx={{
            textWrap: "wrap",
          }}
          key={item.id}
          onClick={() => {
            onChange?.(item.id);
          }}
        >
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
}
