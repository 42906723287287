import { type FC, useCallback } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Storyline } from "src/types/storyline";
import { ContentGoalType } from "src/types/content-goal-type";
import { ItemMenu } from "src/sections/common/item-menu";
import { RouterLink } from "src/components/core/link";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";

interface StorylineItemProps {
  item?: Storyline;
  onDelete?: () => void;
}

export const StorylineItem: FC<StorylineItemProps> = (props) => {
  const { t } = useTranslation();
  const { item, onDelete } = props;

  const getContentGoalType = useCallback(
    (contentGoalType: ContentGoalType) => {
      switch (contentGoalType) {
        case 1:
          return t(tokens.general.contentGoalTypes.engagement);
        case 2:
          return t(tokens.general.contentGoalTypes.community);
        case 3:
          return t(tokens.general.contentGoalTypes.selling);
        default:
          return "";
      }
    },
    [t]
  );

  const getEmojii = useCallback((type: number) => {
    switch (type) {
      case 1:
        return "💪";
      case 2:
        return "🌱";
      case 3:
        return "💎";
      default:
        return "🌟";
    }
  }, []);

  return (
    <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
        component={RouterLink}
        href={
          item?.id
            ? createRoute(paths.storylines.details).link({
                storylineId: item!.id,
              })
            : ""
        }
      >
        <CardMedia
          image={"/assets/image-abstract-1.png"}
          sx={{ height: "100px", width: "100%" }}
        >
          <Stack
            sx={{ mt: 1, mr: 1 }}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
          >
            <ItemMenu onDelete={onDelete} />
          </Stack>
        </CardMedia>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "-50px",
          }}
        >
          <Avatar
            alt="Type"
            sx={{
              "--Avatar-size": "100px",
              border: "3px solid var(--mui-palette-background-paper)",
              backgroundColor: "var(--mui-palette-background-default)",
            }}
          >
            {!item && (
              <Skeleton
                width={100}
                height={100}
                variant="circular"
              />
            )}
            {item && (
              <Typography variant="h2">{getEmojii(item?.type ?? 0)}</Typography>
            )}
          </Avatar>
        </Box>

        <CardContent
          sx={{
            mt: 0,
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Typography
              color="text.primary"
              variant="h5"
            >
              {item && <>{item?.name}</>}
            </Typography>
            {!item && (
              <>
                <Skeleton height={30} />
              </>
            )}
            <Typography
              color="text.secondary"
              variant="body2"
            >
              {item && <>{item?.description}</>}
            </Typography>
            {!item && (
              <>
                <Box>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Box>
              </>
            )}
            <Box>
              {item && (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={getContentGoalType(item?.contentGoalType ?? 0)}
                />
              )}
              {!item && (
                <>
                  <Skeleton width={100} />
                </>
              )}
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
