import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Sparkle as SparkleIcon } from "@phosphor-icons/react/dist/ssr/Sparkle";
import { ContentTemplate } from "src/types/content-template";
import axios, { CancelTokenSource } from "axios";
import { Editor } from "@tiptap/react";
import { TemplateDrawer } from "./template-drawer";
import { thunks as promptsThunks } from "src/thunks/prompts";
import { PromptMessage, PromptMessageUser } from "src/types/prompts";

interface TemplateButtonProps {
  editor: Editor | null;
  onTextGenerate: (text: string) => void;
  onFinish?: () => void;
  getMessages: () => PromptMessage[];
}
export const TemplateButton: React.FC<TemplateButtonProps> = (props) => {
  const { onTextGenerate, onFinish, getMessages } = props;

  const [isGenerating, setIsGenerating] = React.useState(false);
  const dispatch = useDispatch();
  const [savedTopicContent, setSavedTopicContent] = useState<
    string | undefined
  >();

  const { t, i18n } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { strategyId } = useSelector((state) => state.settings);

  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const generateTopicContent = useCallback(
    async (
      contentTemplate: ContentTemplate | undefined,
      instruction: string
    ) => {
      setIsGenerating(true);
      setOpenDrawer(false);

      var messages = getMessages();

      if (contentTemplate) {
        messages.push({
          user: PromptMessageUser.User,
          content: `${contentTemplate?.instruction}`,
        });

        if (contentTemplate?.examples.length > 0) {
          var examples = "\n\nUse the following examples:\n";
          contentTemplate?.examples.forEach((example, index) => {
            examples += `Example ${index + 1} start:\n${example}\n`;
            examples += `Example ${index + 1} end\n`;
          });

          messages.push({
            user: PromptMessageUser.User,
            content: examples,
          });
        }
      }

      if (savedTopicContent) {
        messages.push({
          user: PromptMessageUser.User,
          content: savedTopicContent,
        });
      }
      if (instruction) {
        messages.push({
          user: PromptMessageUser.User,
          content: `${instruction}`,
        });
      }

      await dispatch(
        promptsThunks.runChatPrompt(
          strategyId!,
          i18n.language,
          {
            messages: messages,
          },
          (text) => {
            onTextGenerate(text);
          },
          () => {
            toast.error(t(tokens.general.formError));
            setIsGenerating(false);
          },
          (text) => {
            onFinish?.();
            setIsGenerating(false);
            setSavedTopicContent(text);
          },
          cancelTokenSource
        )
      );
    },
    [
      cancelTokenSource,
      dispatch,
      getMessages,
      i18n.language,
      onFinish,
      onTextGenerate,
      savedTopicContent,
      strategyId,
      t,
    ]
  );

  return (
    <>
      {!isGenerating ? (
        <Button
          startIcon={<SparkleIcon />}
          variant="outlined"
          size="small"
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          {t(tokens.topics.details.form.generateButton)}
        </Button>
      ) : (
        <Button
          startIcon={<SparkleIcon />}
          variant="outlined"
          color="error"
          size="small"
          onClick={() => {
            setIsGenerating(false);
            onFinish?.();
            cancelTokenSource.cancel();
            setCancelTokenSource(axios.CancelToken.source());
          }}
        >
          {t(tokens.general.buttons.cancel)}
        </Button>
      )}

      <TemplateDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        onReset={() => {}}
        onSubmit={async (template, instruction) => {
          setOpenDrawer(false);

          await generateTopicContent(template, instruction);
        }}
      />
    </>
  );
};
