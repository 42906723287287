"use client";
import * as React from "react";
import Stack from "@mui/material/Stack";
import { Autocomplete, TextField, debounce } from "@mui/material";
import { useDispatch, useSelector } from "src/store";
import { thunks as storylinesThunks } from "src/thunks/storylines";
import { Storyline, StorylineCategory } from "src/types/storyline";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";

export interface TopicFilters {
  text: string | null;
  storylineCategory: StorylineCategory | null;
  storyline: Storyline | null;
}

interface TopicListFiltersProps {
  showVerticalFilters?: boolean;
  onFiltersChange: (filters: TopicFilters) => void;
}

export const TopicListFilters: React.FC<TopicListFiltersProps> = (props) => {
  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);
  const { t } = useTranslation();

  const [filters, setFilters] = React.useState<TopicFilters>({
    text: "",
    storylineCategory: null,
    storyline: null,
  });

  const { isLoading: isStorylinesLoading, items: storylines } = useSelector(
    (state) => state.storylines
  );

  const [storylineInputValue, setStorylineInputValue] = React.useState("");

  const fetchStorylines = React.useMemo(
    () =>
      debounce(async (request: string) => {
        await dispatch(
          storylinesThunks.getStorylines(strategyId!, {
            top: 25,
            page: 1,
            text: request,
          })
        );
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [strategyId]
  );

  React.useEffect(() => {
    let active = true;

    if (active) {
      fetchStorylines(storylineInputValue);
    }

    return () => {
      active = false;
    };
  }, [fetchStorylines, storylineInputValue]);

  const onTextChange = debounce(async (text: string) => {
    const newFilters = {
      ...filters,
      text: text,
    };
    setFilters(newFilters);
    props.onFiltersChange(newFilters);
  }, 400);

  return (
    <div>
      <Stack
        spacing={2}
        justifyContent={"space-between"}
        direction={{
          xs: "column",
          sm: props.showVerticalFilters ? "column" : "row",
        }}
      >
        {/* <TextField
          size="medium"
          fullWidth
          label={t(tokens.topics.list.filters.text)}
          onChange={(event) => {
            onTextChange(event.target.value);
          }}
          name="text"
        /> */}
        <Stack
          direction={"row"}
          spacing={2}
        >
          <Autocomplete
            getOptionLabel={(option: Storyline) => `${option.name}`}
            options={storylines.items}
            fullWidth
            value={filters.storyline}
            loading={isStorylinesLoading}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(event: any, newValue: Storyline | null) => {
              const newFilters = { ...filters, storyline: newValue, storylineCategory: null };
              setFilters(newFilters);
              props.onFiltersChange(newFilters);
              setStorylineInputValue("");
            }}
            onInputChange={(event, newInputValue) => {
              setStorylineInputValue(newInputValue);
            }}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                size="small"
                fullWidth
                label={t(tokens.topics.list.filters.storyline)}
                name="storyline"
              />
            )}
          />
          <Autocomplete
            getOptionLabel={(option: StorylineCategory) => `${option.name}`}
            options={filters.storyline?.categories ?? []}
            fullWidth
            disabled={!filters.storyline}
            value={filters.storylineCategory}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(event: any, newValue: StorylineCategory | null) => {
              const newFilters = {
                ...filters,
                storylineCategory: newValue,
              };
              setFilters(newFilters);
              props.onFiltersChange(newFilters);
              //setProductInputValue("");
            }}
            onInputChange={(event, newInputValue) => {
              //setProductInputValue(newInputValue);
            }}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                size="small"
                fullWidth
                label={t(tokens.topics.list.filters.category)}
                name="storylineCategory"
              />
            )}
          />
        </Stack>
      </Stack>
    </div>
  );
};
