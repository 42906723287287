import React, { useCallback, type FC } from "react";
import { PlanType, Strategy } from "src/types/strategy";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";

interface StrategyPlanProps {
  strategy: Strategy;
}

export const StrategyPlan: FC<StrategyPlanProps> = (props) => {
  const { t } = useTranslation();
  const { strategy } = props;

  const getPlanName = useCallback(
    (planType: PlanType) => {
      if (planType === PlanType.Free) {
        return t(tokens.general.plans.free);
      } else if (planType === PlanType.Strategy) {
        return t(tokens.general.plans.basic);
      } else if (planType === PlanType.ContentPlan) {
        return t(tokens.general.plans.professional);
      }
      return planType;
    },
    [t]
  );

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Typography variant="h6">
              {t(tokens.strategySettings.plan.header)}
            </Typography>
            <Stack spacing={2}>
              <Stack spacing={1}>
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                  {t(tokens.strategySettings.plan.status)}
                </Typography>
                <Typography variant="body1">
                  {t(tokens.strategySettings.plan.active)}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                  {t(tokens.strategySettings.plan.currentPlan)}
                </Typography>
                <Typography variant="body1">
                  {getPlanName(strategy.planType)}
                </Typography>
              </Stack>
            </Stack>
            {strategy.planType !== PlanType.ContentPlan && (
              <Box>
                <LoadingButton
                  component={RouterLink}
                  variant="contained"
                  href={paths.pricing}
                >
                  {t(tokens.strategySettings.buttons.upgrade)}
                </LoadingButton>
              </Box>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
