import { type FC } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Container,
  Rating,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { paths } from "src/paths";
import { useRouter } from "src/hooks/use-router";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { thunks } from "src/thunks/strategy-wizard";
import { createRoute } from "react-router-url-params";
import { toast } from "react-hot-toast";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Sparkle as SparkleIcon } from "@phosphor-icons/react/dist/ssr/Sparkle";

export const HeroSection: FC = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Stack
        spacing={4}
        // maxWidth="sm"
        sx={{ textAlign: "center" }}
      >
        <Box>
          <Chip
            color="primary"
            icon={<SparkleIcon />}
            label="AI Copilot for personal brand"
            variant="soft"
          />
        </Box>

        <Typography
          variant="h1"
          textAlign={"center"}
          sx={{ mb: 2, mt: 2 }}
        >
          <Box>{t(tokens.landing.hero.title1)} </Box>
          <Box>
            <Typography
              component="span"
              color="primary.main"
              variant="inherit"
            >
              {t(tokens.landing.hero.title2)}
            </Typography>
          </Box>
          <Box></Box>
        </Typography>

        <Typography
          color="text.secondary"
          textAlign={"center"}
          sx={{
            fontSize: 20,
            fontWeight: 500,
          }}
          dangerouslySetInnerHTML={{ __html: t(tokens.landing.hero.subtitle) }}
        ></Typography>

        <HeroLoadingButton />

        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: "center", justifyContent: "center " }}
        >
          <AvatarGroup
            sx={{
              "& .MuiAvatar-root": {
                border: "1px solid var(--mui-palette-neutral-500)",
              },
            }}
          >
            <Avatar
              alt="User 1"
              src="/assets/landing/avatar1.jpg"
            />
            <Avatar
              alt="User 2"
              src="/assets/landing/avatar2.png"
            />
            <Avatar
              alt="User 3"
              src="/assets/landing/avatar3.jpg"
            />
          </AvatarGroup>
          <Stack direction={"column"}>
            <Rating
              name="read-only"
              value={5}
              readOnly
            />
            <Typography
              color="neutral.300"
              sx={{ whiteSpace: "nowrap" }}
              variant="caption"
            >
              Trusted by 12 clients
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            overflow: "hidden",
            fontSize: 0,

            pt: 2,
            px: 2,
            "& img": {
              borderColor: "background.paper",
              borderTopWidth: 1,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 0,
              borderStyle: "solid",
              borderTopLeftRadius: (theme) => theme.shape.borderRadius * 2,
              borderTopRightRadius: (theme) => theme.shape.borderRadius * 2,
              boxShadow: 16,
              width: "100%",
            },
          }}
        >
          <img
            src={
              theme.palette.mode === "dark"
                ? "/assets/home-landing-dark.png"
                : "/assets/home-landing.png"
            }
          />
        </Box>
      </Stack>
    </Container>
  );
};

const HeroLoadingButton = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { isInitializing } = useSelector((state) => state.strategyWizard);
  return (
    <Box>
      {/* <Button
        variant="contained"
        size="large"
        sx={{
          minWidth: 200,
          height: 56,
        }}
        href="https://tally.so/r/wvBxJg"
        target="_blank"
      >
        {t(tokens.landing.hero.button)}
      </Button> */}
      <LoadingButton
        disabled={isInitializing}
        loading={isInitializing}
        size="large"
        onClick={async () => {
          const strategyId = await dispatch(
            thunks.createStrategy(i18n.language)
          );
          if (strategyId) {
            router.push(
              createRoute(paths.wizard.step).link({
                step: "0",
                strategyId: strategyId,
              })
            );
          } else {
            toast.error(t(tokens.general.formError));
          }
        }}
        variant="contained"
        sx={{
          minWidth: 200,
          height: 56,
        }}
      >
        {t(tokens.landing.hero.button)}
      </LoadingButton>
    </Box>
  );
};
