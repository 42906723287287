import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { Faqs } from "src/sections/home/faqs";
import { Features } from "src/sections/home/features";
import { HeroSection } from "src/sections/home/hero-section";
import { Included } from "src/sections/home/included";
import { Productivity } from "src/sections/home/productivity";
import { StartBuilding } from "src/sections/home/start-building";
import { Testimonails } from "src/sections/home/testimonials";
import { useSelector } from "src/store";
import type { Page as PageType } from "src/types/page";

const Page: PageType = () => {
  usePageView();

  //  const { items } = useSelector((state) => state.strategies);
  //  const router = useRouter();
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (items.length > 0) {
  //     router.push(paths.calendar);
  //   }
  // }, [items, router]);

  return (
    <>
      <Seo title={t(tokens.landing.title) as string} />
      <Box
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundImage: 'url("/assets/gradient-bg.svg")',
        }}
      >
        <Box
          sx={{
            py: {
              xs: "90px",
              md: "100px",
            },
          }}
        >
          <HeroSection />
          {/* <Productivity /> */}
          {/* <Included /> */}
          {/* <Features /> */}
          {/* <Testimonails /> */}
          {/* <Faqs /> */}
          {/* <StartBuilding /> */}
        </Box>
      </Box>
    </>
  );
};

export default Page;
