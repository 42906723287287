import { useCallback, useEffect, useRef, useState, type FC } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import XIcon from "@untitled-ui/icons-react/build/esm/X";
import LoadingButton from "@mui/lab/LoadingButton";
import { RouterLink } from "src/components/core/link";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import toast from "react-hot-toast";
import { thunks } from "src/thunks/content-placeholder";
import { useDispatch, useSelector } from "src/store";
import { DatePicker } from "@mui/x-date-pickers";
import { Language, dateFormatLocales } from "src/language";
import { useFormik } from "formik";
import { ContentGoalType } from "src/types/content-goal-type";
import moment from "moment";
import * as Yup from "yup";
import { TextEditor } from "src/components/core/text-editor/text-editor";
import { ContentPlaceholder } from "src/types/content-placeholder";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Editor } from "@tiptap/react";
import { PromptMessage, PromptMessageUser } from "src/types/prompts";
import { TemplateButton } from "../common/editor/template-button";
import { PROMPTS } from "src/consts";
import { TopicOptions } from "./topic-options";
import { GenerateTopicDrawer } from "./generate-topic-drawer";
import { SelectTopicDrawer } from "./select-topic-drawer";
import { ContentType } from "src/types/content-type";

interface ContentPlaceholderDrawerProps {
  isOpen: boolean;
  date?: Date | null;
  contentPlaceholder?: ContentPlaceholder | undefined;
  onClose: () => void;
  contentTypes: ContentType[];
}

export const ContentPlaceholderDrawer: FC<ContentPlaceholderDrawerProps> = (
  props
) => {
  const { isOpen, contentPlaceholder, onClose, date, contentTypes } = props;
  const { t, i18n } = useTranslation();
  const [isNew, setIsNew] = useState(!contentPlaceholder);
  const editorRef = useRef<Editor | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openGenerateTopicDrawer, setOpenGenerateTopicDrawer] = useState(false);
  const [openSelectTopicDrawer, setOpenSelectTopicDrawer] = useState(false);
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);

  const { strategyId } = useSelector((state) => state.settings);

  const handleCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(true);
  }, []);

  const deletePlaceholder = useCallback(async () => {
    try {
      handleCloseDeleteDialog();
      await dispatch(
        thunks.deleteContentPlaceholder(strategyId!, contentPlaceholder!.id)
      );
      onClose();
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [
    dispatch,
    handleCloseDeleteDialog,
    onClose,
    strategyId,
    t,
    contentPlaceholder,
  ]);

  useEffect(() => {
    setShowForm(contentPlaceholder?.topic?.id !== undefined);
    if (contentPlaceholder) {
      setIsNew(false);
    } else {
      setIsNew(true);
    }
  }, [contentPlaceholder, isOpen]);

  const validationSchema = Yup.object({
    date: Yup.date().required(t(tokens.general.validators.required) as string),
    name: Yup.string().when("isTopicSelected", {
      is: (isTopicSelected: boolean) => isTopicSelected === true,
      then: (schema) =>
        schema
          .max(255, t(tokens.general.validators.maxLength255) as string)
          .required(t(tokens.general.validators.required) as string),
    }),
    contentTypeId: Yup.string().required(
      t(tokens.general.validators.required) as string
    ),
    contentGoalType: Yup.mixed<ContentGoalType>().oneOf(
      [
        ContentGoalType.Community,
        ContentGoalType.Engagement,
        ContentGoalType.Selling,
      ],
      t(tokens.general.validators.required) as string
    ),
  });

  const formik = useFormik({
    initialValues: {
      id: contentPlaceholder?.id ?? undefined,
      isTopicSelected: false,
      topicId: contentPlaceholder?.topic?.id ?? undefined,
      contentTypeId: contentPlaceholder?.contentType?.id ?? "",
      name: contentPlaceholder?.topic?.name ?? "",
      description: contentPlaceholder?.topic?.description ?? "",
      content: contentPlaceholder?.topic?.content ?? "",
      contentGoalType:
        contentPlaceholder?.contentGoalType ?? ContentGoalType.None,
      date: contentPlaceholder?.date
        ? moment(contentPlaceholder.date).toDate()
        : date
          ? moment(date).toDate()
          : undefined,
      submit: null,
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (values, helpers): Promise<void> => {
      //onSubmitStatus(true);
      try {
        if (values.id) {
          const updatedItem = await dispatch(
            thunks.updateContentPlaceholder(strategyId!, values.id, {
              topicId: values.topicId,
              name: values.name,
              date: values.date ? moment(values.date).valueOf() : null,
              description: values.description,
              content: values.content,
              contentTypeId: values.contentTypeId,
              contentGoalType: values.contentGoalType,
            })
          );
          formik.setFieldValue("topicId", updatedItem.topicId);
          //onSubmit?.(values.id);
          toast.success(t(tokens.topics.details.form.updateSuccessMessage));
        } else {
          const newItem = await dispatch(
            thunks.createContentPlaceholder(strategyId!, {
              topicId: values.topicId,
              name: values.name,
              date: values.date ? moment(values.date).valueOf() : null,
              description: values.description,
              content: values.content,
              contentTypeId: values.contentTypeId,
              contentGoalType: values.contentGoalType,
            })
          );
          //onSubmit?.(newTopic.id);
          formik.setFieldValue("id", newItem.id);
          formik.setFieldValue("topicId", newItem.topicId);
          toast.success(t(tokens.topics.details.form.createSuccessMessage));
        }
      } catch (error) {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      } finally {
        //onSubmitStatus(false);
      }
    },
  });

  const onNewTopic = useCallback(() => {
    //formik.resetForm();
    formik.setFieldValue("isTopicSelected", true);
    formik.setFieldValue("name", "");
    formik.setFieldValue("description", "");
    formik.setFieldValue("content", "");
    formik.setFieldValue("topicId", undefined);
    setShowForm(true);
    setIsNew(true);
  }, [formik]);

  const clearForm = useCallback(() => {
    formik.setFieldValue("name", "");
    formik.setFieldValue("description", "");
    formik.setFieldValue("content", "");
    formik.setFieldValue("topicId", undefined);
  }, [formik]);

  const onSelectFromExisting = useCallback(() => {
    clearForm();
    setOpenSelectTopicDrawer(true);
  }, [clearForm]);

  const onGenerateTopic = useCallback(() => {
    clearForm();
    setOpenGenerateTopicDrawer(true);
  }, [clearForm]);

  const onRemoveTopic = useCallback(() => {
    clearForm();
    setShowForm(false);
    setIsNew(true);
  }, [clearForm]);

  return (
    <Box>
      <Drawer
        anchor="right"
        variant="temporary"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            overflow: "visible",
            width: "100%",
            maxWidth: 700,
          },
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            pl: 3,
            pr: 3,
            pt: 2,
            pb: 2,
          }}
        >
          <Typography variant="h6">
            {t(tokens.contentPlaceholders.pageHeader)}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            spacing={0.5}
          >
            <IconButton
              size="small"
              color="inherit"
              onClick={() => {
                onClose();
              }}
            >
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Stack>
        <Divider />

        <div
          style={{
            display: "inline-block",
            height: "100%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box sx={{ overflow: "auto", height: "100%" }}>
              <Stack
                spacing={3}
                sx={{ p: 3 }}
              >
                <form
                  style={{
                    overflow: "hidden",
                    height: "100%",
                  }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      overflow: "hidden",
                    }}
                  >
                    <Grid
                      xs={12}
                      md={4}
                    >
                      <DatePicker
                        sx={{
                          width: "100%",
                        }}
                        format={dateFormatLocales[i18n.language as Language]}
                        label={t(tokens.contentPlaceholders.form.date)}
                        // sx={{ flexBasis: "100%", width: "33%" }}
                        componentsProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                        onChange={(date) => {
                          formik.setFieldValue("date", date ? date : null);
                        }}
                        slotProps={{
                          textField: {
                            error: !!(
                              formik.touched.date && formik.errors.date
                            ),
                            helperText:
                              formik.touched.date && formik.errors.date,
                            onBlur: formik.handleBlur,
                            onChange: formik.handleChange,
                          },
                        }}
                        value={formik.values.date || null}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      md={4}
                    >
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        select
                        error={
                          !!(
                            formik.touched.contentGoalType &&
                            formik.errors.contentGoalType
                          )
                        }
                        helperText={
                          formik.touched.contentGoalType &&
                          formik.errors.contentGoalType
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        label={t(tokens.contentPlaceholders.form.contentGoal)}
                        name="contentGoalType"
                        defaultValue={undefined}
                        value={formik.values.contentGoalType}
                      >
                        {/* <MenuItem
                          key={ContentGoalType.None}
                          value={ContentGoalType.None}
                        >
                          {t(tokens.general.contentGoalTypes.notSelected)}
                        </MenuItem> */}
                        <MenuItem
                          key={ContentGoalType.Engagement}
                          value={ContentGoalType.Engagement}
                        >
                          {t(tokens.general.contentGoalTypes.engagement)}
                        </MenuItem>
                        <MenuItem
                          key={ContentGoalType.Community}
                          value={ContentGoalType.Community}
                        >
                          {t(tokens.general.contentGoalTypes.community)}
                        </MenuItem>
                        <MenuItem
                          key={ContentGoalType.Selling}
                          value={ContentGoalType.Selling}
                        >
                          {t(tokens.general.contentGoalTypes.selling)}
                        </MenuItem>
                      </TextField>
                    </Grid>

                    <Grid
                      xs={12}
                      md={4}
                    >
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        select
                        error={
                          !!(
                            formik.touched.contentTypeId &&
                            formik.errors.contentTypeId
                          )
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        label={t(tokens.contentPlaceholders.form.contentType)}
                        name="contentTypeId"
                        value={formik.values.contentTypeId}
                        helperText={
                          formik.touched.contentTypeId &&
                          formik.errors.contentTypeId
                        }
                      >
                        {contentTypes.map((contentType) => (
                          <MenuItem
                            key={contentType.id}
                            value={contentType.id}
                          >
                            {contentType.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </form>
                <Divider />
                {!showForm && (
                  <TopicOptions
                    onNewTopic={onNewTopic}
                    onGenerateTopic={onGenerateTopic}
                    onSelectFromExisting={onSelectFromExisting}
                  />
                )}
              </Stack>
              {showForm && (
                <Stack
                  spacing={3}
                  sx={{
                    pl: 3,
                    pr: 3,
                    pb: 3,
                    position: "relative",
                  }}
                >
                  <Stack
                    direction={"column"}
                    alignContent={"flex-end"}
                    alignItems={"flex-end"}
                    spacing={3}
                    sx={{
                      position: "absolute",
                      right: 24,
                      zIndex: 1000,
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      color={"error"}
                      onClick={onRemoveTopic}
                      startIcon={<XIcon />}
                    >
                      Remove topic
                    </Button>
                  </Stack>

                  <TextField
                    error={!!(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    label={t(tokens.contentPlaceholders.form.name)}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    sx={{
                      mt: 2,
                    }}
                  />

                  {/* <TextField
                    error={
                      !!(
                        formik.touched.description && formik.errors.description
                      )
                    }
                    multiline
                    minRows={2}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    label={t(tokens.contentPlaceholders.form.description)}
                    name="description"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  /> */}

                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                      }}
                    >
                      {t(tokens.contentPlaceholders.form.content)}
                    </Typography>
                    <TextEditor
                      showHeaderTool={true}
                      ref={editorRef}
                      content={formik.values.content}
                      onUpdate={(content) => {
                        formik.setFieldValue(
                          "content",
                          content.editor.getHTML()
                        );
                      }}
                      placeholder={t(tokens.general.placeholders.editor)}
                      customButtons={[
                        <TemplateButton
                          editor={editorRef.current}
                          onTextGenerate={(text) => {
                            editorRef?.current?.commands.setContent(text);
                          }}
                          getMessages={() => {
                            var messages = [
                              {
                                user: PromptMessageUser.System,
                                content: PROMPTS.TOPIC_CONTENT,
                              },
                            ] as PromptMessage[];
                            if (formik.values.name) {
                              messages.push({
                                user: PromptMessageUser.User,
                                content: `Topic title:\n ${formik.values.name}`,
                              });
                            }

                            if (formik.values.description) {
                              messages.push({
                                user: PromptMessageUser.User,
                                content: `Topic description:\n ${formik.values.description}`,
                              });
                            }

                            return messages;
                          }}
                          onFinish={() => {
                            formik.setFieldValue(
                              "content",
                              editorRef?.current?.getHTML()
                            );
                          }}
                        />,
                      ]}
                    />
                  </Box>
                </Stack>
              )}
            </Box>
            <Divider />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={1}
              sx={{
                pt: 2,
                pb: 2,
                pr: 3,
                pl: 3,
                mt: 0,
              }}
            >
              <Stack
                spacing={1}
                direction={"row"}
              >
                <LoadingButton
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.save)}
                </LoadingButton>
                <Button
                  color="inherit"
                  component={RouterLink}
                  onClick={onClose}
                >
                  {t(tokens.general.buttons.cancel)}
                </Button>
              </Stack>
              {!isNew && (
                <Tooltip
                  title="Delete"
                  placement="top"
                >
                  <IconButton onClick={() => handleOpenDeleteDialog()}>
                    <SvgIcon>
                      <DeleteOutlineOutlinedIcon color="error" />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Box>
        </div>
        <GenerateTopicDrawer
          open={openGenerateTopicDrawer}
          onClose={() => {
            setOpenGenerateTopicDrawer(false);
          }}
          onSelect={(topic) => {
            formik.setFieldValue("isTopicSelected", true);
            formik.setFieldValue("name", topic.name);
            formik.setFieldValue("description", topic.description);
            setShowForm(true);
            setOpenGenerateTopicDrawer(false);
          }}
        />

        <SelectTopicDrawer
          open={openSelectTopicDrawer}
          onClose={() => {
            setOpenSelectTopicDrawer(false);
          }}
          onSelect={(topic) => {
            formik.setFieldValue("isTopicSelected", true);
            formik.setFieldValue("topicId", topic.id);
            formik.setFieldValue("name", topic.name);
            formik.setFieldValue("description", topic.description);
            setShowForm(true);
            setOpenSelectTopicDrawer(false);
          }}
        />
      </Drawer>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.contentPlaceholders.deleteContentPlaceholderDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              tokens.contentPlaceholders
                .deleteContentPlaceholderDialogDescription
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deletePlaceholder}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleCloseDeleteDialog}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
