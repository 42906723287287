import type { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { thunks } from "src/thunks/content-template";
import { useDispatch, useSelector } from "src/store";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { TextEditor } from "src/components/core/text-editor/text-editor";

interface Values {
  name: string;
  instruction: string;
  examples: string[];
  submit: null;
}

const initialValues: Values = {
  name: "",
  instruction: "",
  examples: [],
  submit: null,
};

export const ContentTemplateCreateForm: FC = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const { strategyId } = useSelector((state) => state.settings);

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={async (values, helpers): Promise<void> => {
        try {
          const contentTemplate = await dispatch(
            thunks.createContentTemplate(strategyId!, {
              name: values.name,
              instruction: values.instruction,
              examples: values.examples,
            })
          );
          toast.success(t(tokens.contentTemplates.create.form.successMessage));
          router.push(
            createRoute(paths.contentTemplates.details).link({
              contentTemplateId: contentTemplate.id,
            })
          );
        } catch (err) {
          console.error(err);
          toast.error(t(tokens.general.formError));
          helpers.setStatus({ success: false });
          //helpers.setErrors({ submit: err.Message });
          helpers.setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(255, t(tokens.general.validators.maxLength255) as string)
          .required(t(tokens.general.validators.required) as string),
        instruction: Yup.string().required(
          t(tokens.general.validators.required) as string
        ),
        examples: Yup.array().of(
          Yup.string().required(t(tokens.general.validators.required) as string)
        ),
      })}
    >
      {(props) => (
        <>
          <Form placeholder={""}>
            <Stack spacing={4}>
              <Card>
                <CardContent>
                  <Stack spacing={3}>
                    <TextField
                      error={!!(props.touched.name && props.errors.name)}
                      fullWidth
                      helperText={props.touched.name && props.errors.name}
                      label={t(tokens.contentTemplates.create.form.name)}
                      name="name"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.name}
                    />

                    <TextField
                      error={
                        !!(
                          props.touched.instruction && props.errors.instruction
                        )
                      }
                      fullWidth
                      multiline
                      minRows={5}
                      helperText={
                        props.touched.instruction && props.errors.instruction
                      }
                      label={t(tokens.contentTemplates.create.form.instruction)}
                      name="instruction"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.instruction}
                    />
                    <Stack spacing={2}>
                      <Typography
                        variant="h6"
                        sx={{ mb: 0 }}
                      >
                        {t(tokens.contentTemplates.create.form.examples)}
                      </Typography>

                      {props.values.examples.length === 0 && (
                        <Typography variant="body2">
                          {t(tokens.contentTemplates.create.form.noExamples)}
                        </Typography>
                      )}

                      <FieldArray
                        name="examples"
                        render={(arrayHelpers) => (
                          <>
                            {props.values.examples.map((example, index) => {
                              const item = `examples[${index}]`;
                              return (
                                <React.Fragment key={index}>
                                  <Stack
                                    spacing={3}
                                    direction={{
                                      xs: "column",
                                      sm: "row",
                                    }}
                                    alignItems="center"
                                    sx={{}}
                                  >
                                    <Box
                                      sx={{
                                        width: "100%",
                                      }}
                                    >
                                      <TextEditor
                                        showHeaderTool={true}
                                        content={example}
                                        onUpdate={(content) => {
                                          props.setFieldValue(
                                            item,
                                            content.editor.getHTML()
                                          );
                                        }}
                                        placeholder={t(
                                          tokens.general.placeholders.editor
                                        )}
                                      />
                                    </Box>
                                  </Stack>
                                  <Box>
                                    <Link
                                      color={"error"}
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                      variant="subtitle2"
                                    >
                                      {t(tokens.general.buttons.delete)}
                                    </Link>
                                  </Box>
                                </React.Fragment>
                              );
                            })}
                            <Divider />
                            <Box>
                              <Button
                                onClick={() => arrayHelpers.push("")}
                                variant="outlined"
                                color="primary"
                              >
                                {t(tokens.general.buttons.add)}
                              </Button>
                            </Box>
                          </>
                        )}
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
              <Stack
                alignItems="center"
                direction="row-reverse"
                justifyContent="flex-end"
                spacing={1}
              >
                <Button
                  href={paths.contentTemplates.list}
                  color="inherit"
                  component={RouterLink}
                >
                  {t(tokens.general.buttons.cancel)}
                </Button>
                <LoadingButton
                  type="submit"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.create)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </>
      )}
    </Formik>
  );
};
