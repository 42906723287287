import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { TargetAudienceResponse } from "src/types/target-audience";

interface TargetAudiencesState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: TargetAudienceResponse;
}

type GetTargetAudiencesAction = PayloadAction<TargetAudienceResponse>;
type TargetAudiencesLoadingStatusAction = PayloadAction<boolean>;
type TargetAudiencesErrorStatusAction = PayloadAction<boolean>;

const initialState: TargetAudiencesState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null,
  },
};

const reducers = {
  setLoadingStatus: (
    state: TargetAudiencesState,
    action: TargetAudiencesLoadingStatusAction
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: TargetAudiencesState,
    action: TargetAudiencesErrorStatusAction
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getTargetAudiences: (
    state: TargetAudiencesState,
    action: GetTargetAudiencesAction
  ): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: "targetAudiences",
  initialState,
  reducers,
});

export const { reducer } = slice;
