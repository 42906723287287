import { ChangeEvent, useCallback, useMemo, useState, type FC } from "react";
import { StrategyPartType } from "src/types/strategy";
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { GenerateAgain } from "../dashboard/common/generate-again";
import { useSelector } from "src/store";
import { StrategyName } from "./strategy-name";
import { StrategyExport } from "./strategy-export";
import { StrategyDelete } from "./strategy-delete";
import { StrategyPlan } from "./strategy-plan";
import { useStrategyPart } from "../dashboard/hooks/use-strategy-part";
import { StrategyContentTypes } from "./strategy-content-types";
import { useAuth } from "src/hooks/use-auth";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

export const StrategySettings: FC = () => {
  const { t } = useTranslation();

  const { item } = useSelector((state) => state.strategy);
  const part = useStrategyPart(StrategyPartType.StrategyName);
  const { account } = useAuth();

  const [currentTab, setCurrentTab] = useState<string>("general");

  const tabs = useMemo(
    () => [
      {
        label: t(tokens.strategySettings.tabs.general),
        value: "general",
      },
      {
        label: t(tokens.strategySettings.tabs.contentTypes),
        value: "contentTypes",
      },
    ],
    [t]
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<{}>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h4">
          {t(tokens.strategySettings.header)}
        </Typography>
        {part?.item?.isReady && account?.isHost && (
          <IconButton
            size="small"
            disabled={part?.item?.isGenerating}
            onClick={part.createPart}
          >
            <SvgIcon>
              <RefreshOutlinedIcon />
            </SvgIcon>
          </IconButton>
        )}
      </Stack>

      {!part?.item?.isGenerating && !part?.item?.isReady && (
        <GenerateAgain
          createPart={part.createPart}
          error={t(tokens.strategySettings.noStrategySettingsFound)}
          isGenerating={part?.item?.isGenerating ?? false}
        />
      )}
      {(part?.item?.isReady || part?.item?.isGenerating) && (
        <>
          {part?.item?.isReady && (
            <>
              <div>
                <Tabs
                  indicatorColor="primary"
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  textColor="primary"
                  value={currentTab}
                  variant="scrollable"
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
                <Divider />
              </div>
              {currentTab === "general" && (
                <Stack spacing={4}>
                  <StrategyName strategy={item!} />
                  <StrategyPlan strategy={item!} />
                  {/* <StrategyExport strategy={item!} /> */}
                  <StrategyDelete strategy={item!} />
                </Stack>
              )}
              {currentTab === "contentTypes" && (
                <Stack spacing={4}>
                  <StrategyContentTypes />
                </Stack>
              )}
            </>
          )}
          {part?.item?.isGenerating && (
            <>
              <Card>
                <CardContent>
                  {[...Array(5)].map((item, index) => (
                    <Skeleton />
                  ))}
                </CardContent>
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};
