import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ContentTemplateResponse } from "src/types/content-template";

interface ContentTemplatesState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: ContentTemplateResponse;
}

type GetContentTemplatesAction = PayloadAction<ContentTemplateResponse>;
type ContentTemplatesLoadingStatusAction = PayloadAction<boolean>;
type ContentTemplatesErrorStatusAction = PayloadAction<boolean>;

const initialState: ContentTemplatesState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null,
  },
};

const reducers = {
  setLoadingStatus: (
    state: ContentTemplatesState,
    action: ContentTemplatesLoadingStatusAction
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: ContentTemplatesState,
    action: ContentTemplatesErrorStatusAction
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getContentTemplates: (state: ContentTemplatesState, action: GetContentTemplatesAction): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: "contentTemplates",
  initialState,
  reducers,
});

export const { reducer } = slice;
