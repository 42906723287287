import React, { type FC, useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { paths } from "src/paths";
import { useTranslation } from "react-i18next";
import { useDispatch } from "src/store";
import { useRouter } from "src/hooks/use-router";
import { toast } from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { tokens } from "src/locales/tokens";
import { Form, Formik } from "formik";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { thunks as strategiesThunks } from "src/thunks/strategies";
import { thunks as contentTypesThunks } from "src/thunks/content-types";
import { Stack, Theme } from "@mui/system";
import { useParams } from "react-router";

export const WizardSummary: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [hasGeneratingError, setHasGeneratingError] = useState<boolean>(false);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { strategyId } = useParams();

  const router = useRouter();

  useEffect(() => {
    if (hasGeneratingError) {
      toast.error(t(tokens.general.formError));
    }
  }, [hasGeneratingError, t]);

  return (
    <>
      <Formik
        initialValues={{}}
        enableReinitialize={true}
        onSubmit={async (values, helpers): Promise<void> => {
          if (isGenerating) {
            return;
          }
          setIsGenerating(true);
          try {
            await dispatch(
              strategyThunks.finishStrategy(strategyId!, i18n.language)
            );
            await dispatch(strategyThunks.getStrategy(strategyId!));
            await dispatch(strategiesThunks.getStrategies());
            await dispatch(contentTypesThunks.getContentTypes(strategyId!));
            await new Promise((resolve) => setTimeout(resolve, 1000));

            router.push(paths.calendar);
            //router.push(paths.topics.list);
          } catch (err) {
            setHasGeneratingError(true);
            console.log(err);
          }
          setIsGenerating(false);
        }}
      >
        {(props) => (
          <Form placeholder={""}>
            <Stack
              direction={{
                xs: "column",
                sm: "row",
              }}
              alignItems={"center"}
              spacing={3}
              sx={{ mb: 4 }}
            >
              <img
                width={mdUp ? 200 : 150}
                height={mdUp ? 200 : 150}
                alt={"robot"}
                src={"/assets/robot.png"}
              />
              <Stack spacing={2}>
                <Typography variant="h3">
                  🏁 {t(tokens.wizard.summary.text)}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 2,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t(tokens.wizard.summary.tooltip),
                  }}
                ></Typography>
              </Stack>
            </Stack>

            <Box>
              <LoadingButton
                type="submit"
                size="large"
                loading={props.isSubmitting}
                variant="contained"
                sx={{
                  minWidth: 200,
                  height: 56,
                }}
              >
                {t(tokens.general.buttons.generateStrategy)}
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
