import { ResultError } from "./types/error";

export abstract class CONSTS {
  static readonly USER_KEY = "mil_user";
  static readonly IMPERSONATED_USER_KEY = "mil_impersonated_user";
  static readonly EXPORT_TOKEN_KEY = "mil_export_token";
  static readonly STRATEGY_ID_KEY = "mil_strategy_id";
}

export abstract class ERRORS {
  static readonly GENERAL_ERROR = {
    status: 501,
    message: "An error occurred. Please try again later.",
  } as ResultError;
  static readonly NOT_FOUND_ERROR = {
    status: 404,
    message: "Page not found error",
  } as ResultError;
  static readonly NOT_AUTHORIZED_ERROR = {
    status: 401,
    message: "Not authorized",
  } as ResultError;
}

export abstract class SECTION_KEYS {
  static readonly ABOUT_ME = "about_me";
  static readonly REASONS = "reasons";
  static readonly AREA = "area";
  static readonly SKILLS = "skills";
  static readonly PRODUCT_OR_SERVICE = "product_or_service";

  static readonly GOALS = "goals";
  static readonly PRODUCTS_OR_SERVICES = "products_or_services";

  static readonly CONTENT_VISION = "content_vision";
  static readonly POSITIONING = "positioning";
  static readonly SLOGAN = "slogan";
  static readonly VALUES = "values";
  static readonly MISSION = "mission";
  static readonly FEARS = "fears";
  static readonly STRENGTHS = "strengths";
  static readonly TARGET_AUDIENCE = "target_audience";
  static readonly EXPERT_CONTENT = "expert_content";
  static readonly PERSONAL_CONTENT = "personal_content";
}

export abstract class MODEL_NAMES {
  static readonly GPT4 = "gpt-4o";
  static readonly GPT3_5 = "gpt-3.5-turbo";
}

export abstract class NAME_LENGTH {
  static readonly SHORT = "short";
  static readonly MEDIUM = "medium";
  static readonly LONG = "long";
}

export abstract class STORY_LINES_KEYS {
  static readonly PRODUCTS = "Products";
  static readonly VALUES = "Values";
  static readonly FEEDBACKS = "Feedbacks";
  static readonly MYTHS = "Myths";
  static readonly ADDED_VALUE = "AddedValue";
  static readonly MY_LIFE = "MyLife";
}

export abstract class PROMPTS {
  static LANDING_PAGE = `You are an expert in making copy for landing pages.`;
  static TOPIC_CONTENT = `You are an expert in content writing for social networks.`;
}
