import * as React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';


import { Box, Container } from '@mui/material';
import { MainNav } from '../components/main-nav';
import { styled } from '@mui/system';

interface LayoutProps {
  children: React.ReactNode;
}

const LayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: 'url("/assets/gradient-bg.svg")',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100%'
  })
);

export function Layout({ children }: LayoutProps): React.JSX.Element {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{
          body: {
            '--MainNav-height': '72px',
            '--MainNav-zIndex': 1000,
            '--SideNav-width': '280px',
            '--SideNav-zIndex': 1100,
            '--MobileNav-width': '320px',
            '--MobileNav-zIndex': 1100,
          },
        }}
      />

      <MainNav />
      <LayoutRoot>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flex: '1 1 auto'
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              py: {
                xs: '80px',
                md: '120px'
              }
            }}
          >
            {children}
          </Container>
          {/* <Footer /> */}
        </Box>
      </LayoutRoot>

    </React.Fragment>
  );
}
