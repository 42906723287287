export interface GTMConfig {
  containerId?: string;
}

const warn = (...args: unknown[]) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  console.warn(...args);
};

class GTM {
  CONTAINER_ID?: string;

  initialized = false;

  configure(config: GTMConfig) {
    if (!config.containerId) {
      warn('GTM requires a GTM ID to be loaded.');
      return;
    }

    this.CONTAINER_ID = config.containerId;
  }

  initialize(config: GTMConfig) {
    if (this.initialized) {
      warn('GTM can only be initialized once.');
      return;
    }

    // Maybe you want to load events from server side (in NextJS apps for example),
    // those can be queued.
    // SSR queued events can be loaded in the initialize script.
    // For the moment we do not implement it, but in future we might add it.

    if (!document) {
      warn('GTM can be initialized only on client side.');
      return;
    }

    this.configure(config);

    if (!this.CONTAINER_ID) {
      return;
    }

    const script = document.createElement('script');
    const scriptFile = document.createElement('script');
    scriptFile.src = `https://www.googletagmanager.com/gtag/js?id=${this.CONTAINER_ID}`;
    

    script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', '${this.CONTAINER_ID}');
    `;
    
    document.head.insertBefore(scriptFile, document.head.childNodes[0]);
    document.head.insertBefore(script, document.head.childNodes[0]);
  }

  // eslint-disable-next-line class-methods-use-this
  push(...args: any[]) {
    if (!window) {
      warn('GTM push works only on client side.');
      return;
    }

    if (!(window as any).dataLayer) {
      (window as any).dataLayer = [];
    }

    (window as any).dataLayer.push(...args);
  }
}

// Singleton
export const gtm = new GTM();
