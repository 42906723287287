import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './translations/en';
import { uk } from './translations/uk';
//import { pl } from './translations/pl';
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({

    resources: {
      en: { translation: en },
      uk: { translation: uk },
      // pl: { translation: pl }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    supportedLngs: ['en', 'uk'],
  });
