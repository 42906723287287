import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import Upload01Icon from '@untitled-ui/icons-react/build/esm/Upload01';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { CircularProgress } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

export interface FileImage {
  id: string;
  fileName: string;
  height: number;
  width: number;
  originalUrl: string;
  resizedUrl: string;
}

export enum FilePickerScenario {
  Image,
  File
}

interface FileDropzoneProps extends DropzoneOptions {
  scenario?: FilePickerScenario;
  compact?: boolean;
  caption?: string;
  files?: any[];
  fileProcessing?: boolean;
  uploading?: boolean;
  onRemove?: (file: any) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
  children?: JSX.Element;
}

export const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { children, scenario = FilePickerScenario.Image, compact, caption, files = [], fileProcessing = false, uploading = false, onRemove, onRemoveAll, onUpload, ...other } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone(other);
  const hasAnyFiles = files.length > 0;

  const styles = compact ? {
    alignItems: 'center',
    borderColor: theme.palette.neutral[300],
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    outline: 'none',
    ...(
      isDragActive && {
        backgroundColor: 'action.active',
        opacity: 0.5
      }
    ),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5
    }
  } : {
    alignItems: 'center',
    border: 1,
    borderRadius: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.neutral[300],
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    outline: 'none',
    p: 6,
    ...(
      isDragActive && {
        backgroundColor: 'action.active',
        opacity: 0.5
      }
    ),
    '&:hover': {
      backgroundColor: 'action.hover',
      cursor: 'pointer',
      opacity: 0.5
    }
  };

  return (
    <>
      <Stack
        direction={compact ? "row" : "column"}
        gap={1}
      >
        {children && children}
        <Box
          sx={styles}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <Stack
              sx={{
                height: compact ? 50 : 64,
                width: compact ? 50 : 64,
              }}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >

              {fileProcessing
                ? <CircularProgress
                  sx={{
                    height: compact ? "30px !important" : 64,
                    width: compact ? "30px !important" : 64
                  }} />
                : compact
                  ? <SvgIcon fontSize="large"><CropOriginalIcon /></SvgIcon>
                  : <SvgIcon><Upload01Icon /></SvgIcon>
              }
            </Stack>
            {!compact &&
              <Stack spacing={1}>
                <Typography
                  sx={{
                    '& span': {
                      textDecoration: 'underline'
                    }
                  }}
                  variant="h6"
                >
                  <span>{t(tokens.general.filepicker.titleHighlight)}</span> {t(tokens.general.filepicker.titleNormal)}
                </Typography>
                {caption && (
                  <Typography
                    color="text.secondary"
                    variant="body2"
                  >
                    {caption}
                  </Typography>
                )}
              </Stack>
            }
          </Stack>
        </Box>
      </Stack>
      {scenario === FilePickerScenario.Image && hasAnyFiles && (
        <Box sx={{ mt: 2 }}>
          <List>
            {files.map((image, index) => {
              return (
                compact ?
                  <ListItemIcon
                    key={index}
                    sx={{ position: 'relative' }}
                  >
                    <Avatar
                      src={image.resizedUrl}
                      variant="square"
                      sx={{
                        height: 92,
                        width: 92
                      }}
                    />
                    <Tooltip title={t(tokens.general.filepicker.deleteImage)}>
                      <IconButton
                        edge="end"
                        onClick={() => onRemove?.(image)}
                        sx={{
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          marginRight: '0',
                          zIndex: '1',
                          padding: '0',
                          color: '#fff',
                          background: 'rgba(0,0,0, 0.6)'
                        }}
                      >
                        <SvgIcon>
                          <XIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </ListItemIcon>
                  :
                  <ListItem
                    key={index}
                    sx={{
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                      '& + &': {
                        mt: 1
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Avatar
                        src={image.resizedUrl}
                        sx={{
                          height: 64,
                          width: 64
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={image.fileName}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    // secondary={bytesToSize(file.size)}
                    />
                    <Tooltip title={t(tokens.general.filepicker.deleteImage)}>
                      <IconButton
                        edge="end"
                        onClick={() => onRemove?.(image)}
                      >
                        <SvgIcon>
                          <XIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </ListItem>
              );
            })}
          </List>
        </Box>
      )}
      {onUpload &&
        <Box>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Button
              onClick={onUpload}
              type="button"
              variant="contained"
              disabled={!hasAnyFiles || uploading}
            >
              {t(tokens.general.buttons.update)}
            </Button>
          </Stack>
        </Box>
      }
    </>
  );
};

FileDropzone.propTypes = {
  caption: PropTypes.string,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  // From Dropzone
  accept: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired),
  disabled: PropTypes.bool,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  preventDropOnDocument: PropTypes.bool
};
