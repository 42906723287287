import type { FC } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { ContentTemplate } from "src/types/content-template";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";

interface ContentTemplateDetailsProps {
  contentTemplate: ContentTemplate;
}

export const ContentTemplateDetails: FC<ContentTemplateDetailsProps> = (
  props
) => {
  const { contentTemplate } = props;
  const { t } = useTranslation();
  return (
    <Stack spacing={3}>
      <Card>
        <CardContent>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography variant="h4">{contentTemplate.name}</Typography>
          </Stack>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: contentTemplate.instruction.replaceAll("\n", "<br />"),
            }}
          ></Typography>
        </CardContent>
      </Card>

      <Typography variant="h6">
        {t(tokens.contentTemplates.details.form.examples)}
      </Typography>
      {contentTemplate.examples.length === 0 && (
        <Card>
          <CardContent>
            {t(tokens.contentTemplates.details.form.noExamples)}
          </CardContent>
        </Card>
      )}
      {contentTemplate.examples.map((example, index) => (
        <Card key={index}>
          <CardContent>
            <Stack
              key={index}
              spacing={2}
            >
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: example }}
              ></Typography>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};
