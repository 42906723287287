import { type FC } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { TargetAudience } from "src/types/target-audience";
import { ItemMenu } from "src/sections/common/item-menu";
import { RouterLink } from "src/components/core/link";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";

interface TargetAudienceListItemProps {
  item?: TargetAudience;
  onDelete?: () => void;
}

export const TargetAudienceListItem: FC<TargetAudienceListItemProps> = (
  props
) => {
  const { item, onDelete } = props;

  return (
    <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
        component={RouterLink}
        href={
          item?.id
            ? createRoute(paths.targetAudiences.details).link({
                targetAudienceId: item!.id,
              })
            : ""
        }
      >
        <CardMedia
          image={"/assets/image-abstract-1.png"}
          sx={{ height: "100px", width: "100%" }}
        >
          <Stack
            sx={{ mt: 1, mr: 1 }}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
          >
            <ItemMenu onDelete={onDelete} />
          </Stack>
        </CardMedia>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "-50px",
          }}
        >
          {!item && (
            <Skeleton
              width={100}
              height={100}
              variant="circular"
            />
          )}
          {item && (
            <Avatar
              src={item?.imageUrl ?? ""}
              alt="Type"
              sx={{
                "--Avatar-size": "100px",
                border: "3px solid var(--mui-palette-background-paper)",
              }}
            />
          )}
        </Box>

        <CardContent
          sx={{
            mt: 0,
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            {item && (
              <Typography
                color="text.primary"
                variant="h5"
              >
                {item && <>{item?.name}</>}
              </Typography>
            )}
            {!item && (
              <>
                <Skeleton height={30} />
              </>
            )}
            {item && (
              <Typography
                color="text.secondary"
                variant="body2"
              >
                {item && <>{item?.description}</>}
              </Typography>
            )}
            {!item && (
              <>
                <Box>
                  <Skeleton />
                  <Skeleton />
                </Box>
              </>
            )}
            {/* <Box>
              {item && (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={getContentGoalType(item?.contentGoalType ?? 0)}
                />
              )}
              {!item && (
                <>
                  <Skeleton width={100} />
                </>
              )}
            </Box> */}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
