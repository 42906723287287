import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { StrategyList } from "src/types/strategies";

interface StrategiesState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: StrategyList[];
}

type GetStrategiesAction = PayloadAction<StrategyList[]>;
type UpdateStrategyAction = PayloadAction<StrategyList>;
type StrategiesLoadingStatusAction = PayloadAction<boolean>;
type StrategiesErrorStatusAction = PayloadAction<boolean>;
type DeleteStrategyAction = PayloadAction<string>;

const initialState: StrategiesState = {
  isLoading: false,
  hasLoadingError: false,
  items: [],
};

const reducers = {
  setLoadingStatus: (
    state: StrategiesState,
    action: StrategiesLoadingStatusAction
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: StrategiesState,
    action: StrategiesErrorStatusAction
  ): void => {
    state.hasLoadingError = action.payload;
  },
  updateStrategyName: (
    state: StrategiesState,
    action: UpdateStrategyAction
  ): void => {
    const strategy = state.items.find((x) => x.id === action.payload.id);
    if (strategy) {
      strategy.name = action.payload.name;
    }
  },
  getStrategies: (
    state: StrategiesState,
    action: GetStrategiesAction
  ): void => {
    state.items = action.payload;
  },
  deleteStrategy: (
    state: StrategiesState,
    action: DeleteStrategyAction
  ): void => {
    state.items = state.items.filter(
      (strategy) => strategy.id !== action.payload
    );
  },
};

export const slice = createSlice({
  name: "strategies",
  initialState,
  reducers,
});

export const { reducer } = slice;
