import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { IssuerGuard } from 'src/guards/issuer-guard';
import { GuestGuard } from 'src/guards/guest-guard';
import { Layout as AuthLayout } from 'src/layouts/auth/layout';
import { Issuer } from 'src/utils/auth';

// JWT
const JwtLoginPage = lazy(() => import('src/pages/account/sign-in'));
const EmailSentPage = lazy(() => import('src/pages/account/email-sent'));
const ConfirmSignIn = lazy(() => import('src/pages/account/confirm-signin'));

export const authRoutes: RouteObject[] = [
  {
    path: 'account',
    children: [
      {
        children: [
          {
            element: (
              <IssuerGuard issuer={Issuer.JWT}>
                <GuestGuard>
                  <AuthLayout>
                    <Outlet />
                  </AuthLayout>
                </GuestGuard>
              </IssuerGuard>
            ),
            children: [
              {
                path: 'sign-in',
                element: <JwtLoginPage />
              },
            ]
          },
          {
            path: 'email-sent',
            element: <AuthLayout>
              <EmailSentPage />
            </AuthLayout>
          },
          {
            path: 'confirm-signin',
            element: <AuthLayout><ConfirmSignIn /></AuthLayout>
          },
        ]
      },
    ]
  }
];
