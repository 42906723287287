import { useCallback, useEffect } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import { Box, Button, Stack, SvgIcon } from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
// import { AccountBillingSettings } from 'src/sections/dashboard/account/account-billing-settings';
// import { AccountNotificationsSettings } from 'src/sections/dashboard/account/account-notifications-settings';
// import { AccountTeamSettings } from 'src/sections/dashboard/account/account-team-settings';
// import { AccountSecuritySettings } from 'src/sections/dashboard/account/account-security-settings';
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks as topicThunks } from "src/thunks/topic";
import { useParams } from "react-router";
import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import NotFound from "src/sections/errors/not-found";
import { ERRORS } from "src/consts";
import ServerError from "src/sections/errors/server-error";
import { TopicEditForm } from "src/sections/dashboard/topics/topic-edit-form";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { topicId } = useParams();

  const {
    item: topic,
    error: topicError,
    //isLoading: isTopicLoading,
  } = useSelector((state) => state.topic);
  const { strategyId } = useSelector((state) => state.settings);

  const loadData = useCallback(async () => {
    if (topicId) {
      await dispatch(topicThunks.getTopic(strategyId!, topicId));
    }
  }, [dispatch, topicId, strategyId]);

  useEffect(() => {
    loadData();

    return () => {
      dispatch(topicThunks.clearTopic());
    };
  }, [topicId, loadData, dispatch]);

  if (topicError) {
    if (topicError.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!topicId || !topic) {
    return;
  }

  return (
    <>
      <Seo title={topic.name} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Button
              component={RouterLink}
              href={paths.topics.list}
              startIcon={
                <SvgIcon>
                  <ArrowLeftIcon />
                </SvgIcon>
              }
              size="small"
              color="inherit"
              variant="text"
            >
              {t(tokens.topics.details.backButton)}
            </Button>
          </Stack>
          <TopicEditForm topic={topic} />
        </Stack>
      </Box>
    </>
  );
};

export default Page;
