import { Box, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { thunks } from "src/thunks/payment";
import { LoadIndicator } from "src/sections/common/load-indicator";
import type { Page as PageType } from "src/types/page";
import { useTranslation } from "react-i18next";
import { useSelector } from "src/store";
import { PlanType } from "src/types/strategy";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { tokens } from "src/locales/tokens";

interface KeyValue {
  key: string;
  value: any;
}

const Page: PageType = () => {
  usePageView();

  const { i18n } = useTranslation();
  const { item } = useSelector((state) => state.strategy);

  const [searchParams] = useSearchParams();
  const planType = searchParams.get("planType");
  const productName = searchParams.get("productName");

  const [data, setData] = useState<any | undefined>(undefined);

  const fetchData = useCallback(async () => {
    if (item && planType && productName) {
      const type = PlanType[planType as keyof typeof PlanType];
      try {
        var configuration = await thunks.getConfiguration(
          item.id,
          productName,
          type,
          i18n.language
        );
        setData(configuration);
      } catch (error) {
        toast.error(tokens.general.formError);
      }
    }
  }, [i18n.language, item, planType, productName]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        const form = document.querySelector("#paymentForm") as HTMLFormElement;
        form?.submit();
      }, 1000);
    }
    return () => {
      console.log("unmount");
    };
  }, [data]);

  const getProperties = useCallback(() => {
    if (data) {
      return Object.keys(data).map((key) => {
        return {
          key: key,
          value: data[key],
        };
      }) as KeyValue[];
    }
  }, [data]);

  return (
    <>
      <Seo title="Payment" />

      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <div>
          <Card>
            <CardContent>
              <LoadIndicator />
            </CardContent>
          </Card>
          <form
            id="paymentForm"
            method="POST"
            action="https://secure.wayforpay.com/pay"
          >
            {getProperties()?.map((x, index) => {
              return (
                <input
                  key={index}
                  type="hidden"
                  name={x.key}
                  value={x.value}
                />
              );
            })}
          </form>
        </div>
      </Box>
    </>
  );
};

export default Page;
