"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Editor, EditorContent, posToDOMRect, useEditor } from "@tiptap/react";
import { EditorOptions, Extension, BubbleMenu } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";

import { TextEditorToolbar } from "./text-editor-toolbar";
import { TextBubbleEditorToolbar } from "./text-bubble-editor-toolbar";
import { Card, CardContent, Popover, PopoverProps, Stack } from "@mui/material";
import { AiTextField } from "./ai-text-field";
import { usePopover } from "src/hooks/use-popover";

export interface TextEditorProps {
  content: EditorOptions["content"];
  editable?: EditorOptions["editable"];
  hideToolbar?: boolean;
  showHeaderTool: boolean;
  onUpdate?: EditorOptions["onUpdate"];
  placeholder?: string;
  ref?: Editor;
  customButtons?: React.ReactNode[];
}

export type TextEditorPropsHandle = {
  handleSubmit: () => void;
};

export const TextEditor = React.forwardRef<Editor, TextEditorProps>(
  (props, ref) => {
    const {
      content,
      editable = true,
      hideToolbar,
      customButtons,
      showHeaderTool = true,
      onUpdate = () => {
        // noop
      },
      placeholder,
    } = props;

    const extensions = [
      StarterKit,
      Markdown,
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        placeholder,
      }),
      Link.configure({ openOnClick: false, autolink: true }),
    ] as Extension[];

    const editor = useEditor({ extensions, content, editable, onUpdate });
    //ref.current = editor;

    React.useImperativeHandle(ref, () => editor!);

    const [showAskAI, setShowAskAI] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] =
      React.useState<PopoverProps["anchorEl"]>(null);

    const openref = React.useRef<boolean>(false);

    return (
      <Box
        className="tiptap-root"
        sx={{
          display: "flex",
          flexDirection: "column",
          ...(editable && {
            border: "1px solid var(--mui-palette-divider)",
            borderRadius: 1,
            boxShadow: "var(--mui-shadows-1)",
          }),
          "& .tiptap-container": {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            minHeight: 0,
          },
          "& .tiptap": {
            color: "var(--mui-palette-text-primary)",
            flex: "1 1 auto",
            overflow: "auto",
            p: "8px 16px",
            "&:focus-visible": { outline: "none" },
            "&.resize-cursor": {
              cursor: "ew-resize",
              "& table": { cursor: "col-resize" },
            },
            "& .is-editor-empty:before": {
              color: "var(--mui-palette-text-secondary)",
              content: "attr(data-placeholder)",
              float: "left",
              height: 0,
              pointerEvents: "none",
            },
          },
        }}
      >
        {!hideToolbar ? (
          <TextEditorToolbar
            editor={editor}
            customButtons={customButtons ?? []}
            showHeaderTool={showHeaderTool}
          />
        ) : (
          <div />
        )}

        {editor &&  (
          <BubbleMenu
            editor={editor}
            
            shouldShow={({ editor, from, to }) => {
              console.log(openref.current);

              if (openref.current) {
                return false;
              }

              const isEmptySelection = from === to;
              return !isEmptySelection;
            }}
            tippyOptions={{
              duration: 100,

              maxWidth: 700,
              onHide: () => setShowAskAI(false),
            }}
          >
            {showAskAI ? (
              <>
                <Stack
                  className="tiptap-toolbar"
                  spacing={1}
                  sx={{
                    border: "1px solid var(--mui-palette-divider)",
                    borderRadius: 1,
                    p: "8px",
                    minHeight: "57px",
                    backgroundColor: "var(--mui-palette-background-paper)",
                  }}
                >
                  <AiTextField
                    editor={editor}
                    onInsert={(text) => {
                      const { state, dispatch } = editor.view;
                      const { selection, tr } = state;

                      if (selection.empty) {
                        return false;
                      }
                      editor.commands.deleteSelection();
                      editor.chain().focus();
                      editor.commands.insertContent(text);
                      //handleClose();

                      setShowAskAI(false);
                      //editor.commands.blur();
                    }}
                  />
                </Stack>
              </>
            ) : (
              <TextBubbleEditorToolbar
                editor={editor}
                onAskAI={() => {
                  // var selection = editor.view.state.selection;

                  // // posToDOMRect();
                  // //                   if (
                  // //                     !selection ||
                  // //                     selection.anchorOffset === selection.focusOffset
                  // //                   ) {
                  // //                     return;
                  // //                   }

                  // //                   const getBoundingClientRect = () => {
                  // //                     return selection.getRangeAt(0).getBoundingClientRect();
                  // //                   };
                  // debugger;
                  // const from = selection.$from.posAtIndex(0);
                  // const to = selection.$from.posAtIndex(1);

                  // const node = editor.view.nodeDOM(from) as HTMLElement;

                  // const rect = node.getBoundingClientRect();

                  const selection = window.getSelection();

                  // Skip if selection has a length of 0
                  if (
                    !selection ||
                    selection.anchorOffset === selection.focusOffset
                  ) {
                    return;
                  }

                  const getBoundingClientRect = () => {
                    return selection.getRangeAt(0).getBoundingClientRect();
                  };

                  // var element = editor.view.state.selection
                  //   .content()
                  //   .content.lastChild?.child(0)..getBoundingClientRect();
                  openref.current = true;
                  setOpen(true);

                  setAnchorEl({ getBoundingClientRect, nodeType: 1 });
                  //editor.chain().focus().setParagraph().run();
                  //setShowAskAI(!showAskAI);
                }}
                showHeaderTool={showHeaderTool}
              />
            )}
          </BubbleMenu>
        )}
        <EditorContent
          className="tiptap-container"
          editor={editor}
        />

        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={() => {
            setOpen(false);
            openref.current = false;
          }}
          open={open}
          slotProps={{ paper: { sx: { p: 2 } } }}
        >
          AI POPOVER
        </Popover>
      </Box>
    );
  }
);
