import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as strategySlice } from "src/slices/strategy";
import { slice as strategiesSlice } from "src/slices/strategies";
import { StrategyPartType } from "src/types/strategy";

const createStrategyPart =
  (strategyId: string, type: StrategyPartType, lang: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      await fetchWrapper.post(
        `${api}/strategies/${strategyId}/parts`,
        { type: type },
        undefined,
        lang
      );
      dispatch(
        strategySlice.actions.getStrategyPart({
          isGenerating: true,
          isReady: false,
          hasError: false,
          partType: type,
        })
      );
    } catch (error) {
      dispatch(
        strategySlice.actions.getStrategyPart({
          isGenerating: false,
          isReady: false,
          hasError: true,
          partType: type,
        })
      );
    }
  };

const getStrategyParts =
  (strategyId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    var parts = await fetchWrapper.get(
      `${api}/strategies/${strategyId}/parts`,
      {}
    );
    dispatch(strategySlice.actions.getStrategyParts(parts));
    return parts;
  };

const getStrategyPart =
  (strategyId: string, partType: StrategyPartType): AppThunk =>
  async (dispatch): Promise<void> => {
    var part = await fetchWrapper.get(
      `${api}/strategies/${strategyId}/parts/${partType}`,
      {}
    );
    dispatch(strategySlice.actions.getStrategyPart(part));
    return part;
  };

const getStrategy =
  (strategyId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    var strategy = await fetchWrapper.get(
      `${api}/strategies/${strategyId}`,
      {}
    );
    dispatch(strategySlice.actions.getStrategy(strategy));
  };

const finishStrategy =
  (strategyId: string, lang: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.put(
      `${api}/strategies/${strategyId}/finish`,
      {},
      undefined,
      lang
    );
    dispatch(strategySlice.actions.finishStrategy());
  };

const updateStrategy =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var item = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/`,
      value
    );
    dispatch(strategySlice.actions.getStrategy(item));
    dispatch(
      strategiesSlice.actions.updateStrategyName({
        id: item.id,
        name: item.name,
        createdDate: item.createdDate,
        isComplete: item.isComplete,
      })
    );

    return item;
  };

const deleteStrategy =
  (strategyId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(`${api}/strategies/${strategyId}/`, {});
    dispatch(strategiesSlice.actions.deleteStrategy(strategyId));
    dispatch(strategySlice.actions.getStrategy(undefined));
  };

export const thunks = {
  getStrategy,
  createStrategyPart,
  getStrategyParts,
  getStrategyPart,
  finishStrategy,
  updateStrategy,
  deleteStrategy,
};
