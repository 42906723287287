import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Domain } from 'src/types/domain';


interface DomainState {
  isLoading: boolean;
  hasLoadingError: boolean;
  generatedDomains: Domain[];
  savedDomains: Domain[];
}

type GenerateDomainsAction = PayloadAction<Domain[]>;
type LoadDomainsAction = PayloadAction<Domain[]>;
type SaveDomainAction = PayloadAction<Domain>;
type RemoveDomainAction = PayloadAction<Domain>;
type DomainLoadingStatusAction = PayloadAction<boolean>;
type DomainErrorStatusAction = PayloadAction<boolean>;

const initialState: DomainState = {
  isLoading: false,
  hasLoadingError: false,
  generatedDomains: [],
  savedDomains: []
};

const reducers = {
  setLoadingStatus: (state: DomainState, action: DomainLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: DomainState, action: DomainErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  generateDomains: (state: DomainState, action: GenerateDomainsAction): void => {
    state.generatedDomains = action.payload;
  },
  loadDomain: (state: DomainState, action: LoadDomainsAction): void => {
    state.savedDomains = action.payload;
  },
  saveDomain: (state: DomainState, action: SaveDomainAction): void => {
    state.savedDomains.push(action.payload);
  },
  removeDomain: (state: DomainState, action: RemoveDomainAction): void => {
    state.savedDomains = state.savedDomains.filter(domain => domain.name !== action.payload.name);
  },
};

export const slice = createSlice({
  name: 'domain',
  initialState,
  reducers
});

export const { reducer } = slice;
