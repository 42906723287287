export enum PaymentStatus {
  unknown,

  pending,

  approved,

  declined,

  refunded,
}

export interface PaymentOptions {
  basic: PlanPrice;

  professional: PlanPrice;
}

export interface PlanPrice {
  currency: string;
  price: number;
}
