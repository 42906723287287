import { ukUA as coreUkUA } from "@mui/material/locale";
import { ukUA } from "@mui/x-date-pickers/locales";
import { enUS as coreEnUS } from "@mui/material/locale";
import { enUS } from "@mui/x-date-pickers/locales";
//import { plPL as corePlPl } from "@mui/material/locale";
//import { plPL } from "@mui/x-date-pickers/locales";

export type Language = "en" | "uk" /* | 'pl'*/;

export const languages: Record<Language, string> = {
  en: "/assets/flags/flag-uk.svg",
  uk: "/assets/flags/flag-ua.svg",
  //pl: '/assets/flags/flag-pl.svg'
};

export const dateFormatLocales: Record<Language, string> = {
  en: "MM/dd/yyyy",
  uk: "dd.MM.yyyy",
  //pl: 'dd.MM.yyyy'
};

export const uiLocales: Record<Language, object> = {
  en: enUS,
  uk: ukUA,
  //pl: plPL
};

export const datePickersUILocales: Record<Language, object> = {
  en: coreEnUS,
  uk: coreUkUA,
  //pl: corePlPl
};

export type LanguageOptions = {
  [key in Language]: {
    icon: string;
    label: string;
  };
};

export const languageOptions: LanguageOptions = {
  en: {
    icon: "/assets/flags/flag-uk.svg",
    label: "English",
  },
  uk: {
    icon: "/assets/flags/flag-ua.svg",
    label: "Українська",
  },
  // pl: {
  //     icon: '/assets/flags/flag-pl.svg',
  //     label: 'Polska'
  // }
};
