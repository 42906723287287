import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as topicsSlice } from "src/slices/lead-magnets";
import { GetLeadMagnetsQueryParams } from "src/types/lead-magnet";
import { urlParams } from "src/utils/url-params";
import { GeneratedLeadMagnet } from "src/types/lead-magnet";
import { parse } from "partial-json";
import { CancelTokenSource } from "axios";

const getLeadMagnets =
  (strategyId: string, params: GetLeadMagnetsQueryParams): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicsSlice.actions.setLoadingStatus(true));
    dispatch(topicsSlice.actions.setErrorStatus(false));
    try {
      var topic = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/lead-magnets${urlParams(params)}`,
        {}
      );
      dispatch(topicsSlice.actions.getLeadMagnets(topic));
    } catch (error) {
      dispatch(topicsSlice.actions.setErrorStatus(true));
    }
    dispatch(topicsSlice.actions.setLoadingStatus(false));
  };

const generateLeadMagnets =
  (
    strategyId: string,
    lang: string,
    data: any,
    onProgress: (topics: GeneratedLeadMagnet[]) => void,
    onError: () => void,
    cancelToken?: CancelTokenSource
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      return await fetchWrapper
        .post(
          `${api}/strategies/${strategyId}/lead-magnets/generate`,
          data,
          cancelToken,
          lang,
          "stream",
          (progressEvent: any) => {
            try {
              var dataChunk = progressEvent.event.currentTarget.response;

              dataChunk = dataChunk.replace("```json", "");
              dataChunk = dataChunk.replace("json", "");
              dataChunk = dataChunk.replace("```", "");
              dataChunk = dataChunk.replace("```", "");

              if (!dataChunk.endsWith("]")) {
                dataChunk += "]";
              }

              var json = JSON.parse(dataChunk) as [];
              var str = "";
              for (let i = 0; i < json.length; i++) {
                str += (json[i] as any).value;
              }

              var output = parse(str);
              onProgress(output as GeneratedLeadMagnet[]);
            } catch (e) {
              console.log(e);
            }
          }
        )
        .catch(() => {
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const thunks = {
  generateLeadMagnets,
  getLeadMagnets,
};
