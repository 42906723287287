import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useSearchParams } from "src/hooks/use-search-params";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import type { Page as PageType } from "src/types/page";
import { PaymentStatus } from "src/types/payment";
import { PlanType } from "src/types/strategy";

const Page: PageType = () => {
  usePageView();
  const { t } = useTranslation();

  const searchParams = useSearchParams();
  const email = searchParams.get("email") ?? "";
  const planType = searchParams.get("planType") ?? "";
  const status = searchParams.get("status") ?? "unknown";
  const orderReference = searchParams.get("orderReference") ?? "";

  const paymentStatus =
    PaymentStatus[status.toLowerCase() as keyof typeof PaymentStatus];

  const getStatus = useCallback(() => {
    switch (paymentStatus) {
      case PaymentStatus.approved:
        return t(tokens.payment.statuses.approved);
      case PaymentStatus.pending:
        return t(tokens.payment.statuses.pending);
      case PaymentStatus.declined:
        return t(tokens.payment.statuses.declined);
      default:
        return t(tokens.payment.statuses.unknown);
    }
  }, [paymentStatus, t]);

  const getStatusMessage = useCallback(() => {
    switch (paymentStatus) {
      case PaymentStatus.approved:
        return t(tokens.payment.success.messages.approved);
      case PaymentStatus.pending:
        return t(tokens.payment.success.messages.pending);
      case PaymentStatus.declined:
        return t(tokens.payment.success.messages.declined);
      default:
        return t(tokens.payment.success.messages.unknown);
    }
  }, [paymentStatus, t]);

  const getPlanName = useCallback(() => {
    var plan: PlanType = PlanType[planType as keyof typeof PlanType];

    switch (plan) {
      case PlanType.Strategy:
        return t(tokens.general.plans.basic);
      case PlanType.ContentPlan:
        return t(tokens.general.plans.professional);
      default:
        return t(tokens.general.plans.free);
    }
  }, [planType, t]);

  return (
    <>
      <Seo title={t(tokens.payment.success.title) as string} />
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold" }}
              >
                {t(tokens.payment.success.header)}
              </Typography>
              <Typography variant="body1">
                <p>
                  {t(tokens.payment.success.product)}:{" "}
                  <strong>{getPlanName()}</strong>
                </p>
                <p>
                  {t(tokens.payment.success.orderReference)}:{" "}
                  <strong>{orderReference}</strong>
                </p>
                <p>
                  {t(tokens.payment.success.email)}: <strong>{email}</strong>
                </p>
                <p>
                  {t(tokens.payment.success.status)}:{" "}
                  <strong>{getStatus()}</strong>
                </p>
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold" }}
              >
                {getStatusMessage()}
              </Typography>
              {paymentStatus === PaymentStatus.approved && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <Button
                    component={RouterLink}
                    variant="contained"
                    href={paths.calendar}
                    // href={paths.topics.list}
                  >
                    {t(tokens.payment.success.openDashboardButton)}
                  </Button>
                </Box>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Page;
