import React, { useState, type FC, useCallback } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/target-audience";
import { toast } from "react-hot-toast";
import { TargetAudience } from "src/types/target-audience";
import { ErrorMessage } from "src/sections/common/error-message";
import Grid from "@mui/material/Unstable_Grid2";
import { TargetAudienceListItem } from "./target-audience-list-item";
import { useRouter } from "src/hooks/use-router";

interface TargetAudienceListProps {
  items: TargetAudience[] | [];
  onReset: () => void;
  isLoading: boolean;
  hasError: boolean;
}

export const TargetAudienceList: FC<TargetAudienceListProps> = (props) => {
  const { t } = useTranslation();
  const { items = [], onReset = () => {}, isLoading, hasError } = props;
  const router = useRouter();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [targetAudienceId, setTargetAudienceId] = useState("");

  const { strategyId } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const handleCloseDeleteDialog = useCallback(() => {
    setTargetAudienceId("");
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenDeleteDialog = useCallback((id: string) => {
    setTargetAudienceId(id);
    setOpenDeleteDialog(true);
  }, []);

  const deleteTargetAudience = useCallback(async () => {
    try {
      if (targetAudienceId && strategyId) {
        handleCloseDeleteDialog();
        await dispatch(
          thunks.deleteTargetAudience(strategyId, targetAudienceId)
        );
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [
    dispatch,
    handleCloseDeleteDialog,
    onReset,
    targetAudienceId,
    strategyId,
    t,
  ]);

  if (hasError) {
    return (
      <Stack
        sx={{ position: "relative" }}
        spacing={3}
      >
        <Card>
          <CardContent>
            <ErrorMessage
              text={t(tokens.targetAudiences.list.errorLoadingTargetAudiences)}
            />
          </CardContent>
        </Card>
      </Stack>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Grid
        container
        spacing={3}
      >
        {isLoading && (
          <>
            {[0, 1, 2].map((storyline, index) => (
              <Grid
                key={index}
                md={4}
                sm={6}
                xs={12}
              >
                <TargetAudienceListItem />
              </Grid>
            ))}
          </>
        )}
        {!isLoading && (
          <>
            {items?.map((targetAudience) => (
              <Grid
                key={targetAudience.id}
                md={4}
                sm={6}
                xs={12}
              >
                <TargetAudienceListItem
                  item={targetAudience}
                  onDelete={() => {
                    handleOpenDeleteDialog(targetAudience.id);
                  }}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.targetAudiences.list.deleteTargetAudienceDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              tokens.targetAudiences.list.deleteTargetAudienceDialogDescription
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteTargetAudience}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleCloseDeleteDialog}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
