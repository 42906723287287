import React, { FC, useCallback, useEffect } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { Topic } from "src/types/topic";
import { TopicFilters, TopicListFilters } from "../topics/topic-list-filters";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/topics";
import LoadingButton from "@mui/lab/LoadingButton";
import { ErrorMessage } from "src/sections/common/error-message";
import { LoadIndicator } from "src/sections/common/load-indicator";
import { EmptyMessage } from "src/sections/common/empty-message";
import moment from "moment";

interface SelectTopicDrawerProps {
  open: boolean;
  onClose: () => void;
  onSelect: (topic: Topic) => void;
}

export const SelectTopicDrawer: FC<SelectTopicDrawerProps> = (props) => {
  const { open, onClose, onSelect } = props;
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      // hideBackdrop
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={onClose}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          width: "100%",
          maxWidth: 700,
        },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          pl: 3,
          pr: 3,
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="h6">
          {t(tokens.calendar.selectTopic.pageHeader)}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <IconButton
            size="small"
            color="inherit"
            onClick={() => {
              onClose();
            }}
          >
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      {open && <DrawerContent onSelect={onSelect} />}
    </Drawer>
  );
};

interface DrawerConentProps {
  onSelect: (topic: Topic) => void;
}

const DrawerContent: FC<DrawerConentProps> = (props) => {
  const { onSelect } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { strategyId } = useSelector((state) => state.settings);

  const {
    response: items,
    hasLoadingError,
    isLoading,
  } = useSelector((state) => state.topics);

  const [filters, setFilters] = React.useState<TopicFilters>({
    text: "",
    storylineCategory: null,
    storyline: null,
  });

  const loadTopics = useCallback(
    async (page: number) => {
      await dispatch(
        thunks.getTopics(strategyId!, {
          top: 10,
          page: page,
          //lastItemDateTime: lastItemDateTime ?? "",
          text: filters.text ?? "",
          storyLineCategoryId: filters.storylineCategory?.id ?? "",
          storyLineId: filters.storyline?.id ?? "",
        })
      );
    },
    [
      dispatch,
      filters.storylineCategory?.id,
      filters.storyline?.id,
      filters.text,
      strategyId,
    ]
  );

  useEffect(() => {
    loadTopics(1);
  }, [loadTopics]);

  useEffect(() => {
    return () => {
      dispatch(thunks.clearTopics());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        display: "inline-block",
        height: "100%",
        overflow: "auto",
        paddingBottom: 1,
      }}
    >
      <Box
        sx={{
          p: 3,
        }}
      >
        <TopicListFilters
          showVerticalFilters={true}
          onFiltersChange={(filters) => {
            setFilters(filters);
          }}
        />

        <Divider
          sx={{
            mt: 2,
            mb: 2,
          }}
        />

        <Stack spacing={2}>
          {!isLoading && !hasLoadingError && items.items.length === 0 && (
            <EmptyMessage text={t(tokens.calendar.selectTopic.noTopicsFound)} />
          )}

          {isLoading && items.items.length === 0 && (
            <>
              <LoadIndicator />
            </>
          )}

          {hasLoadingError && !isLoading && (
            <ErrorMessage
              text={t(tokens.calendar.selectTopic.errorLoadingTopics)}
            />
          )}
          {!hasLoadingError && (
            <>
              {items.items.length > 0 && (
                <Card>
                  <List disablePadding>
                    {items.items.map((topic, index) => (
                      <TopicItem
                        key={topic.id}
                        topic={topic}
                        showDivider={index < items.items.length - 1}
                        onSelect={(topic) => {
                          onSelect(topic);
                        }}
                      />
                    ))}
                  </List>
                </Card>
              )}
              {items.items.length > 0 &&
                items.totalCount > items.items.length && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <LoadingButton
                      loading={isLoading}
                      variant="outlined"
                      disabled={isLoading}
                      onClick={() => {
                        loadTopics(
                          items.page + 1
                          //items.items.length > 0
                          //</Box>? items.items[items.items.length - 1].createdDate
                          //: undefined
                        );
                      }}
                    >
                      {t(tokens.general.buttons.loadMore)}
                    </LoadingButton>
                  </Box>
                )}
            </>
          )}
        </Stack>
      </Box>
    </div>
  );
};

interface TopicItemProps {
  topic: Topic;
  onSelect?: (topic: Topic) => void;
  showDivider?: boolean;
}

export const TopicItem: FC<TopicItemProps> = (props) => {
  const { topic, onSelect, showDivider } = props;
  const { t } = useTranslation();
  return (
    <ListItem
      divider={showDivider}
      alignItems="center"
      sx={{ p: 2 }}
    >
      <ListItemText
        disableTypography
        primary={<Typography variant="subtitle1">{topic.name}</Typography>}
        secondary={
          <Typography
            variant="body2"
            fontSize={"0.8rem"}
          >
            {topic.storyLine ? topic.storyLine.name : ""}

            {topic.storyLineCategory && topic.storyLine
              ? " - " + topic.storyLineCategory.name
              : topic.storyLineCategory
                ? topic.storyLineCategory.name
                : ""}
          </Typography>
        }
      />
      {!topic.date && (
        <LoadingButton
          onClick={() => {
            onSelect?.(topic);
          }}
          type="submit"
          variant="outlined"
          size="small"
        >
          {t(tokens.general.buttons.select)}
        </LoadingButton>
      )}
      {topic.date && (
        <>
          <Stack
            spacing={2}
            direction={"column"}
          >
            <Chip
              variant="outlined"
              color="secondary"
              label={moment(topic.date).format("LL")}
            />
          </Stack>
        </>
      )}
    </ListItem>
  );
};
