import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import ChatBot, { Flow } from "react-chatbotify";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDispatch, useSelector } from "src/store";
import { ContentTemplate } from "src/types/content-template";
import { thunks as contentTemplatesThunks } from "src/thunks/content-templates";
import { debounce } from "lodash";

interface TemplateDrawerProps {
  open: boolean;
  onClose: () => void;
  onReset: () => void;
  onSubmit: (
    template: ContentTemplate | undefined,
    instruction: string
  ) => void;
}

export const TemplateDrawer: FC<TemplateDrawerProps> = (props) => {
  const { open, onClose, onSubmit, onReset } = props;
  const theme = useTheme();
  const [form, setForm] = React.useState({} as any);

  const [chatKey, setChatKey] = React.useState(Date.now().toString());
  const { t } = useTranslation();
  const { strategyId } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const { isLoading: isContentTemplatessLoading, items: contentTemplates } =
    useSelector((state) => state.contentTemplates);
  const [contentTemplateInputValue, setContentTemplateInputValue] =
    useState("");

  const fetchTemplates = useMemo(
    () =>
      debounce(async (request: string) => {
        await dispatch(
          contentTemplatesThunks.getContentTemplates(strategyId!, {
            top: 25,
            page: 1,
            text: request,
          })
        );
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [strategyId]
  );

  useEffect(() => {
    let active = true;

    if (active) {
      fetchTemplates(contentTemplateInputValue);
    }

    return () => {
      active = false;
    };
  }, [fetchTemplates, contentTemplateInputValue]);

  const flow = {
    start: {
      message: "👋 I am here to help with content generation!",
      transition: { duration: 1000 },
      path: "ask_structure",
    },
    ask_structure: {
      message: "Do you want to use a template?",
      options: ["Yes", "No"],
      chatDisabled: true,
      function: (params: any) => {
        setForm({ ...form, custom: params.userInput });
      },
      path: async (params: any) => {
        if (params.userInput === "Yes") {
          if (contentTemplates.items.length > 0) {
            return "ask_select_template";
          } else {
            return "no_templates";
          }
        }
        return "enter_instructions_empty";
      },
    },
    no_templates: {
      message:
        "Ooops... You don't have any templates. Please create a template first. Go to the Content Templates section.",
      chatDisabled: true,
      transition: { duration: 1000 },
      path: async (params: any) => {
        return "ask_for_instructions_no_template";
      },
    },
    ask_for_instructions_no_template: {
      message: "Do you want to create content without a template?",
      options: ["Yes", "No"],
      chatDisabled: true,
      path: async (params: any) => {
        if (params.userInput === "Yes") {
          return "enter_instructions_empty";
        }
        return "close";
      },
    },
    ask_select_template: {
      chatDisabled: true,
      message: (params: any) => `Choose a template`,
      render: (params: any) => {
        return (
          <Box
            sx={{
              p: 2,
            }}
          >
            <Autocomplete
              getOptionLabel={(option: ContentTemplate) => `${option.name}`}
              options={contentTemplates.items}
              fullWidth
              disabled={form.template !== undefined}
              //value={props.values.contentTemplate}
              loading={isContentTemplatessLoading}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event: any, newValue: ContentTemplate | null) => {
                //props.setFieldValue("contentTemplate", newValue);
                setForm({
                  ...form,
                  template: newValue,
                });
              }}
              onInputChange={(event, newInputValue) => {
                setContentTemplateInputValue(newInputValue);
              }}
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  disabled={form.template !== undefined}
                  name="сontentTemplate"
                />
              )}
            />
          </Box>
        );
      },
      options: ["Generate", "Add instructions"],
      path: async (params: any) => {
        return "validate_template";
      },
    },
    validate_template: {
      chatDisabled: true,
      transition: { duration: 100 },
      path: async (params: any) => {
        if (!form.template) {
          await params.injectMessage("Please select a template first.");
          return "ask_select_template";
        } else {
          if (params.userInput === "Generate") {
            return "generating";
          }
          return "enter_instructions_template";
        }
      },
    },
    enter_instructions_empty: {
      message:
        "Please specify what you want to create. For example: 'Create a blog post about cats'",
      chatDisabled: false,
      function: (params: any) => {
        setTimeout(() => {
          onSubmit(form.template, params.userInput);
        }, 1000);
      },
      path: async (params: any) => {
        return "end";
      },
    },
    enter_instructions_template: {
      message: "Please specify what else I need to take into account",
      chatDisabled: false,
      function: (params: any) => {
        setTimeout(() => {
          onSubmit(form.template, params.userInput);
        }, 1000);
      },
      path: async (params: any) => {
        return "end";
      },
    },
    close: {
      transition: { duration: 500 },
      chatDisabled: true,
      function: (params: any) => {
        onReset();
        onClose();
        setForm({});
        setChatKey(Date.now().toString());
      },
    },
    generating: {
      chatDisabled: true,
      transition: { duration: 0 },
      function: (params: any) => {
        setTimeout(() => {
          onSubmit(form.template, "");
        }, 1000);
      },
      path: async (params: any) => {
        return "end";
      },
    },
    end: {
      message: "Generating...",
      chatDisabled: false,
      function: (params: any) => {
        setTimeout(() => {
          onSubmit(form.template, params.userInput);
        }, 1000);
      },
      path: async (params: any) => {
        return "end";
      },
    },
  } as Flow;

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      // hideBackdrop
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={onClose}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          width: "100%",
          maxWidth: 700,
        },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          pl: 3,
          pr: 3,
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="h6">
          {t(tokens.general.aiGeneration.pageHeader)}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <Tooltip title="Reset chat">
            <IconButton
              size="small"
              color="inherit"
              onClick={() => {
                setForm({});
                onReset();
                setChatKey(Date.now().toString());
              }}
            >
              <SvgIcon>
                <RestartAltIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <IconButton
            size="small"
            color="inherit"
            onClick={() => {
              onClose();
            }}
          >
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      <Box
        sx={{
          mt: 1,
        }}
      >
        <ChatBot
          key={chatKey}
          options={{
            chatWindow: { showScrollbar: true, autoJumpToBottom: true },
            chatWindowStyle: {
              height: "calc(100vh - 72px)",
              width: "100%",
            },

            theme: {
              embedded: true,
              primaryColor: theme.palette.primary.main,
              secondaryColor: theme.palette.secondary.main,
              showFooter: false,
              showHeader: false,
              showInputRow: true,
            },
            botBubble: {
              animate: false,
            },
            userBubble: {
              animate: false,
            },
            // botBubbleStyle: { backgroundColor: theme.palette.primary.main },
            // userBubbleStyle: { backgroundColor: theme.palette.secondary.main },
            chatInput: {
              enabledPlaceholderText:
                "Type a message... Use Shift + Enter for newline",
              disabledPlaceholderText: "Chat is disabled",
              allowNewline: true,
            },
            chatInputAreaStyle: {
              borderColor: theme.palette.divider,
              height: 50,
              borderWidth: 1,
            },
            sendButtonStyle: {
              borderRadius: "50%",
              width: 50,
              height: 50,
            },
            sendButtonHoveredStyle: {
              borderRadius: "50%",
              width: 50,
              height: 50,
            },
            chatInputAreaFocusedStyle: {
              // backgroundColor: theme.palette.background.paper,
              // borderColor: theme.palette.primary.main,
            },
            chatInputContainerStyle: {
              backgroundColor: theme.palette.background.paper,
              borderColor: theme.palette.divider,
              borderWidth: 1,
            },
            notification: { disabled: true },
            chatHistory: { disabled: true },
            fileAttachment: { disabled: true },
            emoji: { disabled: true },
          }}
          flow={flow}
        />
      </Box>
    </Drawer>
  );
};
