import { useCallback, useState, type FC } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Storyline } from "src/types/storyline";
import Grid from "@mui/material/Unstable_Grid2";
import { StorylineItem } from "./storyline-list-item";
import { ErrorMessage } from "src/sections/common/error-message";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch, useSelector } from "src/store";
import toast from "react-hot-toast";
import { thunks } from "src/thunks/storyline";

interface StorylineListProps {
  items: Storyline[] | [];
  isLoading: boolean;
  hasError: boolean;
  onReset: () => void;
}

export const StorylineList: FC<StorylineListProps> = (props) => {
  
  const { items = [], isLoading, hasError, onReset } = props;
  const { t } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [storylineId, setStorylineId] = useState("");

  const { strategyId } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const handleCloseDeleteDialog = useCallback(() => {
    setStorylineId("");
    setOpenDeleteDialog(false);
  }, []);

  const handleOpenDeleteDialog = useCallback((id: string) => {
    setStorylineId(id);
    setOpenDeleteDialog(true);
  }, []);

  const deleteStoryline = useCallback(async () => {
    try {
      if (storylineId && strategyId) {
        handleCloseDeleteDialog();
        await dispatch(thunks.deleteStoryline(strategyId, storylineId));
        onReset();
      }
    } catch (err) {
      toast.error(t(tokens.general.formError));
    }
  }, [dispatch, handleCloseDeleteDialog, onReset, storylineId, strategyId, t]);

  if (hasError) {
    return (
      <Stack
        sx={{ position: "relative" }}
        spacing={3}
      >
        <Card>
          <CardContent>
            <ErrorMessage
              text={t(tokens.storylines.list.errorLoadingStorylines)}
            />
          </CardContent>
        </Card>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{ position: "relative" }}
      spacing={3}
    >
      <Grid
        container
        spacing={3}
      >
        {isLoading && (
          <>
            {[0, 1, 2].map((storyline, index) => (
              <Grid
                key={index}
                md={4}
                sm={6}
                xs={12}
              >
                <StorylineItem />
              </Grid>
            ))}
          </>
        )}
        {!isLoading && (
          <>
            {items?.map((storyline) => (
              <Grid
                key={storyline.id}
                md={4}
                sm={6}
                xs={12}
              >
                <StorylineItem
                  item={storyline}
                  onDelete={() => {
                    handleOpenDeleteDialog(storyline.id);
                  }}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(tokens.storylines.list.deleteStorylineDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(tokens.storylines.list.deleteStorylineDialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteStoryline}
            color="error"
          >
            {t(tokens.general.buttons.delete)}
          </Button>
          <Button onClick={handleCloseDeleteDialog}>
            {t(tokens.general.buttons.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
