import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultError } from "src/types/error";
import { Topic } from "src/types/topic";

interface TopicState {
  isLoading: boolean;
  error: ResultError | undefined;
  item: Topic | undefined;
}

type LoadTopicAction = PayloadAction<Topic | undefined>;
type TopicLoadingAction = PayloadAction<boolean>;
type TopicLoadingErrorAction = PayloadAction<ResultError | undefined>;

const initialState: TopicState = {
  isLoading: false,
  error: undefined,
  item: undefined,
};

const reducers = {
  setLoadingStatus: (state: TopicState, action: TopicLoadingAction): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: TopicState,
    action: TopicLoadingErrorAction
  ): void => {
    state.error = action.payload;
  },
  setTopic: (state: TopicState, action: LoadTopicAction): void => {
    state.item = action.payload;
  },
};

export const slice = createSlice({
  name: "topic",
  initialState,
  reducers,
});

export const { reducer } = slice;
