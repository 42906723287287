export interface PromptMessage {
  content: string;
  user: PromptMessageUser;
}

export enum PromptMessageUser {
  User = 1,
  
  Assistant = 2,

  System = 3,
}
