import { useState, type FC } from "react";
import { IconButton, Skeleton, Stack, SvgIcon, TextField } from "@mui/material";
import { StrategyPartType } from "src/types/strategy";
import { Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch } from "src/store";
import { GenerateAgain } from "../common/generate-again";
import EditIcon from "@mui/icons-material/Edit";
import { ContentEditor } from "../common/content-editor";
import { useStrategyPart } from "../hooks/use-strategy-part";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useAuth } from "src/hooks/use-auth";
import { SocialProfile, SocialProfileType } from "src/types/social-profile";
import { thunks as socialProfileThunks } from "src/thunks/social-profile";
import { InstagramGrid } from "./instagram-grid";

interface InstagramProfilePartPartProps {
  strategyId: string;
  socialProfile?: SocialProfile;
}

export const InstagramProfilePart: FC<InstagramProfilePartPartProps> = (
  props
) => {
  const { socialProfile, strategyId } = props;
  const { t } = useTranslation();

  const part = useStrategyPart(StrategyPartType.InstagramProfile);

  const dispatch = useDispatch();
  const { account } = useAuth();

  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(socialProfile?.data?.title);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h4">
          {t(tokens.recommendations.instagram.profile.header)}
        </Typography>
        {part?.item?.isReady && account?.isHost && (
          <IconButton
            size="small"
            disabled={part?.item?.isGenerating}
            onClick={part.createPart}
          >
            <SvgIcon>
              <RefreshOutlinedIcon />
            </SvgIcon>
          </IconButton>
        )}
      </Stack>

      {!part?.item?.isGenerating && !part?.item?.isReady && (
        <GenerateAgain
          createPart={part.createPart}
          error={t(tokens.recommendations.instagram.noDataFound)}
          isGenerating={part?.item?.isGenerating ?? false}
        />
      )}
      {(part?.item?.isReady || part?.item?.isGenerating) && (
        <>
          <Card>
            <CardContent>
              {!isEditing && (
                <>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    spacing={2}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {part?.item?.isReady && <>{socialProfile?.data.title}</>}
                      {part?.item?.isGenerating && <Skeleton />}
                    </Typography>
                    <IconButton
                      size="small"
                      disabled={part?.item?.isGenerating || isEditing}
                      onClick={() => {
                        setIsEditing(!isEditing);
                      }}
                    >
                      <SvgIcon>
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </Stack>
                  {part?.item?.isReady && (
                    <>
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: socialProfile?.data?.description ?? "",
                        }}
                      ></Typography>
                    </>
                  )}
                </>
              )}

              {isEditing && (
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    value={title}
                  />
                  <ContentEditor
                    onCancel={async () => {
                      setTitle(socialProfile?.data?.title);
                      setIsEditing(false);
                    }}
                    showHeaderTool={false}
                    text={socialProfile?.data?.description}
                    onSave={async (content) => {
                      await dispatch(
                        socialProfileThunks.updateSocialProfile(
                          strategyId,
                          SocialProfileType.Instagram,
                          {
                            data: {
                              title: title,
                              description: content,
                            },
                          }
                        )
                      );
                      //var newStrategy = { ...item };
                      //newStrategy.instagramDescription = content;
                      //newStrategy.instagramTitle = title;
                      //await dispatch(
                      //strategyThunks.updateStrategy(item!.id, newStrategy)
                      //);
                      setIsEditing(false);
                    }}
                  />
                </Stack>
              )}

              {part?.item?.isGenerating && (
                <>
                  {[...Array(5)].map((_, index) => (
                    <Skeleton key={index} />
                  ))}
                </>
              )}
            </CardContent>
          </Card>

          <InstagramGrid strategyId={strategyId} />
        </>
      )}
    </>
  );
};
