export enum ComplexityType {
  Unknown,
  Easy,
  Medium,
  Complicated,
}

export interface LeadMagnet {
  id: string;
  name: string;
  description: string;
  type: string;
  complexity: ComplexityType;
  product: LeadMagnetProduct;
}

export interface LeadMagnetProduct {
  id: string;
  name: string;
}

export interface LeadMagnetResponse {
  items: LeadMagnet[] | [];
  totalCount: number;
  top: number;
  page: number;
  lastItemDateTime: number | null;
}

export interface GetLeadMagnetsQueryParams {
  productId?: string;
  text?: string;
  top?: number;
  page?: number;
  lastItemDateTime?: number;
}

export interface GeneratedLeadMagnet {
  name: string;
  description: string;
  type: string;
  complexity?: ComplexityType;
}
