import { useEffect } from 'react';
import { ClarityConfig, clarity } from '../lib/clarity';


export function useClarity(config: ClarityConfig) {
  useEffect(
    () => {
      clarity.initialize(config);
    },
    [config]
  );
}
