import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ResultError } from "src/types/error";
import { StorylineCategory } from "src/types/storyline-category";

interface StorylineCategoryState {
  isLoading: boolean;
  error: ResultError | undefined;
  item: StorylineCategory | undefined;
}

const initialState: StorylineCategoryState = {
  isLoading: false,
  error: undefined,
  item: undefined,
};

const reducers = {
  setLoadingStatus: (
    state: StorylineCategoryState,
    action: PayloadAction<boolean>
  ): void => {
    state.isLoading = action.payload;
  },
  setLoadingErrorStatus: (
    state: StorylineCategoryState,
    action: PayloadAction<ResultError | undefined>
  ): void => {
    state.error = action.payload;
  },
  setStorylineCategory: (
    state: StorylineCategoryState,
    action: PayloadAction<StorylineCategory | undefined>
  ): void => {
    state.item = action.payload;
  },
};

export const slice = createSlice({
  name: "storyline-category",
  initialState,
  reducers,
});

export const { reducer } = slice;
