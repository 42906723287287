import { slice as settingsSlice } from "src/slices/settings";
import type { AppThunk } from "src/store";

const setStrategyId =
  (selectedStrategyId: string | undefined): AppThunk =>
  async (dispatch): Promise<any> =>
    dispatch(settingsSlice.actions.setStrategyId(selectedStrategyId));

export const thunks = {
  setStrategyId,
};
