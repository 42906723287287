import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { Box, Stack } from "@mui/material";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { StrategySettings } from "src/sections/settings/strategy-settings";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  return (
    <>
      <Seo title={t(tokens.strategySettings.title) as string} />

      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            overflow: "hidden",
            position: "relative",
            gap: "18px",
          }}
        >
          <Box
            sx={{
              flex: "1 1 auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Stack
              sx={{ px: 1, pb: 1 }}
              spacing={3}
            >
              <StrategySettings />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Page;
