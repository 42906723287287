import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ProductResponse } from "src/types/product";

interface ProductsState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: ProductResponse;
}

type GetProductsAction = PayloadAction<ProductResponse>;
type ProductsLoadingStatusAction = PayloadAction<boolean>;
type ProductsErrorStatusAction = PayloadAction<boolean>;

const initialState: ProductsState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null,
  },
};

const reducers = {
  setLoadingStatus: (
    state: ProductsState,
    action: ProductsLoadingStatusAction
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: ProductsState,
    action: ProductsErrorStatusAction
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getProducts: (state: ProductsState, action: GetProductsAction): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: "products",
  initialState,
  reducers,
});

export const { reducer } = slice;
