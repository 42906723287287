import type { ChangeEvent } from "react";
import { useCallback, useState } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import {
  Box,
  Container,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { useAuth } from "src/hooks/use-auth";
import { usePageView } from "src/hooks/use-page-view";
import { AccountGeneralSettings } from "src/sections/dashboard/account/account-general-settings";
// import { AccountBillingSettings } from 'src/sections/dashboard/account/account-billing-settings';
// import { AccountNotificationsSettings } from 'src/sections/dashboard/account/account-notifications-settings';
// import { AccountTeamSettings } from 'src/sections/dashboard/account/account-team-settings';
// import { AccountSecuritySettings } from 'src/sections/dashboard/account/account-security-settings';
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";

// const now = new Date();

const Page: PageType = () => {
  const auth = useAuth();
  const [currentTab, setCurrentTab] = useState<string>("general");
  const { t } = useTranslation();

  const tabs = [
    {
      label: t(tokens.account.profile.tab.general) as string,
      value: "general",
    },
    // { label: 'Billing', value: 'billing' },
    // { label: 'Team', value: 'team' },
    // { label: 'Notifications', value: 'notifications' },
    // { label: 'Security', value: 'security' }
  ];

  usePageView();

  const handleTabsChange = useCallback(
    (event: ChangeEvent<{}>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  return (
    <>
      <Seo title={t(tokens.account.profile.title) as string} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            spacing={3}
            sx={{ mb: 3 }}
          >
            <Typography variant="h4">
              {t(tokens.account.profile.header) as string}
            </Typography>
            <div>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
              <Divider />
            </div>
          </Stack>
          {currentTab === "general" && (
            <>
              <Stack spacing={4}>
                <AccountGeneralSettings
                  avatar={auth.account?.avatar || ""}
                  firstName={auth.account?.firstName || ""}
                  lastName={auth.account?.lastName || ""}
                  email={auth.account?.email || ""}
                  profileImageId={auth.account?.profileImageId || ""}
                  profileImageUrl={auth.account?.profileImageUrl || ""}
                />
              </Stack>
            </>
          )}
          {/* {currentTab === 'billing' && (
            <AccountBillingSettings
              plan="standard"
              invoices={[
                {
                  id: '5547409069c59755261f5546',
                  amount: 4.99,
                  createdAt: subMonths(now, 1).getTime()
                },
                {
                  id: 'a3e17f4b551ff8766903f31f',
                  amount: 4.99,
                  createdAt: subMonths(now, 2).getTime()
                },
                {
                  id: '28ca7c66fc360d8203644256',
                  amount: 4.99,
                  createdAt: subMonths(now, 3).getTime()
                }
              ]}
            />
          )}
          {currentTab === 'team' && (
            <AccountTeamSettings
              members={[
                {
                  avatar: '/assets/avatars/avatar-cao-yu.png',
                  email: 'cao.yu@devias.io',
                  name: 'Cao Yu',
                  role: 'Owner'
                },
                {
                  avatar: '/assets/avatars/avatar-siegbert-gottfried.png',
                  email: 'siegbert.gottfried@devias.io',
                  name: 'Siegbert Gottfried',
                  role: 'Standard'
                }
              ]}
            />
          )}
           */}
        </Container>
      </Box>
    </>
  );
};

export default Page;
