import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as topicSlice } from "src/slices/topic";
import { slice as topicsSlice } from "src/slices/topics";
import { ERRORS } from "src/consts";

const clearTopic =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicSlice.actions.setTopic(undefined));
  };

const createTopic =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var topic = await fetchWrapper.post(
      `${api}/strategies/${strategyId}/topics`,
      value
    );
    dispatch(topicSlice.actions.setTopic(topic));
    return topic;
  };

const updateTopic =
  (strategyId: string, topicId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var topic = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/topics/${topicId}`,
      value
    );
    dispatch(topicSlice.actions.setTopic(topic));
    dispatch(topicsSlice.actions.updateTopic(topic));
    return topic;
  };

const deleteTopic =
  (strategyId: string, topicId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/strategies/${strategyId}/topics/${topicId}`,
      {}
    );
    dispatch(topicSlice.actions.setTopic(undefined));
  };

const getTopic =
  (strategyId: string, topicId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(topicSlice.actions.setLoadingStatus(true));
    try {
      var topic = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/topics/${topicId}`,
        {}
      );
      dispatch(topicSlice.actions.setTopic(topic));
    } catch (error) {
      if (error?.code === "Topic.TopicNotFound") {
        dispatch(
          topicSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR)
        );
      } else {
        dispatch(
          topicSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR)
        );
      }
    }
    dispatch(topicSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createTopic,
  updateTopic,
  deleteTopic,
  clearTopic,
  getTopic,
};
