import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Strategy, StrategyPart } from "src/types/strategy";

interface StrategyState {
  item: Strategy | undefined;
}

type GetStrategyAction = PayloadAction<Strategy | undefined>;
type GetStrategyPartAction = PayloadAction<StrategyPart>;

const initialState: StrategyState = {
  item: undefined,
};

const reducers = {
  getStrategy: (state: StrategyState, action: GetStrategyAction): void => {
    state.item = action.payload;
  },
  finishStrategy: (state: StrategyState, action: PayloadAction): void => {
    state.item = undefined;
  },
  getStrategyPart: (
    state: StrategyState,
    action: GetStrategyPartAction
  ): void => {
    if (state.item) {
      var parts = state.item?.parts.filter(
        (x) => x.partType !== action.payload.partType
      );
      parts.push(action.payload);
      state.item.parts = parts;
    }
  },
  getStrategyParts: (
    state: StrategyState,
    action: PayloadAction<StrategyPart[]>
  ): void => {
    if (state.item) {
      state.item.parts = action.payload;
    }
  },
};

export const slice = createSlice({
  name: "strategy",
  initialState,
  reducers,
});

export const { reducer } = slice;
