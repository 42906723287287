import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as productSlice } from "src/slices/product";
import { ERRORS } from "src/consts";

const clearProduct =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productSlice.actions.setProduct(undefined));
  };

const createProduct =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productSlice.actions.setSavingErrorStatus(false));
    dispatch(productSlice.actions.setSavingStatus(true));
    try {
      var product = await fetchWrapper.post(
        `${api}/strategies/${strategyId}/products`,
        value
      );
      dispatch(productSlice.actions.setProduct(product));
      return product;
    } catch (error) {
      dispatch(productSlice.actions.setSavingErrorStatus(true));
    }
    dispatch(productSlice.actions.setSavingStatus(false));
    return undefined;
  };

const updateProduct =
  (strategyId: string, productId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productSlice.actions.setSavingErrorStatus(false));
    dispatch(productSlice.actions.setSavingStatus(true));
    try {
      var product = await fetchWrapper.put(
        `${api}/strategies/${strategyId}/products/${productId}`,
        value
      );
      dispatch(productSlice.actions.setProduct(product));
      return product;
    } catch (error) {
      dispatch(productSlice.actions.setSavingErrorStatus(true));
    }
    dispatch(productSlice.actions.setSavingStatus(false));
    return undefined;
  };

const deleteProduct =
  (strategyId: string, productId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/strategies/${strategyId}/products/${productId}`,
      {}
    );
    dispatch(productSlice.actions.setProduct(undefined));
  };

const getProduct =
  (strategyId: string, productId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(productSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(productSlice.actions.setLoadingStatus(true));
    try {
      var product = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/products/${productId}`,
        {}
      );
      dispatch(productSlice.actions.setProduct(product));
    } catch (error) {
      if (error?.code === "Product.ProductNotFound") {
        dispatch(
          productSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR)
        );
      } else {
        dispatch(
          productSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR)
        );
      }
    }
    dispatch(productSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createProduct,
  updateProduct,
  deleteProduct,
  clearProduct,
  getProduct,
};
