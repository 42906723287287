import React, { FC, useCallback, useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";

import ChatBot from "react-chatbotify";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/content-types";
import { LoadIndicator } from "src/sections/common/load-indicator";
import { ErrorMessage } from "src/sections/common/error-message";

interface ScheduleDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const ScheduleDrawer: FC<ScheduleDrawerProps> = (props) => {
  const { open, onClose } = props;
  const theme = useTheme();
  const [form, setForm] = React.useState({} as any);
  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);

  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.contentTypes
  );

  const { t } = useTranslation();

  const flow = {
    start: {
      message: "👋 Let me help you with the content plan!",
      transition: { duration: 500 },
      path: "ask_days",
    },
    // ask_channels: {
    //   message: "What channel do you want to post content on?",
    //   options: items.map((item) => item.name),
    //   chatDisabled: true,
    //   function: (params: any) =>
    //     setForm({ ...form, channel: params.userInput }),
    //   path: "goal",
    // },
    goal: {
      message: "What is your goal for this month?",
      options: ["Engage people", "Build Community", "Sell a product/service"],
      chatDisabled: true,
      function: (params: any) => setForm({ ...form, name: params.userInput }),
      path: "ask_days",
    },

    ask_days: {
      message:
        "Which days do you want to post content? (You can select multiple)",
      checkboxes: {
        items: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        min: 1,
        max: 7,
      },
      chatDisabled: true,
      function: (params: any) => {
        setForm({ ...form, days: params.userInput });
        setTimeout(() => {
          onClose();
        }, 1000);
      },
      path: "end",
    },

    end: {
      message: "👍 I got you!",
      chatDisabled: true,
    },
    // ask_age: {
    //   message: (params: any) =>
    //     `Nice to meet you ${params.userInput}, what is your age?`,
    //   function: (params: any) => setForm({ ...form, age: params.userInput }),
    //   path: async (params: any) => {
    //     if (isNaN(Number(params.userInput))) {
    //       await params.injectMessage("Age needs to be a number!");
    //       return;
    //     }
    //     return "ask_pet";
    //   },
    // },
    // ask_pet: {
    //   message: "Do you own any pets?",
    //   options: ["Yes", "No"],
    //   chatDisabled: true,
    //   function: (params: any) =>
    //     setForm({ ...form, pet_ownership: params.userInput }),
    //   path: "ask_choice",
    // },

    // ask_work_days: {
    //   message: "How many days can you work per week?",
    //   function: (params: any) =>
    //     setForm({ ...form, num_work_days: params.userInput }),
    //   path: async (params: any) => {
    //     if (isNaN(Number(params.userInput))) {
    //       await params.injectMessage(
    //         "Number of work day(s) need to be a number!"
    //       );
    //       return;
    //     }
    //     return "end";
    //   },
    // },
    // end: {
    //   message: "Thank you for your interest, we will get back to you shortly!",
    //   render: (
    //     <div>
    //       <p>Name: {form.name}</p>
    //       <p>Age: {form.age}</p>
    //       <p>Pet Ownership: {form.pet_ownership}</p>
    //       <p>Pet Choices: {form.pet_choices}</p>
    //       <p>Num Work Days: {form.num_work_days}</p>
    //     </div>
    //   ),
    //   options: ["New Application"],
    //   chatDisabled: true,
    //   path: "start",
    // },
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          overflow: "visible",
          width: "100%",
          maxWidth: 700,
        },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          pl: 3,
          pr: 3,
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="h6">
          {t(tokens.calendar.contentPlanSchedule.pageHeader)}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={0.5}
        >
          <IconButton
            size="small"
            color="inherit"
            onClick={() => {
              onClose();
            }}
          >
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      <Box
        sx={{
          mt: 1,
        }}
      >
        {hasLoadingError && (
          <Box sx={{ p: 3 }}>
            <ErrorMessage
              text={t(tokens.contentTypes.errorLoadingContentTypes)}
            />
          </Box>
        )}
        {isLoading && (
          <>
            <Box sx={{ p: 3 }}>
              <LoadIndicator />
            </Box>
          </>
        )}
        {!isLoading && !hasLoadingError && (
          <ChatBot
            options={{
              chatWindow: { showScrollbar: true, autoJumpToBottom: true },
              chatWindowStyle: {
                height: "calc(100vh - 72px)",
                width: "100%",
              },

              theme: {
                embedded: true,
                primaryColor: theme.palette.primary.main,
                secondaryColor: theme.palette.secondary.main,
                showFooter: false,
                showHeader: false,
                showInputRow: false,
              },
              botBubble: {
                animate: false,
              },
              userBubble: {
                animate: false,
              },
              // botBubbleStyle: { backgroundColor: theme.palette.primary.main },
              // userBubbleStyle: { backgroundColor: theme.palette.secondary.main },
              chatInput: {
                enabledPlaceholderText: "Type a message...",
                disabledPlaceholderText: "Chat is disabled",
                allowNewline: true,
              },
              chatInputAreaStyle: {
                borderColor: theme.palette.divider,
                height: 50,
                borderWidth: 1,
              },
              sendButtonStyle: {
                borderRadius: "50%",
                width: 50,
                height: 50,
              },
              sendButtonHoveredStyle: {
                borderRadius: "50%",
                width: 50,
                height: 50,
              },
              chatInputAreaFocusedStyle: {
                // backgroundColor: theme.palette.background.paper,
                // borderColor: theme.palette.primary.main,
              },
              chatInputContainerStyle: {
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.divider,
                borderWidth: 1,
              },
              notification: { disabled: true },
              chatHistory: { disabled: true },
              fileAttachment: { disabled: true },
              emoji: { disabled: true },
            }}
            flow={flow}
          />
        )}
      </Box>
    </Drawer>
  );
};
