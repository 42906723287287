import { type FC } from "react";
import { StrategyPartType } from "src/types/strategy";
import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { BrandPartTextItem } from "./brand-part-text-item";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { GenerateAgain } from "../common/generate-again";
import { useDispatch, useSelector } from "src/store";
import { useStrategyPart } from "../hooks/use-strategy-part";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useAuth } from "src/hooks/use-auth";

interface BrandPartProps {
  isPrintingMode?: boolean;
}

export const BrandPart: FC<BrandPartProps> = (props) => {
  const { t } = useTranslation();
  const { isPrintingMode } = props;

  const { item } = useSelector((state) => state.strategy);
  const { account } = useAuth();
  const dispatch = useDispatch();

  const part = useStrategyPart(StrategyPartType.AboutMe);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h4">{t(tokens.aboutMe.header)}</Typography>
        {part?.item?.isReady && account?.isHost && (
          <IconButton
            size="small"
            disabled={part?.item?.isGenerating}
            onClick={part.createPart}
          >
            <SvgIcon>
              <RefreshOutlinedIcon />
            </SvgIcon>
          </IconButton>
        )}
        {/* {!isPrintingMode && (
          <IconButton
            size="small"
            disabled={part?.isGenerating}
            onClick={createPart}
          >
            <SvgIcon>
              <RefreshIcon />
            </SvgIcon>
          </IconButton>
        )} */}
      </Stack>

      {!part?.item?.isGenerating && !part?.item?.isReady && (
        <GenerateAgain
          createPart={part.createPart}
          error={t(tokens.aboutMe.noAboutMeFound)}
          isGenerating={part?.item?.isGenerating ?? false}
        />
      )}
      {!part?.item?.hasError && part && (
        <>
          <BrandPartTextItem
            title={t(tokens.aboutMe.sections.aboutMe)}
            emoji="😊"
            isLoading={
              (part?.item?.isGenerating ?? false) || !part?.item?.isReady
            }
            text={item?.aboutMe}
            onUpdate={
              isPrintingMode
                ? undefined
                : async (text) => {
                    var newStrategy = { ...item };
                    newStrategy.aboutMe = text;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                  }
            }
          />

          <BrandPartTextItem
            title={t(tokens.aboutMe.sections.values)}
            emoji="💎"
            isLoading={
              (part?.item?.isGenerating ?? false) || !part?.item?.isReady
            }
            text={item?.values}
            onUpdate={
              isPrintingMode
                ? undefined
                : async (text) => {
                    var newStrategy = { ...item };
                    newStrategy.values = text;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                  }
            }
          />

          <BrandPartTextItem
            title={t(tokens.aboutMe.sections.slogan)}
            emoji="⭐"
            isLoading={
              (part?.item?.isGenerating ?? false) || !part?.item?.isReady
            }
            text={item?.slogan}
            onUpdate={
              isPrintingMode
                ? undefined
                : async (text) => {
                    var newStrategy = { ...item };
                    newStrategy.slogan = text;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                  }
            }
          />

          {/* <BrandPartTextItem
            title={t(tokensaboutMe.sections.reasons)}
            emoji="💎"
            isLoading={!strategy}
            text={strategy?.reasons}
          /> */}

          {/* <BrandPartTextItem
            title={t(tokens.aboutMe.sections.skills)}
            emoji="🤹"
            isLoading={
              (part?.item?.isGenerating ?? false) || !part?.item?.isReady
            }
            text={item?.skills}
            onUpdate={
              isPrintingMode
                ? undefined
                : async (text) => {
                    var newStrategy = { ...item };
                    newStrategy.skills = text;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                  }
            }
          /> */}

          {/* <BrandPartTextItem
            title={t(tokens.aboutMe.sections.goals)}
            emoji="🎯"
            isLoading={
              (part?.item?.isGenerating ?? false) || !part?.item?.isReady
            }
            text={item?.goals}
            onUpdate={
              isPrintingMode
                ? undefined
                : async (text) => {
                    var newStrategy = { ...item };
                    newStrategy.goals = text;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                  }
            }
          /> */}

          {/* <BrandPartListItem
          title={t(tokens.aboutMe.sections.goalsPriority)}
          emoji='⚖️'
          isLoading={!strategy}
          items={strategy?.goalsPriority ?? []}
        /> */}

          {/* <BrandPartListItem
          title={t(tokens.aboutMe.sections.productsOrServices)}
          emoji='🧙'
          isLoading={!strategy}
          items={strategy?.productsOrServices ?? []}
        />

        <BrandPartTextItem
          title={t(tokens.aboutMe.sections.productOrService)}
          emoji='👉'
          isLoading={!strategy}
          text={strategy?.productOrService}
        /> */}

          {/* <BrandPartTextItem
            title={t(
              tokensaboutMe.sections.positionings
            )}
            emoji="🔍"
            isLoading={!strategy}
            text={strategy?.positioning}
          /> */}
          {/* 
        <BrandPartListItem
          title={t(tokens.aboutMe.sections.positionings)}
          emoji='👑'
          isLoading={!strategy}
          items={strategy?.positionings ?? []}
        /> */}

          {/* <BrandPartListItem
          title={t(tokensaboutMe.sections.voice)}
          emoji='💎'
          isLoading={!strategy}
          items={strategy?.values ?? []}
        /> */}

          <BrandPartTextItem
            title={t(tokens.aboutMe.sections.mission)}
            emoji="🚀"
            isLoading={
              (part?.item?.isGenerating ?? false) || !part?.item?.isReady
            }
            text={item?.mission}
            onUpdate={
              isPrintingMode
                ? undefined
                : async (text) => {
                    var newStrategy = { ...item };
                    newStrategy.mission = text;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                  }
            }
          />

          <BrandPartTextItem
            title={t(tokens.aboutMe.sections.expertContent)}
            emoji="💪"
            isLoading={
              (part?.item?.isGenerating ?? false) || !part?.item?.isReady
            }
            text={item?.expertContent}
            onUpdate={
              isPrintingMode
                ? undefined
                : async (text) => {
                    var newStrategy = { ...item };
                    newStrategy.expertContent = text;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                  }
            }
          />

          <BrandPartTextItem
            title={t(tokens.aboutMe.sections.personalContent)}
            emoji="🧘‍♂️"
            isLoading={
              (part?.item?.isGenerating ?? false) || !part?.item?.isReady
            }
            text={item?.personalContent}
            onUpdate={
              isPrintingMode
                ? undefined
                : async (text) => {
                    var newStrategy = { ...item };
                    newStrategy.expertContent = text;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                  }
            }
          />

          {/* <Card>
            <CardContent>
              <SectionTitle
                emoji={"📣"}
                text={
                  t(
                    tokensaboutMe.sections.voice
                  ) as string
                }
              />
              <p>
                <b>Моє УТП -</b> {item?.slogan}
              </p>
              <p>
                <b>Моя місія - </b> {item?.mission}
              </p>
            </CardContent>
          </Card> */}

          {/* <BrandPartTextItem
          title={t(tokensaboutMe.sections.mission)}
          emoji='🌄'
          isLoading={!strategy}
          text={strategy?.mission}
        /> */}

          {/* <BrandPartListItem
            title={t(
              tokensaboutMe.sections.contentCategories
            )}
            emoji="📰"
            isLoading={!strategy}
            items={strategy?.contentCategories ?? []}
          /> */}

          {/* <BrandPartTextItem
            title={t(
              tokensaboutMe.sections.contentVision
            )}
            emoji="🔍"
            isLoading={!strategy}
            text={strategy?.contentVision}
          /> */}
        </>
      )}
    </>
  );
};
