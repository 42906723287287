import { useState, type FC } from "react";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextEditor } from "src/components/core/text-editor/text-editor";
import toast from "react-hot-toast";

interface ContentEditorProps {
  text: string;
  showHeaderTool: boolean;
  onCancel: () => Promise<void>;
  onSave?: (text: string) => Promise<void>;
}

export const ContentEditor: FC<ContentEditorProps> = (props) => {
  const { t } = useTranslation();
  const { onCancel, onSave, text, showHeaderTool } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState(text ?? "");

  return (
    <>
      <Stack spacing={2}>
        <TextEditor
          showHeaderTool={showHeaderTool}
          content={text}
          onUpdate={(content) => {
            setData(content.editor.getHTML());
          }}
          placeholder={t(tokens.general.placeholders.editor)}
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
          spacing={1}
        >
          <LoadingButton
            type="submit"
            onClick={async () => {
              setIsSaving(true);
              try {
                await onSave?.(data);
              } catch (error) {
                toast.error(t(tokens.general.formError));
              } finally {
                setIsSaving(false);
              }
            }}
            disabled={isSaving}
            loading={isSaving}
            variant="contained"
          >
            {t(tokens.general.buttons.update)}
          </LoadingButton>
          <Button
            onClick={onCancel}
            color="inherit"
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
