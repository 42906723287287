// NOTE: We did not use React Components for Icons, because
//  you may one to get the config from the server.

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { useSelector } from "src/store";
import { NavItemConfig } from "src/types/nav";
import { StrategyPartType } from "src/types/strategy";

// NOTE: First level elements are groups.

export interface LayoutConfigResult {
  menuItems: NavItemConfig[];
}

export function LayoutConfig(): LayoutConfigResult {
  const parts = useSelector((state) => state.strategy?.item?.parts ?? []);
  const { t } = useTranslation();

  const items = useMemo<NavItemConfig[]>(() => {
    return [
      {
        key: "content",
        title: t(tokens.sidebar.content),
        items: [
          // {
          //   key: "calendar",
          //   title: "Календар",
          //   href: paths.home,
          //   icon: "calendar",
          // },
          {
            key: "calendar",
            title: t(tokens.sidebar.calendar),
            href: paths.calendar,
            icon: "calendar",
            isGenerating: false,
            isReady: true,
            matcher: { type: "startsWith", href: paths.calendar },
            hasError: false,
          },
          // {
          //   key: "topics",
          //   title: t(tokens.sidebar.ideas),
          //   href: paths.topics.list,
          //   icon: "jar",
          //   matcher: { type: "startsWith", href: paths.topics.list },
          //   isGenerating: parts.find(
          //     (x) => x.partType === StrategyPartType.Storylines
          //   )?.isGenerating,
          //   isReady: parts.find(
          //     (x) => x.partType === StrategyPartType.Storylines
          //   )?.isReady,
          //   hasError: parts.find(
          //     (x) => x.partType === StrategyPartType.Storylines
          //   )?.hasError,
          // },
          // {
          //   key: "magnets",
          //   title: t(tokens.sidebar.leadMagnets),
          //   href: paths.leadMagnets.list,
          //   icon: "magnet",
          //   matcher: { type: "startsWith", href: paths.leadMagnets.list },
          //   isGenerating: parts.find(
          //     (x) => x.partType === StrategyPartType.LeadMagnets
          //   )?.isGenerating,
          //   isReady: parts.find(
          //     (x) => x.partType === StrategyPartType.LeadMagnets
          //   )?.isReady,
          //   hasError: parts.find(
          //     (x) => x.partType === StrategyPartType.LeadMagnets
          //   )?.hasError,
          // },
          // {
          //   key: "topicGenerator",
          //   title: t(tokens.sidebar.topicGenerator),
          //   href: paths.topicGenerator,
          //   icon: "lightbulb",
          // },
          {
            key: "storylines",
            title: t(tokens.sidebar.storylines),
            href: paths.storylines.list,
            icon: "book-open-text",
            matcher: { type: "startsWith", href: paths.storylines.list },
            isGenerating: parts.find(
              (x) => x.partType === StrategyPartType.Storylines
            )?.isGenerating,
            isReady: parts.find(
              (x) => x.partType === StrategyPartType.Storylines
            )?.isReady,
            hasError: parts.find(
              (x) => x.partType === StrategyPartType.Storylines
            )?.hasError,
          },
          {
            key: "content-templates",
            title: t(tokens.sidebar.contentTemplates),
            href: paths.contentTemplates.list,
            icon: "copy",
            matcher: { type: "startsWith", href: paths.contentTemplates.list },
            isGenerating: parts.find(
              (x) => x.partType === StrategyPartType.ContentTemplates
            )?.isGenerating,
            isReady: parts.find(
              (x) => x.partType === StrategyPartType.ContentTemplates
            )?.isReady,
            hasError: parts.find(
              (x) => x.partType === StrategyPartType.ContentTemplates
            )?.hasError,
          },
        ],
      },
      {
        key: "my-brand",
        title: t(tokens.sidebar.myBrand),
        items: [
          {
            key: "about-me",
            title: t(tokens.sidebar.aboutMe),
            href: paths.profile,
            icon: "user",
            matcher: { type: "startsWith", href: paths.profile },
            isGenerating: parts.find(
              (x) => x.partType === StrategyPartType.AboutMe
            )?.isGenerating,
            isReady: parts.find((x) => x.partType === StrategyPartType.AboutMe)
              ?.isReady,
            hasError: parts.find((x) => x.partType === StrategyPartType.AboutMe)
              ?.hasError,
          },
          {
            key: "product",
            title: t(tokens.sidebar.product),
            href: paths.products.details,
            icon: "value",
            matcher: { type: "startsWith", href: paths.products.details },
            isGenerating: parts.find(
              (x) => x.partType === StrategyPartType.Product
            )?.isGenerating,
            isReady: parts.find((x) => x.partType === StrategyPartType.Product)
              ?.isReady,
            hasError: parts.find((x) => x.partType === StrategyPartType.Product)
              ?.hasError,
          },
          {
            key: "target-audience",
            title: t(tokens.sidebar.targetAudiences),
            href: paths.targetAudiences.list,
            icon: "target-audience",
            matcher: { type: "startsWith", href: paths.targetAudiences.list },
            isGenerating: parts.find(
              (x) => x.partType === StrategyPartType.TargetAudiences
            )?.isGenerating,
            isReady: parts.find(
              (x) => x.partType === StrategyPartType.TargetAudiences
            )?.isReady,
            hasError: parts.find(
              (x) => x.partType === StrategyPartType.TargetAudiences
            )?.hasError,
          },
          {
            key: "instagram",
            title: t(tokens.sidebar.instagram),
            icon: "instagram",
            matcher: { type: "startsWith", href: paths.instagramProfile },
            href: paths.instagramProfile,
            isGenerating: parts.find(
              (x) => x.partType === StrategyPartType.InstagramProfile
            )?.isGenerating,
            isReady: parts.find(
              (x) => x.partType === StrategyPartType.InstagramProfile
            )?.isReady,
            hasError: parts.find(
              (x) => x.partType === StrategyPartType.InstagramProfile
            )?.hasError,
          },
        ],
      },
      // {
      //   key: "recommendations",
      //   title: t(tokens.sidebar.recommendations),
      //   items: [
      //     {
      //       key: "instagram",
      //       title: t(tokens.sidebar.instagram),
      //       icon: "instagram",
      //       matcher: { type: "startsWith", href: paths.instagramProfile },
      //       href: paths.instagramProfile,
      //       isGenerating: parts.find(
      //         (x) => x.partType === StrategyPartType.InstagramProfile
      //       )?.isGenerating,
      //       isReady: parts.find(
      //         (x) => x.partType === StrategyPartType.InstagramProfile
      //       )?.isReady,
      //       hasError: parts.find(
      //         (x) => x.partType === StrategyPartType.InstagramProfile
      //       )?.hasError,
      //     },
      //     // {
      //     //   key: "fears",
      //     //   title: t(tokens.sidebar.fears),
      //     //   href: paths.fears,
      //     //   icon: "fear",
      //     //   matcher: { type: "startsWith", href: paths.fears },
      //     //   isGenerating: parts.find(
      //     //     (x) => x.partType === StrategyPartType.Fears
      //     //   )?.isGenerating,
      //     //   isReady: parts.find((x) => x.partType === StrategyPartType.Fears)
      //     //     ?.isReady,
      //     //   hasError: parts.find((x) => x.partType === StrategyPartType.Fears)
      //     //     ?.hasError,
      //     // },
      //   ],
      // },
      {
        key: "general",
        title: t(tokens.sidebar.general),
        items: [
          {
            key: "settings",
            title: t(tokens.sidebar.settings),
            icon: "settings",
            matcher: { type: "startsWith", href: paths.settings },
            href: paths.settings,
            isGenerating: parts.find(
              (x) => x.partType === StrategyPartType.StrategyName
            )?.isGenerating,
            isReady: parts.find(
              (x) => x.partType === StrategyPartType.StrategyName
            )?.isReady,
            hasError: parts.find(
              (x) => x.partType === StrategyPartType.StrategyName
            )?.hasError,
          },
        ],
      },
    ];
  }, [parts, t]);

  return {
    menuItems: items,
  } as LayoutConfigResult;
}
