import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import type { NavItemConfig } from '../../types/nav';
import { RouterLink } from '../../components/core/link';


interface Links {
  title: string;
  path: string;
  external: boolean;
}

const links: Links[] = [
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
    external: false
  },
  {
    title: 'Terms of Use',
    path: '/terms-of-use',
    external: false
  },
  {
    title: 'support@makeitlab.net',
    path: 'mailto:support@makeitlab.net',
    external: true
  }
];

export function Footer(): React.JSX.Element {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'var(--mui-palette-background-default)',
        // borderTop: '1px solid var(--mui-palette-divider)',
        pb: 2,
        pt: { md: 4, xs: 4 },
      }}
    >


      <Container maxWidth="lg">
        <Stack
          alignItems="flex-start"
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          justifyContent="space-between"
          // display="inline-flex"
          spacing={3}
          sx={{ textDecoration: 'none' }}
        >

          <Box
            sx={{
              color: 'text.primary',
              // fontFamily: '\'Plus Jakarta Sans\', sans-serif',
              fontSize: 14,
              fontWeight: 500,
              // letterSpacing: '0.3px',
              lineHeight: 2.5,
              '& span': {
                color: 'primary.main'
              }
            }}
          >
            MakeIt<span>Lab</span>

            © All Rights Reserved.
          </Box>
          <Stack
            spacing={3}
            direction={{ xs: 'column', sm: 'row', md: 'row' }}
          >

            {links.map((item) => {
              const linkProps = item.external
                ? {
                  component: 'a',
                  href: item.path,
                  target: '_blank'
                }
                : {
                  component: RouterLink,
                  href: item.path
                };

              return (
                <Stack
                  alignItems="center"
                  direction="row"
                  key={item.title}
                  spacing={2}
                >
                  <Link
                    color="text.primary"
                    variant="subtitle2"
                    {...linkProps}
                  >
                    {item.title}
                  </Link>

                </Stack>
              )
            })}
          </Stack>
        </Stack>

      </Container>


      {/* <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid md={3} sm={4} sx={{ order: { xs: 4, md: 1 } }} xs={12}>
            <Stack spacing={1}>
              <DynamicLogo colorDark="light" colorLight="dark" height={32} width={122} />
              <Typography color="text.secondary" variant="caption">
                © 2024 Devias IO
              </Typography>
            </Stack>
          </Grid>
          {groups.map((section, index) => (
            <Grid key={section.key} md={3} sm={4} sx={{ order: { md: index + 2, xs: index + 1 } }} xs={12}>
              <Typography color="text.secondary" variant="overline">
                {section.title}
              </Typography>
              <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
                {section.items.map((item) => (
                  <NavItem {...item} key={item.key} />
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Typography color="text.secondary" variant="caption">
          All Rights Reserved.
        </Typography>
      </Container> */}
    </Box>
  );
}

interface NavItemProps extends NavItemConfig { }

function NavItem({ href, external, title }: NavItemProps): React.JSX.Element {
  return (
    <Stack direction="row" key={title} spacing={2} sx={{ alignItems: 'center' }}>
      <Box sx={{ bgcolor: 'var(--mui-palette-primary-main)', height: '2px', width: '12px' }} />
      <Link
        {...(href ? (external ? { component: 'a', href, target: '_blank' } : { component: RouterLink, href }) : {})}
        color="text.primary"
        variant="subtitle2"
      >
        {title}
      </Link>
    </Stack>
  );
}
