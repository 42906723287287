"use client";

import * as React from "react";

import type { EventPriority } from "./types";
import { ContentPlaceholder } from "src/types/content-placeholder";
import moment from "moment";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/content-placeholder";
import { tokens } from "src/locales/tokens";

function noop(): void {
  return undefined;
}

interface EventCreateParams {
  title: string;
  description?: string;
  date: Date;
  allDay: boolean;
  priority?: EventPriority;
}

interface EventUpdateParams {
  title?: string;
  description?: string;
  date?: Date;
  allDay?: boolean;
  priority?: EventPriority;
}

export interface CalendarContextValue {
  events: Map<string, ContentPlaceholder>;
  currentEventId?: string;
  setCurrentEventId: (eventId?: string) => void;
  createEvent: (params: EventCreateParams) => void;
  deleteEvent: (eventId: string) => void;
  updateEvent: (eventId: string, params: EventUpdateParams) => void;
}

export const CalendarContext = React.createContext<CalendarContextValue>({
  events: new Map(),
  setCurrentEventId: noop,
  createEvent: noop,
  deleteEvent: noop,
  updateEvent: noop,
});

export interface CalendarProviderProps {
  children: React.ReactNode;
  events: ContentPlaceholder[];
}

export function CalendarProvider({
  children,
  events: initialEvents = [],
}: CalendarProviderProps): React.JSX.Element {
  const [events, setEvents] = React.useState(
    new Map<string, ContentPlaceholder>()
  );
  const [currentEventId, setCurrentEventId] = React.useState<string>();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);

  React.useEffect((): void => {
    setEvents(new Map(initialEvents.map((event) => [event.id, event])));
  }, [initialEvents]);

  const handleCreateEvent = React.useCallback((params: EventCreateParams) => {
    // const updatedEvents = new Map<string, ContentPlaceholder>(events);
    // // Create event
    // const event: ContentPlaceholder = { id: `EV-${Date.now()}`, ...params };
    // // Add event
    // updatedEvents.set(event.id, event);
    // // Dispatch update
    // setEvents(updatedEvents);
  }, []);

  const handleUpdateEvent = React.useCallback(
    (eventId: string, params: EventUpdateParams) => {
      const event = events.get(eventId);

      // ContentPlaceholder might no longer exist
      if (!event) {
        return;
      }
      //const updatedEvents = new Map<string, ContentPlaceholder>(events);

      const d = moment(params.date).valueOf();
      var item = { ...event };
      item.date = d;

      try {
        dispatch(
          thunks.updateContentPlaceholder(strategyId!, event.id, {
            topicId: event.topic?.id,
            contentTypeId: event.contentType?.id,
            name: event.topic?.name,
            date: d,
            description: event.topic?.description,
            content: event.topic?.content,
            contentGoalType: event.contentGoalType,
          })
        );
      } catch (error) {
        toast.error(t(tokens.general.formError));
      }
      //debugger;
      //event.date = d;

      //updatedEvents.set(eventId, { ...event, ...params });
      // Dispatch update
      //setEvents(updatedEvents);
    },
    [dispatch, events, strategyId, t]
  );

  const handleDeleteEvent = React.useCallback(
    (eventId: string) => {
      const event = events.get(eventId);

      // ContentPlaceholder might no longer exist
      if (!event) {
        return;
      }

      const updatedEvents = new Map<string, ContentPlaceholder>(events);

      // Delete event
      updatedEvents.delete(eventId);

      // Dispatch update
      setEvents(updatedEvents);
    },
    [events]
  );

  return (
    <CalendarContext.Provider
      value={{
        events,
        currentEventId,
        setCurrentEventId,
        createEvent: handleCreateEvent,
        deleteEvent: handleDeleteEvent,
        updateEvent: handleUpdateEvent,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}
