import type { FC } from 'react';
// import { useTheme } from '@mui/material/styles';

export const Logo: FC = () => {
  // const theme = useTheme();
  // const fillColor = theme.palette.primary.main;

  return (
    <svg
      fill="none"
      height="100%"
      viewBox="0 0 100 100"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M66.4588 72.3724L56.6959 62.6095L59.5244 59.7811L69.2872 69.544L66.4588 72.3724Z"
        fill="#ACA9BB" />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6109 45.5244L26.1869 32.1005L29.0153 29.272L42.4393 42.696L39.6109 45.5244Z"
        fill="#ACA9BB" />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0014 28.4394L59.1367 44.3041L56.3082 41.4756L72.1729 25.6109L75.0014 28.4394Z"
        fill="#ACA9BB" />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M43.272 60.1688L26.1869 77.2538L23.3585 74.4254L40.4435 57.3403L43.272 60.1688Z"
        fill="#ACA9BB" />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M23 31C26.3137 31 29 28.3137 29 25C29 21.6863 26.3137 19 23 19C19.6863 19 17 21.6863 17 25C17 28.3137 19.6863 31 23 31ZM23 36C29.0751 36 34 31.0751 34 25C34 18.9249 29.0751 14 23 14C16.9249 14 12 18.9249 12 25C12 31.0751 16.9249 36 23 36Z"
        fill="#696BD8" />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M82 26C86.9706 26 91 21.9706 91 17C91 12.0294 86.9706 8 82 8C77.0294 8 73 12.0294 73 17C73 21.9706 77.0294 26 82 26ZM82 31C89.732 31 96 24.732 96 17C96 9.26801 89.732 3 82 3C74.268 3 68 9.26801 68 17C68 24.732 74.268 31 82 31Z"
        fill="#696BD8" />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M17 93C21.9706 93 26 88.9706 26 84C26 79.0294 21.9706 75 17 75C12.0294 75 8 79.0294 8 84C8 88.9706 12.0294 93 17 93ZM17 98C24.732 98 31 91.732 31 84C31 76.268 24.732 70 17 70C9.26801 70 3 76.268 3 84C3 91.732 9.26801 98 17 98Z"
        fill="#696BD8" />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M73.5 82C76.5376 82 79 79.5376 79 76.5C79 73.4624 76.5376 71 73.5 71C70.4624 71 68 73.4624 68 76.5C68 79.5376 70.4624 82 73.5 82ZM73.5 87C79.299 87 84 82.299 84 76.5C84 70.701 79.299 66 73.5 66C67.701 66 63 70.701 63 76.5C63 82.299 67.701 87 73.5 87Z"
        fill="#696BD8" />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M50 62C55.5228 62 60 57.5228 60 52C60 46.4772 55.5228 42 50 42C44.4772 42 40 46.4772 40 52C40 57.5228 44.4772 62 50 62ZM50 67C58.2843 67 65 60.2843 65 52C65 43.7157 58.2843 37 50 37C41.7157 37 35 43.7157 35 52C35 60.2843 41.7157 67 50 67Z"
        fill="#696BD8" />
    </svg>
  );
};
