
export enum DomainAvailabilityStatus {
    unknown,

    available,

    unavailable
}

export interface Domain {

    name: string
    
    status: DomainAvailabilityStatus;
    
}