import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as socialProfileSlice } from "src/slices/social-profile";
import { SocialProfileType } from "src/types/social-profile";
import { ERRORS } from "src/consts";

const clearSocialProfile =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(socialProfileSlice.actions.getSocialProfile(undefined));
  };

const getSocialProfile =
  (strategyId: string, type: SocialProfileType): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(socialProfileSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(socialProfileSlice.actions.setLoadingStatus(true));
    try {
      var item = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/social-profiles/` + type,
        {}
      );
      dispatch(socialProfileSlice.actions.getSocialProfile(item));
    } catch (error) {
      if (error?.code === "Product.ProductNotFound") {
        dispatch(
          socialProfileSlice.actions.setLoadingErrorStatus(
            ERRORS.NOT_FOUND_ERROR
          )
        );
      } else {
        dispatch(
          socialProfileSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR)
        );
      }
    }
    dispatch(socialProfileSlice.actions.setLoadingStatus(false));
  };

const updateSocialProfile =
  (strategyId: string, type: SocialProfileType, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var item = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/social-profiles/` + type,
      value
    );
    dispatch(socialProfileSlice.actions.getSocialProfile(item));
  };

export const thunks = {
  getSocialProfile: getSocialProfile,
  updateSocialProfile: updateSocialProfile,
  clearSocialProfile: clearSocialProfile,
};
