import React, { type FC } from 'react';
import { Box, IconButton, LinearProgress, Stack, SvgIcon, Typography } from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface WizardHeaderProps {
  pageIndex: number;
  totalPages: number;
  onBack?: () => void;
}

export const WizardHeader: FC<WizardHeaderProps> = (props) => {

  const { pageIndex, totalPages, onBack } = props;

  return (
    <>
      <Box
        sx={{ pb: 0, pt: 0 }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={1}
        >
          {/* <Box
            sx={{
              width: '50px',
              height: '50px',
            }}>
            {pageIndex > 0 &&
              <IconButton
                onClick={() => onBack?.()}
              >
                <SvgIcon>
                  <ArrowBackIosIcon />
                </SvgIcon>
              </IconButton>
            }
          </Box>
          <Typography
            textAlign="center">
            {pageIndex + 1} / {totalPages}
          </Typography>
          <Box
            sx={{
              width: '50px',
              height: '50px',
            }}>
          </Box> */}


          <IconButton
            disabled={pageIndex === 0}
            onClick={() => onBack?.()}
          >
            <SvgIcon>
              <ArrowBackIosIcon />
            </SvgIcon>
          </IconButton>

          <LinearProgress
            sx={{ width: '100%' }}
            variant="determinate"
            value={(pageIndex / totalPages) * 100} />
          <Box>
            <Typography
              variant='body2'
              sx={{
                width: '40px',
                textAlign: 'right'
              }}
              noWrap
            >
              {((pageIndex / totalPages) * 100).toFixed(0)} %
            </Typography>
          </Box>
        </Stack>
      </Box>

    </>
  );
};