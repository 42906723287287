import React, { useEffect, type FC, useCallback } from "react";
import {
  Alert,
  Box,
  Link,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "src/store";
import { ListForm } from "./section-forms/list-form";
import { slice as strategyWizardSlice } from "src/slices/strategy-wizard";
import { TextForm } from "./section-forms/text-form";
import { TwoFieldsForm } from "./section-forms/two-fields-form";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { thunks } from "src/thunks/strategy-wizard";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { SectionFormType } from "src/types/strategy";
import { useParams } from "react-router";

interface WizardSectionsProps {
  saveChanges: (value: any) => Promise<any>;
  onNext?: () => void;
}

export const WizardSections: FC<WizardSectionsProps> = (props) => {
  const { saveChanges, onNext } = props;

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const { strategyId } = useParams();
  const sections = useSelector((state) => state.strategyWizard.steps);
  const wizardStep = useSelector((state) => state.strategyWizard.wizardStep);
  const currentSection = sections[wizardStep];
  const [showExample, setShowExample] = React.useState(false);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const isSaving = currentSection.isSaving;
  const currentValue = currentSection.strategyStep.value;

  const getSectionValue = useCallback(async () => {
    await dispatch(
      thunks.getStrategySectionValue(
        strategyId!,
        currentSection.strategyStep.configuration.sectionKey!,
        i18n.language
      )
    );
  }, [
    currentSection.strategyStep.configuration.sectionKey,
    dispatch,
    i18n.language,
    strategyId,
  ]);

  useEffect(() => {
    if (currentSection.strategyStep.configuration.sectionKey) {
      getSectionValue();
    }
  }, [
    dispatch,
    getSectionValue,
    currentSection.strategyStep.configuration.sectionKey,
  ]);

  useEffect(() => {
    setShowExample(false);
  }, [wizardStep]);

  return (
    <>
      {!currentSection.strategyStep.configuration.sectionKey && (
        <>
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            alignItems={"center"}
            spacing={3}
            sx={{ mb: 4 }}
          >
            <img
              width={mdUp ? 200 : 150}
              height={mdUp ? 200 : 150}
              alt={"robot"}
              src={"/assets/robot.png"}
            />
            <Stack spacing={2}>
              <Typography variant="h3">
                {currentSection.strategyStep.configuration.title}
              </Typography>

              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: currentSection.strategyStep.configuration.description,
                }}
              ></Typography>
            </Stack>
          </Stack>
          <Box>
            <LoadingButton
              type="submit"
              size="large"
              onClick={onNext}
              variant="contained"
              sx={{
                minWidth: 200,
                height: 56,
              }}
            >
              {t(tokens.general.buttons.next)}
            </LoadingButton>
          </Box>
        </>
      )}

      {currentSection.strategyStep.configuration.sectionKey && (
        <>
          <Stack spacing={2}>
            <Typography variant="h3">
              {currentSection.strategyStep.configuration.title}
            </Typography>

            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: currentSection.strategyStep.configuration.description,
              }}
            ></Typography>
            {currentSection.strategyStep.configuration.example && (
              <Stack spacing={2}>
                <Link
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowExample(!showExample);
                  }}
                  variant="subtitle2"
                >
                  {t(tokens.wizard.showExample)} {showExample ? "▲" : "▼"}
                </Link>
                {showExample && (
                  <Alert icon={false}>
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html:
                          currentSection.strategyStep.configuration.example,
                      }}
                    ></Typography>
                  </Alert>
                )}
              </Stack>
            )}
          </Stack>
          {(() => {
            switch (currentSection?.strategyStep.configuration.formType) {
              case SectionFormType.LongText:
                return (
                  <TextForm
                    key={currentSection.strategyStep.configuration.sectionKey}
                    value={currentValue?.text}
                    // placeholder={t(tokens.wizard.placeholders.name) ?? undefined}
                    isMultiline={true}
                    isSubmitting={isSaving}
                    onSubmit={async (text) => {
                      await saveChanges({ text: text });
                    }}
                  />
                );

              case SectionFormType.ShortText:
                return (
                  <TextForm
                    key={currentSection.strategyStep.configuration.sectionKey}
                    value={currentValue?.title}
                    // placeholder={t(tokens.wizard.placeholders.valueProposition) ?? undefined}
                    isMultiline={false}
                    isSubmitting={isSaving}
                    onSubmit={async (title) => {
                      await saveChanges({ title: title });
                    }}
                  />
                );

              case SectionFormType.TwoFields:
                return (
                  <TwoFieldsForm
                    key={currentSection.strategyStep.configuration.sectionKey}
                    title={currentValue?.title}
                    // titlePlaceholder={t(tokens.wizard.placeholders.productOrService.title) ?? undefined}
                    description={currentValue?.description}
                    // descriptionPlaceholder={t(tokens.wizard.placeholders.productOrService.description) ?? undefined}
                    isSubmitting={isSaving}
                    onSubmit={async (title, description) => {
                      await saveChanges({
                        title: title,
                        description: description,
                      });
                    }}
                  />
                );

              case SectionFormType.List:
                return (
                  <ListForm
                    key={currentSection.strategyStep.configuration.sectionKey}
                    values={currentValue?.items ?? [""]}
                    maxItems={
                      currentSection.strategyStep.configuration.options
                        ?.maxItems
                    }
                    // placeholder={t(tokens.wizard.placeholders.targetAudience.title) ?? undefined}
                    isSubmitting={isSaving}
                    onChange={(items) => {
                      dispatch(
                        strategyWizardSlice.actions.setSectionValue({
                          items: items,
                        })
                      );
                    }}
                    onSubmit={async (items) => {
                      await saveChanges({ items: items });
                    }}
                  />
                );

              default:
                return <>{t(tokens.general.formError)}</>;
            }
          })()}
        </>
      )}
    </>
  );
};
