import { ContentGoalType } from "src/types/content-goal-type";

export const getContentTypeColor = (contentGoalType: ContentGoalType) => {
  switch (contentGoalType) {
    case ContentGoalType.Engagement:
      return "var(--mui-palette-primary-main)";
    case ContentGoalType.Community:
      return "var(--mui-palette-warning-main)";
    case ContentGoalType.Selling:
      return "var(--mui-palette-success-main)";
    // case ContentGoalType.Instruct:
    //   return "var(--mui-palette-error-main)";
    default:
      return "transparent";
  }
};

export const getChipColor = (contentGoalType: ContentGoalType) => {
  switch (contentGoalType) {
    case ContentGoalType.Engagement:
      return "primary";
    case ContentGoalType.Community:
      return "warning";
    case ContentGoalType.Selling:
      return "success";
    // case ContentGoalType.Instruct:
    //   return "var(--mui-palette-error-main)";
    default:
      return "default";
  }
};
