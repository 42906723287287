import React, { useState, type FC, useEffect } from 'react';
import { Box, Button, IconButton, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import DeleteIcon from '@mui/icons-material/Delete';

interface Values {
  items: string[];
  submit: null;
}

interface ListFormProps {
  values: string[];
  maxItems?: number;
  placeholder?: string;
  isSubmitting?: boolean;
  onSubmit: (items: string[]) => void;
  onChange: (items: string[]) => void;
}

export const ListForm: FC<ListFormProps> = (props) => {

  const { onSubmit, onChange, values, placeholder, isSubmitting, maxItems } = props;
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<Values>(
    {
      items: values ?? [],
      submit: null
    });

  useEffect(() => {
    setInitialValues({
      items: values ?? [],
      submit: null
    });

  }, [values]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        items: Yup.array().of(
          Yup.string()
            .max(255, t(tokens.general.validators.maxLength255) as string)
            .required(t(tokens.general.validators.required) as string),
        ).min(1, t(tokens.general.validators.required) as string)
      })}
      enableReinitialize={true}
      onSubmit={async (values, helpers): Promise<void> => {
        onSubmit(values.items);
      }}
    >
      {(props) => (
        <Form placeholder="">
          <Stack
            spacing={3}
          >

            <FieldArray
              name="items"
              render={arrayHelpers => {
                return (
                  <>
                    {props.values.items.map((value: string, index: number) => {

                      const text = `items[${index}]`;
                      const touchedTitle = getIn(props.touched, text);
                      const errorTitle = getIn(props.errors, text);

                      return (<React.Fragment key={index}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}>
                          <TextField
                            error={Boolean(touchedTitle && errorTitle)}
                            helperText={touchedTitle && errorTitle}
                            placeholder={placeholder ?? ""}
                            fullWidth
                            multiline
                            sx={{
                              '& input': {
                                p: 2
                              },
                              '& textarea': {
                                p: 2
                              },
                            }}

                            InputProps={{
                              sx: {
                                p: 0
                              }

                            }}
                            name={text}
                            onBlur={(e) => {
                              props.handleBlur(e);
                              onChange([...props.values.items]);
                            }}
                            onChange={props.handleChange}
                            type="text"
                            value={value}
                          />
                          <Box
                            sx={{ pt: 0 }}>
                            <IconButton aria-label="delete"
                              onClick={() => {
                                var res = [...props.values.items].filter((_, i) => i !== index);
                                arrayHelpers.remove(index);
                                onChange(res);
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Stack>
                      </React.Fragment>)
                    })}
                    {!props.isValid && props.submitCount > 0 && props.values.items.length === 0 && (
                      <Typography
                        color={"error"}
                        variant="body2"
                      >
                        {t(tokens.general.validators.oneItemRequired)}
                      </Typography>
                    )}
                    {(!maxItems || props.values.items.length < maxItems) &&
                      <Box>
                        <Button
                          onClick={() => {
                            arrayHelpers.push(
                              ""
                            );
                            onChange([...props.values.items, ""]);
                          }}
                          size="small"
                          startIcon={(
                            <SvgIcon>
                              <PlusIcon />
                            </SvgIcon>
                          )}
                          variant="text"
                        >
                          {t(tokens.general.buttons.addItem)}
                        </Button>
                      </Box >
                    }
                  </>)
              }} />


            <Box>
              <LoadingButton
                type="submit"
                size="large"
                loading={isSubmitting}
                variant="contained"
                sx={{
                  minWidth: 200,
                  height: 56,
                }}
              >
                {t(tokens.general.buttons.next)}
              </LoadingButton>
            </Box>
          </Stack >
        </Form>
      )
      }
    </Formik >
  );
};