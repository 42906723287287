import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { StorylineResponse } from "src/types/storyline";

interface StorylinesState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: StorylineResponse;
}

type GetStorylinesAction = PayloadAction<StorylineResponse>;
type StorylinesLoadingStatusAction = PayloadAction<boolean>;
type StorylinesErrorStatusAction = PayloadAction<boolean>;

const initialState: StorylinesState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    lastItemDateTime: null,
  },
};

const reducers = {
  setLoadingStatus: (
    state: StorylinesState,
    action: StorylinesLoadingStatusAction
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: StorylinesState,
    action: StorylinesErrorStatusAction
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getStorylines: (state: StorylinesState, action: GetStorylinesAction): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: "storylines",
  initialState,
  reducers,
});

export const { reducer } = slice;
