"use client";

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

import { usePopover } from "../../../hooks/use-popover";
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";
import { MobileNav } from "../mobile-nav";
import { NavItemConfig } from "src/types/nav";
import { Language, languages } from "src/language";
import { LanguagePopover } from "src/layouts/components/language-popover";
import { UserPopover } from "src/layouts/components/user-popover/user-popover";
import { AccountImpersonationDrawer } from "src/layouts/components/account-impersonation-drawer";
import { useAuth } from "src/hooks/use-auth";
import { tokens } from "src/locales/tokens";
import { Button } from "@mui/material";
import { paths } from "src/paths";
import { CrownSimple as CrownSimpleInon } from "@phosphor-icons/react/dist/ssr/CrownSimple";
import { useSelector } from "src/store";
import { PlanType } from "src/types/strategy";
import { RouterLink } from "src/components/router-link";

export interface MainNavProps {
  items: NavItemConfig[];
}

export function MainNav({ items }: MainNavProps): React.JSX.Element {
  const [openNav, setOpenNav] = React.useState<boolean>(false);
  const { item } = useSelector((state) => state.strategy);
  const [impersonateDrawerOpen, setImpersonateDrawerOpen] =
    React.useState<boolean>(false);
  const auth = useAuth();
  const abilityToImpersonate = auth.account?.isHost;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box
        component="header"
        sx={{
          "--MainNav-background": "var(--mui-palette-background-default)",
          "--MainNav-divider": "var(--mui-palette-divider)",
          bgcolor: "var(--MainNav-background)",
          left: 0,
          position: "sticky",
          pt: { lg: "var(--Layout-gap)" },
          top: 0,
          width: "100%",
          zIndex: "var(--MainNav-zIndex)",
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid var(--MainNav-divider)",
            display: "flex",
            flex: "1 1 auto",
            minHeight: "var(--MainNav-height)",
            px: { xs: 2, lg: 3 },
            py: 1,
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", flex: "1 1 auto" }}
          >
            <IconButton
              onClick={(): void => {
                setOpenNav(true);
              }}
              sx={{ display: { lg: "none" } }}
            >
              <ListIcon />
            </IconButton>
            {/* <SearchButton /> */}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
              flex: "1 1 auto",
              justifyContent: "flex-end",
            }}
          >
            {item?.planType !== PlanType.ContentPlan && (
              <Button
                variant="outlined"
                component={RouterLink}
                sx={{
                  display: { xs: "none", lg: "inline-flex" },
                }}
                href={paths.pricing}
                startIcon={<CrownSimpleInon />}
              >
                {t(tokens.nav.upgradeAccount)}
              </Button>
            )}
            {/* <IconButton href={paths.products.details}>
              <ListIcon />
              Upgrade account
            </IconButton>
            <Link
              color="primary"
              component={RouterLink}
              href={paths.products.details}
              variant="subtitle2"
            >
              {" "}
              Upgrade account
            </Link> */}
            {/* <NotificationsButton />
            <ContactsButton /> */}
            {/* <Divider
              flexItem
              orientation="vertical"
              sx={{
                borderColor: "var(--MainNav-divider)",
                display: { xs: "none", lg: "block" },
              }}
            /> */}
            <LanguageSwitch />
            <UserButton
              onImpersonate={() => {
                setImpersonateDrawerOpen(true);
              }}
            />
          </Stack>
        </Box>
      </Box>
      <MobileNav
        items={items}
        onClose={() => {
          setOpenNav(false);
        }}
        open={openNav}
      />
      {abilityToImpersonate && (
        <AccountImpersonationDrawer
          isOpen={impersonateDrawerOpen}
          onClose={() => setImpersonateDrawerOpen(false)}
        />
      )}
    </React.Fragment>
  );
}

// function SearchButton(): React.JSX.Element {
//   const dialog = useDialog();

//   return (
//     <React.Fragment>
//       <Tooltip title="Search">
//         <IconButton
//           onClick={dialog.handleOpen}
//           sx={{ display: { xs: "none", lg: "inline-flex" } }}
//         >
//           <MagnifyingGlassIcon />
//         </IconButton>
//       </Tooltip>
//       <SearchDialog
//         onClose={dialog.handleClose}
//         open={dialog.open}
//       />
//     </React.Fragment>
//   );
// }

// function ContactsButton(): React.JSX.Element {
//   const popover = usePopover<HTMLButtonElement>();

//   return (
//     <React.Fragment>
//       <Tooltip title="Contacts">
//         <IconButton
//           onClick={popover.handleOpen}
//           ref={popover.anchorRef}
//         >
//           <UsersIcon />
//         </IconButton>
//       </Tooltip>
//       <ContactsPopover
//         anchorEl={popover.anchorRef.current}
//         onClose={popover.handleClose}
//         open={popover.open}
//       />
//     </React.Fragment>
//   );
// }

// function NotificationsButton(): React.JSX.Element {
//   const popover = usePopover<HTMLButtonElement>();

//   return (
//     <React.Fragment>
//       <Tooltip title="Notifications">
//         <Badge
//           color="error"
//           sx={{
//             "& .MuiBadge-dot": {
//               borderRadius: "50%",
//               height: "10px",
//               right: "6px",
//               top: "6px",
//               width: "10px",
//             },
//           }}
//           variant="dot"
//         >
//           <IconButton
//             onClick={popover.handleOpen}
//             ref={popover.anchorRef}
//           >
//             <BellIcon />
//           </IconButton>
//         </Badge>
//       </Tooltip>
//       {/* <NotificationsPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} /> */}
//     </React.Fragment>
//   );
// }

function LanguageSwitch(): React.JSX.Element {
  const { i18n, t } = useTranslation();
  const popover = usePopover<HTMLButtonElement>();
  const language = (i18n.language || "en") as Language;
  const flag = languages[language];

  return (
    <React.Fragment>
      <Tooltip title={t(tokens.general.language)}>
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
          sx={{ display: "inline-flex" }}
        >
          <Box sx={{ height: "24px", width: "24px" }}>
            <Box
              alt={language}
              component="img"
              src={flag}
              sx={{ height: "auto", width: "100%" }}
            />
          </Box>
        </IconButton>
      </Tooltip>
      <LanguagePopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </React.Fragment>
  );
}

export interface UserButtonProps {
  onImpersonate?: () => void;
}

function UserButton({ onImpersonate }: UserButtonProps): React.JSX.Element {
  const popover = usePopover<HTMLButtonElement>();
  const auth = useAuth();

  return (
    <React.Fragment>
      <Box
        component="button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          border: "none",
          background: "transparent",
          cursor: "pointer",
          p: 0,
        }}
      >
        <Avatar src={auth.account?.profileImageUrl} />
      </Box>
      <UserPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        onImpersonate={onImpersonate}
        open={popover.open}
      />
    </React.Fragment>
  );
}
