import type { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Card, CardContent, Stack, TextField } from "@mui/material";
import { paths } from "src/paths";
import { thunks } from "src/thunks/lead-magnet";
import { useDispatch, useSelector } from "src/store";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { ComplexityType, LeadMagnet } from "src/types/lead-magnet";

interface Values {
  name: string;
  description: string;
  type: string;
  complexity: ComplexityType;
  submit: null;
}

interface LeadMagnetEditFormProps {
  leadMagnet: LeadMagnet;
}

export const LeadMagnetEditForm: FC<LeadMagnetEditFormProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { leadMagnet } = props;

  const { strategyId } = useSelector((state) => state.settings);

  const initialValues: Values = {
    name: leadMagnet.name,
    description: leadMagnet.description,
    type: leadMagnet.type,
    complexity: leadMagnet.complexity,
    submit: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
    description: Yup.string().required(
      t(tokens.general.validators.required) as string
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      const updatedLeadMagnet = await dispatch(
        thunks.updateLeadMagnet(strategyId!, leadMagnet.id, {
          name: values.name,
          description: values.description,
          type: values.type,
          complexity: values.complexity,
        })
      );
      if (updatedLeadMagnet) {
        toast.success(t(tokens.leadMagnets.details.form.successMessage));
      } else {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
    >
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label={t(tokens.leadMagnets.details.form.name)}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <TextField
                error={
                  !!(formik.touched.description && formik.errors.description)
                }
                fullWidth
                multiline
                rows={5}
                helperText={
                  formik.touched.description && formik.errors.description
                }
                label={t(tokens.leadMagnets.details.form.description)}
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </Stack>
          </CardContent>
        </Card>
        <Stack
          alignItems="center"
          direction="row-reverse"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            href={paths.leadMagnets.list}
            color="inherit"
            component={RouterLink}
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
          <LoadingButton
            type="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t(tokens.general.buttons.update)}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
