import type { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Card, CardContent, Stack, TextField } from "@mui/material";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { thunks } from "src/thunks/lead-magnet";
import { useDispatch, useSelector } from "src/store";
import { RouterLink } from "src/components/router-link";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { createRoute } from "react-router-url-params";
import LoadingButton from "@mui/lab/LoadingButton";
import { ComplexityType } from "src/types/lead-magnet";

interface Values {
  name: string;
  description: string;
  type: string;
  complexity: ComplexityType;
  submit: null;
}

const initialValues: Values = {
  name: "",
  description: "",
  type: "",
  complexity: ComplexityType.Unknown,
  submit: null,
};

export const LeadMagnetCreateForm: FC = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const { strategyId } = useSelector((state) => state.settings);

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
    description: Yup.string().required(
      t(tokens.general.validators.required) as string
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      const topic = await dispatch(
        thunks.createLeadMagnet(strategyId!, {
          name: values.name,
          description: values.description,
          type: values.type,
          complexity: values.complexity,
        })
      );
      if (topic) {
        toast.success(t(tokens.leadMagnets.create.form.successMessage));
        router.push(
          createRoute(paths.leadMagnets.details).link({
            topicId: topic.id,
          })
        );
      } else {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        //helpers.setErrors({ submit: err.Message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label={t(tokens.leadMagnets.create.form.name)}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <TextField
                error={
                  !!(formik.touched.description && formik.errors.description)
                }
                fullWidth
                multiline
                rows={5}
                helperText={
                  formik.touched.description && formik.errors.description
                }
                label={t(tokens.leadMagnets.create.form.description)}
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </Stack>
          </CardContent>
        </Card>
        <Stack
          alignItems="center"
          direction="row-reverse"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            href={paths.leadMagnets.list}
            color="inherit"
            component={RouterLink}
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
          <LoadingButton
            type="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t(tokens.general.buttons.create)}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
