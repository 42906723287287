import { type FC } from "react";
import { Button, Card, CardContent, Stack, TextField } from "@mui/material";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch, useSelector } from "src/store";
import toast from "react-hot-toast";
import { thunks as storylineCategoryThunks } from "src/thunks/storyline-category";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { RouterLink } from "src/components/core/link";
import { paths } from "src/paths";
import { createRoute } from "react-router-url-params";
import { useRouter } from "src/hooks/use-router";

interface CreateStorylineCategoryFormProps {
  storylineId: string;
}

export const CreateStorylineCategoryForm: FC<
  CreateStorylineCategoryFormProps
> = (props) => {
  const { storylineId } = props;
  const { t } = useTranslation();
  const { strategyId } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <>
      <Formik
        validateOnChange={false}
        initialValues={{
          name: "",
          description: "",
          submit: null,
        }}
        onSubmit={async (values, helpers): Promise<void> => {
          try {
            var category = await dispatch(
              storylineCategoryThunks.createStorylineCategory(
                strategyId!,
                storylineId,
                {
                  name: values.name,
                  description: values.description,
                }
              )
            );
            toast.success(
              t(tokens.storylinesCategories.create.form.successMessage)
            );

            router.push(
              createRoute(paths.storylineCategories.details).link({
                storylineId: storylineId,
                categoryId: category.id,
              })
            );
          } catch (error) {
            toast.error(t(tokens.general.formError));
            helpers.setStatus({ success: false });
            helpers.setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255, t(tokens.general.validators.maxLength255) as string)
            .required(t(tokens.general.validators.required) as string),

          description: Yup.string()
            .max(1000, t(tokens.general.validators.maxLength1000) as string)
            .required(t(tokens.general.validators.required) as string),
        })}
      >
        {(props) => (
          <>
            <Form placeholder={""}>
              <Stack spacing={3}>
                <Card>
                  <CardContent>
                    <Stack spacing={2}>
                      <TextField
                        error={!!(props.touched.name && props.errors.name)}
                        fullWidth
                        helperText={props.touched.name && props.errors.name}
                        name="name"
                        label={t(tokens.storylinesCategories.create.form.name)}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        value={props.values.name}
                      />
                      <TextField
                        error={
                          !!(
                            props.touched.description &&
                            props.errors.description
                          )
                        }
                        fullWidth
                        helperText={
                          props.touched.description && props.errors.description
                        }
                        label={t(
                          tokens.storylinesCategories.create.form.description
                        )}
                        name="description"
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        value={props.values.description}
                      />
                    </Stack>
                  </CardContent>
                </Card>

                <Stack
                  direction="row"
                  alignItems={"start"}
                  justifyContent={"flex-start"}
                  spacing={1}
                >
                  <LoadingButton
                    type="submit"
                    disabled={props.isSubmitting}
                    loading={props.isSubmitting}
                    variant="contained"
                  >
                    {t(tokens.general.buttons.create)}
                  </LoadingButton>
                  <Button
                    component={RouterLink}
                    href={createRoute(paths.storylines.details).link({
                      storylineId: storylineId,
                    })}
                    color="inherit"
                  >
                    {t(tokens.general.buttons.cancel)}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
