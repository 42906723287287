import { type FC } from "react";
import { Strategy } from "src/types/strategy";
import { Card, CardContent, Typography } from "@mui/material";

interface FearsDetailsProps {
  strategy: Strategy;
}

export const FearsDetails: FC<FearsDetailsProps> = (
  props
) => {
  const { strategy } = props;

  return (
    <>
      <Card>
        <CardContent>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: strategy.fears ?? "",
            }}
          ></Typography>
        </CardContent>
      </Card>
    </>
  );
};
