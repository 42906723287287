import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as storylineSlice } from "src/slices/storyline";
import { ERRORS } from "src/consts";

const clearStoryline =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(storylineSlice.actions.setStoryline(undefined));
  };

const createStoryline =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var storyline = await fetchWrapper.post(
      `${api}/strategies/${strategyId}/storylines`,
      value
    );
    dispatch(storylineSlice.actions.setStoryline(storyline));
    return storyline;
  };

const updateStoryline =
  (strategyId: string, storylineId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var storyline = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/storylines/${storylineId}`,
      value
    );
    dispatch(storylineSlice.actions.setStoryline(storyline));
    return storyline;
  };

const deleteStoryline =
  (strategyId: string, storylineId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/strategies/${strategyId}/storylines/${storylineId}`,
      {}
    );
    dispatch(storylineSlice.actions.setStoryline(undefined));
  };

const getStoryline =
  (strategyId: string, storylineId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(storylineSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(storylineSlice.actions.setLoadingStatus(true));
    try {
      var storyline = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/storylines/${storylineId}`,
        {}
      );
      dispatch(storylineSlice.actions.setStoryline(storyline));
    } catch (error) {
      if (error?.code === "Storyline.StorylineNotFound") {
        dispatch(
          storylineSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR)
        );
      } else {
        dispatch(
          storylineSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR)
        );
      }
    }
    dispatch(storylineSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createStoryline,
  updateStoryline,
  deleteStoryline,
  clearStoryline,
  getStoryline,
};
