import type { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FieldArray, Form, Formik, getIn } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { thunks } from "src/thunks/content-types";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { LoadIndicator } from "../common/load-indicator";
import { ErrorMessage } from "../common/error-message";

export const StrategyContentTypes: FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { strategyId } = useSelector((state) => state.settings);
  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.contentTypes
  );

  if (isLoading) {
    return <LoadIndicator />;
  }

  if (hasLoadingError) {
    return (
      <Card>
        <CardContent>
          <ErrorMessage
            text={t(tokens.contentTypes.errorLoadingContentTypes)}
          />
        </CardContent>
      </Card>
    );
  }

  return (
    <Formik
      validateOnChange={false}
      enableReinitialize
      initialValues={{
        contentTypes: items,
        submit: null,
      }}
      onSubmit={async (values, helpers): Promise<void> => {
        try {
          await dispatch(
            thunks.updateContentTypes(strategyId!, {
              contentTypes: values.contentTypes,
            })
          );
          toast.success(t(tokens.contentTypes.form.successMessage));
        } catch (error) {
          toast.error(t(tokens.general.formError));
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        contentTypes: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .required(t(tokens.general.validators.required) as string)
              .max(255, t(tokens.general.validators.maxLength255) as string),
          })
        ),
      })}
    >
      {(props) => (
        <>
          <Form placeholder={""}>
            <Stack spacing={4}>
              <Card sx={{ display: "flex", flexDirection: "column" }}>
                {props.values.contentTypes.length === 0 && (
                  <>
                    <CardContent>
                      <Typography variant="body2">
                        {t(tokens.contentTypes.form.noContentTypes)}
                      </Typography>
                    </CardContent>
                    <Divider />
                  </>
                )}
                <Box sx={{ overflowX: "auto" }}>
                  <List disablePadding>
                    <FieldArray
                      name="contentTypes"
                      render={(arrayHelpers) => (
                        <>
                          {props.values.contentTypes.map(
                            (contentType, index) => {
                              const name = `contentTypes[${index}].name`;
                              const touchedName = getIn(props.touched, name);
                              const errorName = getIn(props.errors, name);

                              const isTemporary = `contentTypes[${index}].isTemporary`;
                              // const touchedIsTemporary = getIn(
                              //   props.touched,
                              //   isTemporary
                              // );
                              // const errorIsTemporary = getIn(
                              //   props.errors,
                              //   isTemporary
                              // );

                              return (
                                <ListItem
                                  key={index}
                                  divider
                                  sx={{ p: 2, width: "100%" }}
                                >
                                  <Stack
                                    spacing={2}
                                    direction={{
                                      xs: "column",
                                      sm: "row",
                                    }}
                                    alignItems={"end"}
                                    sx={{
                                      width: "100%",
                                    }}
                                  >
                                    <TextField
                                      error={!!(touchedName && errorName)}
                                      fullWidth
                                      helperText={touchedName && errorName}
                                      name={name}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                      value={contentType.name}
                                    />
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="center"
                                      justifyContent={"space-between"}
                                      sx={{
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "40%",
                                        },
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name={isTemporary}
                                            onBlur={props.handleBlur}
                                            onChange={props.handleChange}
                                            value={contentType.isTemporary}
                                            checked={contentType.isTemporary}
                                          />
                                        }
                                        label={t(tokens.contentTypes.form.type)}
                                      />

                                      <IconButton
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        <SvgIcon>
                                          <DeleteOutlinedIcon color="error" />
                                        </SvgIcon>
                                      </IconButton>
                                    </Stack>
                                  </Stack>
                                </ListItem>
                              );
                            }
                          )}

                          <Box sx={{ p: 2 }}>
                            <Button
                              onClick={() =>
                                arrayHelpers.push({
                                  id: "",
                                  name: "",
                                  isTemporary: false,
                                })
                              }
                              variant="outlined"
                              color="primary"
                            >
                              {t(tokens.general.buttons.add)}
                            </Button>
                          </Box>
                        </>
                      )}
                    />
                  </List>
                </Box>
              </Card>

              <Stack
                alignItems="center"
                direction="row-reverse"
                justifyContent="flex-end"
                spacing={1}
              >
                <LoadingButton
                  type="submit"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </>
      )}
    </Formik>
  );
};
