import { type FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Card, CardContent, Stack, TextField } from "@mui/material";
import { thunks } from "src/thunks/product";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import { Product } from "src/types/product";

interface Values {
  name: string;
  description: string;
  uniqueValueProposition: string;
  landingPage?: string;
  submit: null;
}

interface ProductEditFormProps {
  product: Product;
  onSave?: () => void;
  onCancel?: () => void;
}

export const ProductEditForm: FC<ProductEditFormProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { product, onSave } = props;

  const { strategyId } = useSelector((state) => state.settings);

  const initialValues: Values = {
    name: product.name,
    description: product.description,
    uniqueValueProposition: product.uniqueValueProposition,
    landingPage: product.landingPage,
    submit: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
    description: Yup.string().required(
      t(tokens.general.validators.required) as string
    ),
    uniqueValueProposition: Yup.string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (values, helpers): Promise<void> => {
      const updatedProduct = await dispatch(
        thunks.updateProduct(strategyId!, product.id, {
          name: values.name,
          description: values.description,
          uniqueValueProposition: values.uniqueValueProposition,
          landingPage: values.landingPage,
        })
      );
      onSave?.();
      if (updatedProduct) {
        toast.success(t(tokens.products.details.form.successMessage));
      } else {
        toast.error(t(tokens.general.formError));
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label={t(tokens.products.details.form.name)}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <TextField
                error={
                  !!(
                    formik.touched.uniqueValueProposition &&
                    formik.errors.uniqueValueProposition
                  )
                }
                fullWidth
                multiline
                minRows={2}
                helperText={
                  formik.touched.uniqueValueProposition &&
                  formik.errors.uniqueValueProposition
                }
                label={t(tokens.products.details.form.uniqueValueProposition)}
                name="uniqueValueProposition"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.uniqueValueProposition}
              />
              <TextField
                error={
                  !!(formik.touched.description && formik.errors.description)
                }
                fullWidth
                multiline
                minRows={5}
                helperText={
                  formik.touched.description && formik.errors.description
                }
                label={t(tokens.products.details.form.description)}
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </Stack>
          </CardContent>
        </Card>
        {/* 
        <Card sx={{ display: "flex", flexDirection: "column" }}>
          <CardHeader title={t(tokens.products.details.form.landingPage)} />
          <Divider />
          <CardContent>
            <Stack spacing={1}>
              <TextEditor
                ref={editorRef}
                customButtons={[
                  <TemplateButton
                    editor={editorRef.current}
                    onTextGenerate={(text) => {
                      editorRef?.current?.commands.setContent(text);
                    }}
                    getMessages={() => {
                      var messages = [
                        {
                          user: PromptMessageUser.System,
                          content: PROMPTS.TOPIC_CONTENT,
                        },
                      ] as PromptMessage[];

                      if (formik.values.name) {
                        messages.push({
                          user: PromptMessageUser.User,
                          content: `Product name:\n ${formik.values.name}`,
                        });
                      }

                      if (formik.values.description) {
                        messages.push({
                          user: PromptMessageUser.User,
                          content: `Product description:\n ${formik.values.description}`,
                        });
                      }

                      var targetAudiencesString = "";
                      formik.values.targetAudiences.map((x, index) => {
                        targetAudiencesString += `Target Audience ${index + 1}:\n`;
                        targetAudiencesString += `Name: ${x.name}\n\n`;
                        return true;
                      });

                      if (targetAudiencesString) {
                        messages.push({
                          user: PromptMessageUser.User,
                          content: `Target Audiences:\n ${targetAudiencesString}`,
                        });
                      }

                      return messages;
                    }}
                    onFinish={() => {
                      formik.setFieldValue(
                        "content",
                        editorRef?.current?.getHTML()
                      );
                    }}
                  />,
                ]}
                showHeaderTool={true}
                content={formik.values.landingPage ?? ""}
                onUpdate={(content) => {
                  formik.setFieldValue("landingPage", content.editor.getHTML());
                }}
              />
            </Stack>{" "}
          </CardContent>
        </Card> */}
        <Stack
          alignItems="center"
          direction="row-reverse"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            onClick={props.onCancel}
            color="inherit"
          >
            {t(tokens.general.buttons.cancel)}
          </Button>
          <LoadingButton
            type="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t(tokens.general.buttons.update)}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
