import { useState, type FC, useRef, useEffect } from 'react';
import { Autocomplete, Box, Card, CardContent, Chip, Slider, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, alpha } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useDispatch } from 'src/store';
import { thunks as domainThunks } from 'src/thunks/domain';
import { NAME_LENGTH } from 'src/consts';

interface Values {
  idea: string;
  description: string;
  submit: null;
  nameLength: string;
  zones: string[];
  style: Option | undefined;
  randomness: Option | undefined;
}

type Option = {
  text: string;
  description: string;
  isDefault?: boolean;
};

const styles: Option[] = [
  { text: 'Auto', description: 'all styles', isDefault: true },
  {
    text: 'Suffixes (Shopify, Bitly, Turntable)',
    description: 'Apply suffixes, like  "ify", "fy", "ly", "ley", "lie", "able", "ible", "er", "tech", "link", "hub", "ology", "logy", "pro", "lab"'
  },
  {
    text: 'Prefixes (iPhone, BioTech, InVision, Unsplash, Evernote)',
    description: 'Apply prefixes, like  "i", "e", "cyber", "auto", "pro", "pre", "co", "super", "multi", "trans", "mega", "ultra"'
  },
  {
    text: 'Compound words (FedEx, Microsoft, YouTube, Facebook)',
    description: 'Combine two words, like Microsoft (Microcomputer + Software), Instagram (Instant + Telegram), Netflix (Internet + Flicks)'
  },
  {
    text: 'Non-English words (Hulu, Toyota, Audi)',
    description: 'Generate non-english words like Hulu, Toyota, Audi'
  },
  {
    text: 'Misspelled words (Flickr, Tumblr, Lyft)',
    description: 'Generate misspelled words like Flickr, Tumblr, Lyft'
  },
  {
    text: 'Descriptive Names (PayPal, SoundCloud, Evernote)',
    description: 'Generate descriptive words like PayPal, SoundCloud, Evernote'
  },
  {
    text: 'Blended Words (Microsoft, Instagram, Netflix)',
    description: 'Generate descriptive words like Microsoft, Instagram, Netflix'
  }
];

const zones: Option[] = [
  { text: 'com', description: 'com', isDefault: true },
  { text: 'net', description: 'net', isDefault: true },
  { text: 'org', description: 'org', isDefault: false },
  { text: 'ai', description: 'ai' },
];

const randomness: Option[] = [
  { text: 'Low', description: 'Less random. The most direct name ideas' },
  { text: 'Medium', description: 'Balanced. More creative results', isDefault: true },
  { text: 'High', description: 'Random ideas. More varied results' },
];

interface DomainFilterFormProps {
  modelName: string;
}

export const DomainFilterForm: FC<DomainFilterFormProps> = (props) => {

  const { t, i18n } = useTranslation();
  const { modelName } = props;
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<Values>(
    {
      idea: '',
      description: '',
      zones: zones.filter(x => x.isDefault).map((item) => item.text) ?? [],
      nameLength: NAME_LENGTH.MEDIUM,
      style: styles.find((item) => item.isDefault) ?? undefined,
      randomness: randomness.find((item) => item.isDefault) ?? undefined,
      submit: null
    });

  const textRef = useRef();
  const validationSchema = Yup.object({
    idea: Yup
      .string()
      .max(255, t(tokens.general.validators.maxLength255) as string)
      .required(t(tokens.general.validators.required) as string),
    zones: Yup
      .array()
      .min(1, t(tokens.general.validators.oneItemRequired) as string)
      .required(t(tokens.general.validators.required) as string),
  });

  useEffect(() => {
    var domains = localStorage.getItem('domains_filters');
    if (domains) {
      setInitialValues(JSON.parse(domains) as Values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      var prompt = `description:\n${values.idea}`;
      prompt += `\n\namount of options:30`;
      if (values.nameLength === NAME_LENGTH.SHORT) {
        prompt += `\n\nMaximum amount of characters: 5-7`;
      } else if (values.nameLength === NAME_LENGTH.MEDIUM) {
        prompt += `\n\nMaximum amount of characters: 8-12`;
      } else {
        prompt += `\n\nMaximum amount of characters: 12-25`;
      }
      if (values.style?.text !== 'Auto') {
        prompt += `\n\nstyle: ${values.style?.text}`;
      }
      await dispatch(domainThunks.generateDomains(prompt, modelName, values.zones));
      localStorage.setItem('domains_filters', JSON.stringify(values));
    }
  });

  return (
    <Card>
      <CardContent
        sx={{
          p: 3,
          m: 0
        }}>
        <form
          onSubmit={formik.handleSubmit}
        // {...props}
        >
          <Stack
            spacing={3}
          >
            <TextField
              inputRef={textRef}
              error={!!(formik.touched.idea && formik.errors.idea)}
              helperText={formik.touched.idea && formik.errors.idea}
              fullWidth
              multiline={true}
              minRows={3}
              sx={{
                mt: 0,
                '& input': {
                  p: 2,
                },
                '& textarea': {
                  p: 2
                },
              }}

              InputProps={{
                sx: {
                  p: 0
                }

              }}

              placeholder={"Describe the product or service you're naming"}
              name="idea"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.idea}
            />

            <Autocomplete
              getOptionLabel={(option: Option) => `${option.text}`}
              options={styles}
              disableClearable
              value={formik.values.style}
              isOptionEqualToValue={(option, value) => option.text === value.text}
              onChange={(e, option) => {
                formik.setFieldValue('style', option);
              }}
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  fullWidth
                  label="Styles"
                  name="style"
                />
              )}
            />

            <Autocomplete
              multiple
              options={zones.map((option) => option.text)}
              value={formik.values.zones}
              freeSolo
              filterSelectedOptions
              limitTags={6}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined"
                    label={option}
                    {...getTagProps({ index })} />
                ))
              }
              onChange={(e, option) => {
                formik.setFieldValue('zones', option.map((item) => item));
              }}
              //value={formik.values.zones}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  error={!!(formik.touched.zones && formik.errors.zones)}
                  helperText={formik.touched.zones && formik.errors.zones}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  label="Domain zones"
                  type="text"
                  placeholder="Enter domain zone"
                />
              )}
            />

            {/* <Autocomplete
          getOptionLabel={(option: Option) => `${option.text} (${option.description})`}
          options={randomness}
          disableClearable
          value={formik.values.randomness}
          isOptionEqualToValue={(option, value) => option.text === value.text}
          onChange={(e, option) => {
            formik.setFieldValue('randomness', option);
          }}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              fullWidth
              label="Randomness"
              name="randomness"
            />
          )}
        /> */}
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography id="track-false-slider"
                gutterBottom>
                Prefered name length
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={formik.values.nameLength}
                exclusive
                onChange={(event, value) => {
                  formik.setFieldValue('nameLength', value);
                }}
                aria-label="Platform"
              >
                <ToggleButton
                  sx={{
                    borderColor: (theme) => alpha(theme.palette.grey[300], 0.8)
                  }}
                  value={NAME_LENGTH.SHORT}>Short</ToggleButton>
                <ToggleButton
                  sx={{
                    borderColor: (theme) => alpha(theme.palette.grey[300], 0.8)
                  }}
                  value={NAME_LENGTH.MEDIUM}>Medium</ToggleButton>
                <ToggleButton
                  sx={{
                    borderColor: (theme) => alpha(theme.palette.grey[300], 0.8)
                  }}
                  value={NAME_LENGTH.LONG}>Long</ToggleButton>
              </ToggleButtonGroup>
              {/* <TextField
            inputRef={textRef}
            error={!!(formik.touched.maxLength && formik.errors.maxLength)}
            helperText={formik.touched.maxLength && formik.errors.maxLength}
            onChange={(e) => {
              formik.setFieldValue('maxLength', e.target.value);
              formik.handleChange(e);
            }}
            sx={{
              '& input': {
                p: 2,
                textAlign: "center"
              },
              '& textarea': {
                p: 2
              },
              width: 80
            }}

            InputProps={{
              sx: {
                p: 0
              },
              inputProps: { min: 5, max: 20 }
            }}

            placeholder={"Length"}
            name="maxLength"
            onBlur={formik.handleBlur}
            type="number"
            value={formik.values.maxLength}
          /> */}
            </Stack>
            {/* <Box
          sx={{
            pb: 1
          }}>


          <Slider
            onChange={(e, value) => {
              formik.setFieldValue('maxLength', value);
            }}
            value={formik.values.maxLength}
            min={5}
            max={20}
            aria-label="Default"
            marks={[
              { value: 5, label: "5" },
              { value: 10, label: "10" },
              { value: 15, label: "15" },
              { value: 20, label: "20" }
            ]}
            valueLabelDisplay="off" />
        </Box> */}
            <Box>
              <LoadingButton
                type="submit"
                size="large"
                loading={formik.isSubmitting}
                variant="contained"
                sx={{
                  minWidth: "100%",
                  height: 56,
                }}
              >
                {"Generate"}
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </CardContent>
    </Card >
  );
};