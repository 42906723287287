import { type FC } from "react";
import { IconButton, SvgIcon, Typography } from "@mui/material";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface ItemMenuProps {
  onDelete?: () => void;
  onEdit?: () => void;
}
export const ItemMenu: FC<ItemMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.onEdit && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              props.onEdit?.();
              handleClose();
            }}
          >
            <SvgIcon>
              <EditOutlinedIcon />
            </SvgIcon>
            {t(tokens.general.buttons.edit)}
          </MenuItem>
        )}
        {props.onDelete && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              props.onDelete?.();
              handleClose();
            }}
          >
            <SvgIcon color="error">
              <DeleteOutlinedIcon />
            </SvgIcon>
            <Typography color="error">
              {t(tokens.general.buttons.delete)}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
