import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as contentTemplateSlice } from "src/slices/content-template";
import { ERRORS } from "src/consts";

const clearContentTemplate =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(contentTemplateSlice.actions.setContentTemplate(undefined));
  };

const createContentTemplate =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var contentTemplate = await fetchWrapper.post(
      `${api}/strategies/${strategyId}/content-templates`,
      value
    );
    dispatch(contentTemplateSlice.actions.setContentTemplate(contentTemplate));
    return contentTemplate;
  };

const updateContentTemplate =
  (strategyId: string, contentTemplateId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var contentTemplate = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/content-templates/${contentTemplateId}`,
      value
    );
    dispatch(contentTemplateSlice.actions.setContentTemplate(contentTemplate));
    return contentTemplate;
  };

const deleteContentTemplate =
  (strategyId: string, contentTemplateId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await fetchWrapper.delete(
      `${api}/strategies/${strategyId}/content-templates/${contentTemplateId}`,
      {}
    );
    dispatch(contentTemplateSlice.actions.setContentTemplate(undefined));
  };

const getContentTemplate =
  (strategyId: string, contentTemplateId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(contentTemplateSlice.actions.setLoadingErrorStatus(undefined));
    dispatch(contentTemplateSlice.actions.setLoadingStatus(true));
    try {
      var contentTemplate = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/content-templates/${contentTemplateId}`,
        {}
      );
      dispatch(contentTemplateSlice.actions.setContentTemplate(contentTemplate));
    } catch (error) {
      if (error?.code === "ContentTemplate.ContentTemplateNotFound") {
        dispatch(
          contentTemplateSlice.actions.setLoadingErrorStatus(ERRORS.NOT_FOUND_ERROR)
        );
      } else {
        dispatch(
          contentTemplateSlice.actions.setLoadingErrorStatus(ERRORS.GENERAL_ERROR)
        );
      }
    }
    dispatch(contentTemplateSlice.actions.setLoadingStatus(false));
  };

export const thunks = {
  createContentTemplate,
  updateContentTemplate,
  deleteContentTemplate,
  clearContentTemplate,
  getContentTemplate,
};
