import React, { useState, type FC, useEffect, useRef } from 'react';
import { Box, Chip, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'src/store';
import { Container, Stack, Theme, alpha } from '@mui/system';
import { thunks as domainThunks } from 'src/thunks/domain';
import { Domain } from 'src/types/domain';
import { DomainGeneratedList } from './domain-generated-list';
import { DomainFilterForm } from './domain-filter-form';
import { MODEL_NAMES } from 'src/consts';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

export const DomainSection: FC = () => {

  const {
    generatedDomains,
    savedDomains,
    hasLoadingError,
    isLoading
  } = useSelector((state) => state.domain);

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showOnlyAvailable, setShowOnlyAvailableDomains] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [modelName, setModelName] = React.useState(MODEL_NAMES.GPT3_5);

  useEffect(() => {
    if (isInitialized) {
      localStorage.setItem('domains', JSON.stringify(savedDomains));
    }
    else {
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedDomains]);

  useEffect(() => {
    if (!mdUp && generatedDomains.length) {
      setSelectedTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedDomains]);

  useEffect(() => {
    var domains = localStorage.getItem('domains');
    if (domains) {
      dispatch(domainThunks.loadDomains(JSON.parse(domains) as Domain[]));
    }
    var showOnlyAvailableDomains = localStorage.getItem('showOnlyAvailableDomains');
    if (showOnlyAvailableDomains) {
      setShowOnlyAvailableDomains(JSON.parse(showOnlyAvailableDomains) as boolean);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setSelectedTabIndex(0);
  }, [mdUp]);

  return (
    <>

      <Box>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          textAlign={"center"}
          spacing={2}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Typography
            variant='h1'
            align='center'
          >
            Domain name generator
          </Typography>
          <Box>
            <Chip
              label="Powered by AI"
              color="primary"
              variant="outlined" />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 3
        }}>
        <ToggleButtonGroup
          color="primary"
          value={modelName}
          exclusive
          onChange={(event, value) => {
            setModelName(value);
          }}
          aria-label="Platform"
        >
          <ToggleButton
            sx={{
              borderColor: (theme) => alpha(theme.palette.grey[300], 0.8)
            }}
            value={MODEL_NAMES.GPT3_5}>
            <Stack
              direction={"row"}
              spacing={1}>
              <BoltOutlinedIcon /> <span>GPT 3.5</span>
            </Stack>
          </ToggleButton>
          <ToggleButton
            sx={{
              borderColor: (theme) => alpha(theme.palette.grey[300], 0.8)
            }}
            value={MODEL_NAMES.GPT4}>
            <Stack
              direction={"row"}
              spacing={1}>
              <AutoAwesomeOutlinedIcon /> <span>GPT 4</span>
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box
        component="main"
        ref={rootRef}
        sx={{
          display: 'flex',
          flexGrow: 1,
          pb: '20px'
        }}
      >

        <Container maxWidth="lg"
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
            position: 'relative',
            gap: '36px'
          }}>

          {mdUp && (
            <Box
              sx={{
                flex: '0 0 50%',

              }}
            >

              <DomainFilterForm
                modelName={modelName} />

            </Box>
          )}
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            {/* {!mdUp && <StrategyMenuToolbar onDrawerOpen={handleDrawerOpen} />} */}
            <Box sx={{
              borderBottom: 1,
              borderColor: 'divider',
              mb: 2
            }}>
              <Tabs value={selectedTabIndex}
                onChange={(event, newValue) => {
                  setSelectedTabIndex(newValue);
                }}>
                {!mdUp &&
                  <Tab label="Parameters" />
                }
                <Tab label="Generated names" />
                <Tab label="Saved options" />
              </Tabs>
            </Box>

            {(selectedTabIndex === 0 && !mdUp) &&
              <DomainFilterForm
                modelName={modelName}
              />
            }

            {((selectedTabIndex === 0 && mdUp) || (selectedTabIndex === 1 && !mdUp)) &&
              <DomainGeneratedList
                emptyText='No generated domains yet'
                showOnlyAvailable={showOnlyAvailable}
                domains={generatedDomains.map(d => {
                  return {
                    ...d,
                    isSaved: savedDomains.find(s => s.name === d.name) !== undefined
                  };
                })}
                onShowOnlyAvailable={(showOnlyAvailable) => {
                  setShowOnlyAvailableDomains(showOnlyAvailable);
                  localStorage.setItem('showOnlyAvailableDomains', JSON.stringify(showOnlyAvailable));
                }}
                onAddToBookmarks={async (domain) => {
                  await dispatch(domainThunks.saveDomain(domain));
                }}
                onRemoveFromBookmarks={async (domain) => {
                  await dispatch(domainThunks.removeDomain(domain));
                }}
                hasError={hasLoadingError}
                isLoading={isLoading}
              />
            }

            {((selectedTabIndex === 1 && mdUp) || (selectedTabIndex === 2 && !mdUp)) &&
              <DomainGeneratedList
                emptyText='No saved domains yet'
                domains={savedDomains.slice().reverse().map(d => {
                  return {
                    ...d,
                    isSaved: true
                  };
                })}
                onRemoveFromBookmarks={async (domain) => {
                  await dispatch(domainThunks.removeDomain(domain));
                }}
                hasError={false}
                isLoading={false}
              />
            }
          </Box>
        </Container>
      </Box>
    </>
  );
};