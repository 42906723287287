import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as topicsSlice } from "src/slices/topics";
import { GetTopicsQueryParams } from "src/types/topic";
import { urlParams } from "src/utils/url-params";

const getTopics =
  (strategyId: string, params: GetTopicsQueryParams): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicsSlice.actions.setLoadingStatus(true));
    dispatch(topicsSlice.actions.setErrorStatus(false));
    try {
      var topic = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/topics${urlParams(params)}`,
        {}
      );
      dispatch(topicsSlice.actions.getTopics(topic));
    } catch (error) {
      dispatch(topicsSlice.actions.setErrorStatus(true));
    }
    dispatch(topicsSlice.actions.setLoadingStatus(false));
  };

const clearTopics =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(topicsSlice.actions.clearTopics());
  };

export const thunks = {
  clearTopics,
  getTopics,
};
